// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.animation_DndAi {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    .iphone_c0w6w {
        position: absolute;
        height: 600px;
    }
    
    .widgets__kNFN {
        position: absolute;
        z-index: -1;
        scale: 0;
    }
    
    .socials_I5ZXo {
        background: linear-gradient(#ff348b, #e30217);
        border-radius: 30px;
        aspect-ratio: 1;
        height: 140px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
    
    a {
        text-decoration: none;
        font-size: 30px;
        font-family: sans-serif;
        background-color: white;
        aspect-ratio: 1;
        height: 50px;
        border-radius: 100%;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }
} 
`, "",{"version":3,"sources":["webpack://./src/views/web/components/Home/Intro.module.css"],"names":[],"mappings":";;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ;QACI,kBAAkB;QAClB,aAAa;IACjB;;IAEA;QACI,kBAAkB;QAClB,WAAW;QACX,QAAQ;IACZ;;IAEA;QACI,6CAA6C;QAC7C,mBAAmB;QACnB,eAAe;QACf,aAAa;QACb,aAAa;QACb,6BAA6B;QAC7B,mBAAmB;QACnB,eAAe;IACnB;;IAEA;QACI,qBAAqB;QACrB,eAAe;QACf,uBAAuB;QACvB,uBAAuB;QACvB,eAAe;QACf,YAAY;QACZ,mBAAmB;QACnB,YAAY;QACZ,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;AACJ","sourcesContent":["\n\n.animation {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    width: 100vw;\n    .iphone {\n        position: absolute;\n        height: 600px;\n    }\n    \n    .widgets {\n        position: absolute;\n        z-index: -1;\n        scale: 0;\n    }\n    \n    .socials {\n        background: linear-gradient(#ff348b, #e30217);\n        border-radius: 30px;\n        aspect-ratio: 1;\n        height: 140px;\n        display: flex;\n        justify-content: space-evenly;\n        align-items: center;\n        flex-wrap: wrap;\n    }\n    \n    a {\n        text-decoration: none;\n        font-size: 30px;\n        font-family: sans-serif;\n        background-color: white;\n        aspect-ratio: 1;\n        height: 50px;\n        border-radius: 100%;\n        color: black;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animation": `animation_DndAi`,
	"iphone": `iphone_c0w6w`,
	"widgets": `widgets__kNFN`,
	"socials": `socials_I5ZXo`
};
export default ___CSS_LOADER_EXPORT___;
