import React from "react";

import styles from '../index.module.css'

const UtilizeBody = ({ utilize }) => {
  return (
    <>
      {utilize &&
        utilize.map((data, index) => (
          <div id={data.id} className={`${styles["single-inner-box"]}`}  key={index}>
            <h3 className={styles["section-title"]} >{data.title}</h3>
            {data.body.map((inner, i) => (
              <div
                id={inner.id}
                className={`${styles['rbt-elements-area']} ${styles['rbt-shadow-box']}`} 
                key={i}
              >
                <div className={styles.wrapper} >
                  <h4 className={`${styles.titleSm}`} >{inner.title}</h4>
                  <div className={`${styles.desc}`} >
                    <div className={`${styles.img}`} >
                      <img src={inner.img} width={892} height={617} alt="" />
                    </div>
                    <p className={`${styles.b1}`} >{inner.desc}</p>
                    <h6>{inner.subTitle}</h6>
                    <ul className={`${styles["content-list"]}`} >
                      {inner.list.map((item, itemIndex) => (
                        <li key={itemIndex}>{item.text}</li>
                      ))}
         
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
    </>
  );
};

export default UtilizeBody;
