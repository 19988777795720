import React from "react";

import styles from '../index.module.css'

const FooterProps = ({ list }) => {
  return (
    <>
      {list.map((item, itemIndex) => (
        <div className={styles["widget-menu-bottom"]} key={itemIndex}>
          <h4 className={styles["title"]}>{item.title}</h4>
          <div className={styles["inner"]}>
            <ul className={`${styles["footer-link"]} ${styles["link-hover"]}`}>
              {item.innerItem.map((inner, i) => (
                <li key={i}>
                  <button href={inner.link}>{inner.text}</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default FooterProps;
