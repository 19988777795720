import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from '../index.module.css';

import PricingData from "@home/assets/constants/pricing.json";

import { ReactComponent as IconTest } from '@home/assets/images/pricing/pricingprueba.svg';
import { ReactComponent as IconScale } from '@home/assets/images/pricing/pricingempieza.svg';
import { ReactComponent as IconPredict } from '@home/assets/images/pricing/pricingescala.svg';


const Pricing = ({
  setStateUpgrade = (() => { }),
  start,
  end,
  parentClass,
  isBadge,
  gap
}) => {
  const { t } = useTranslation();

  const [pricingDataState, setPricingDataState] = useState(PricingData.pricing);

  const [sectionStates, setSectionStates] = useState({
    Premium: true,
    Enterprise: true,
  });

  const toggleSection = (subTitle) => {
    setSectionStates((prevState) => ({
      ...prevState,
      [subTitle]: !prevState[subTitle],
    }));
  };

  const handlePricingClick = (index) => {
    const updatedPricing = pricingDataState.map((data, i) => ({
      ...data,
      isSelect: i === index, 
    }));
    setPricingDataState(updatedPricing);
  };

  return (
    <>
      <div
        className={`${styles["tab-content"]} ${styles["p-0"]} ${styles["bg-transparent"]} ${styles["border-0"]} ${styles.border} ${styles["bg-light"]}`}
        id="nav-tabContent"
      >

        <div className={`${styles.row}`}>
          <div className={`${styles["col-lg-12"]}`}>
            <div
              className={`${styles["section-title"]} ${styles["text-center"]}`}
              data-sal="slide-up"
              data-sal-duration="400"
              data-sal-delay="150"
            >
              <h4 className={styles.subtitle}>
                <span className={`${styles["theme-gradient"]}`}>
                  {t('home1.web.component5.text1')}
                </span>
              </h4>
              <h2 className={`${styles.title} ${styles["text-center"]} ${styles["mb--40"]}`}>
                {t('home1.web.component5.text2')}
              </h2>
            </div>

            <nav className={`${styles['aiwave-tab']}`}>
              <div
                className={`${styles["tab-btn-grp"]} ${styles["nav"]} ${styles["nav-tabs"]} ${styles["text-center"]} ${styles["justify-content-center"]}`}
                id="nav-tab"
                role="tablist"
              >
                {pricingDataState &&
                  pricingDataState.map((data, index) => (
                    <button
                      className={`${styles["nav-link"]} ${data.isSelect ? styles.active : ""}`}
                      id={`${data.priceId}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${data.priceId}`}
                      type="button"
                      role="tab"
                      aria-controls={data.priceId}
                      aria-selected="false"
                      key={index}
                      onClick={() => handlePricingClick(index)} // Change isSelect on click
                    >
                      {data.priceLabel}{" "}
                      {data.discount ? (
                        <span className={`${styles["rainbow-badge-card"]} ${styles["badge-border"]}`}>
                          -{data.discount}%
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  ))}
              </div>
            </nav>
          </div>
        </div>

        {pricingDataState &&
          pricingDataState.map((data, index) => (
            <div
              className={`${styles["tab-pane"]} ${styles.fade} ${data.isSelect ? `${styles.active} ${styles.show}` : ""}`}
              id={data.priceId}
              role="tabpanel"
              aria-labelledby={`${data.priceId}-tab`}
              key={index}
            >
              <div className={`${styles.row} ${styles["row--15"]} ${gap}`}>
                {data.priceBody
                  .slice(start, end)
                  .map((innerData, innerIndex) => (
                    <div
                      key={innerIndex}
                      className={`${styles["col-xl-4"]} ${styles["col-lg-6"]} ${styles["col-md-6"]} ${styles["col-12"]} ${styles["mt--30"]}`}
                    >
                      <div
                        className={`${styles["rainbow-pricing"]} ${styles["style-aiwave"]} ${innerData.isSelect ? styles.active : ""}`}
                      >
                        <div className={styles["pricing-table-inner"]}>
                          <div className={styles["pricing-top"]}>
                            <div className={styles["pricing-header"]}>
                              <div className={styles["pricing-header-container"]}>
                                <div className={`${styles.icon} ${styles[`color-var-${innerData.classNum}`]}`}>
                                  {innerData.id == 1 ? (
                                    <IconTest />
                                  ) : innerData.id == 2 ? (
                                    <IconScale />
                                  ) : (
                                    <IconPredict />
                                  )}
                                </div>
                                <h4 className={`${styles.title} ${styles[`color-var-${innerData.classNum}`]}`}>
                                  {innerData.subTitle}
                                </h4>
                              </div>
                              <div className={styles.pricing}>
                                <span className={styles["price-text"]}>
                                  {innerData.price}
                                </span>
                                <span className={styles["text"]}>
                                  {innerData.priceFor}
                                </span>
                              </div>
                              <p className={styles.subtitle}>{innerData.title}</p>
                            </div>
                            <div className={styles["pricing-body"]}>
                              <div
                                className={`${styles["features-section"]} ${styles["has-show-more"]} ${!sectionStates[innerData.subTitle] ? styles.active : ""
                                  }`}
                              >
                                <h6>{innerData.text}</h6>
                                <ul className={`${styles["list-style--1"]} ${styles["has-show-more-inner-content"]}`}>
                                  {innerData.listItem.map((list, i) => (
                                    <li key={i}>
                                      <i className="fa-regular fa-circle-check"></i>
                                      {list.text}
                                    </li>
                                  ))}
                                </ul>
                                {innerData.isShow ? (
                                  <div
                                    className={`${styles["rbt-show-more-btn"]} ${!sectionStates[innerData.subTitle] ? styles.active : ""
                                      }`}
                                  >
                                    <button
                                      className={styles["rbt-btn-link"]}
                                      onClick={() => toggleSection(innerData.subTitle)}
                                    >
                                      <span
                                        className={styles["text-btn"]}
                                        style={{
                                          display: sectionStates[innerData.subTitle]
                                            ? "inline"
                                            : "none",
                                        }}
                                      >
                                        Show More
                                      </span>
                                      <span
                                        className={styles["text-btn"]}
                                        style={{
                                          display: sectionStates[innerData.subTitle]
                                            ? "none"
                                            : "inline",
                                        }}
                                      >
                                        Show Less
                                      </span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Pricing;
