import { createAsyncThunk } from '@reduxjs/toolkit'
import apiBackend from '../../../../apiBackend'
import {
	setAssets,
	setAssetsVersions,
	filterFolder,
	pushFolder,
	setFile,
	setCategory,
	setFileToCopy,
	copyFileLocal,
} from '../slices/assetsSlice'

function getCurrentDateFormatted() {
	const now = new Date()

	return now.getTime()
}

function buildQueryString(params) {
	return Object.keys(params)
		.map(
			(key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
		)
		.join('&')
}

export const getRootDirectories = createAsyncThunk(
	'assets/getRootDirectories',
	async ({ driveId, Prefix }, { dispatch }) => {
		try {
			const userId = driveId
			const query = buildQueryString({
				userId,
				Prefix,
			})
			const { data } = await apiBackend.get(`/assets/directories?${query}`)
			dispatch(setAssets(data.data))
			return data.data.body
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const getDirectories = createAsyncThunk(
	'assets/getDirectories',
	async ({ driveId, Prefix }, { dispatch }) => {
		try {
			const userId = driveId
			const query = buildQueryString({
				userId,
				Prefix,
			})
			const { data } = await apiBackend.get(`/assets/directories?${query}`)

			return data.data
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const getDirectoriesVersions = createAsyncThunk(
	'assets/getDirectoriesVersions',
	async (driveId, { dispatch }) => {
		try {
			const query = buildQueryString({
				userId: driveId,
			})
			const { data } = await apiBackend.get(
				`/assets/directories/versions?${query}`
			)

			console.log('dbdbdbd', data)
			dispatch(setAssetsVersions(data.data))
			return data.data.Versions
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const getFileData = createAsyncThunk(
	'assets/getFileData',
	async ({ fileName, driveId }, { dispatch }) => {
		try {
			const userId = driveId
			const query = buildQueryString({
				userId,
				fileName,
			})
			const { data } = await apiBackend.get(`/assets/file-data?${query}`)
			return data.data
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const getFile = createAsyncThunk(
	'assets/getFile',
	async ({ fileName, driveId }, { dispatch }) => {
		try {
			const userId = driveId
			const query = buildQueryString({
				userId,
				fileName,
			})
			const { data } = await apiBackend.get(`/assets/file?${query}`)
			return data.data
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const getSharedData = createAsyncThunk(
	'assets/getSharedData',
	async ({ driveId }, { dispatch }) => {
		try {
			const query = buildQueryString({
				userId: driveId,
			})
			const { data } = await apiBackend.get(`/assets/get-shared-files?${query}`)

			return data.data
		} catch (error) {
			console.log('Error shared data', error)
		}
	}
)

export const createNewFolder = createAsyncThunk(
	'assets/createNewFolder',
	async ({ name, driveId }) => {
		try {
			const userId = driveId
			const body = {
				userId,
				name,
			}
			const { data } = await apiBackend.post('/assets/new-folder', body)
			return data.data.body
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const uploadFile = createAsyncThunk(
	'assets/uploadFile',
	async ({ file, pathDepured, driveId }) => {
		try {
			const userId = driveId
			const formData = new FormData()
			formData.append('userId', userId)
			formData.append('path', pathDepured)
			formData.append('image', file)
			if (file.sheetId) formData.append('sheetId', file.sheetId)
			const { data } = await apiBackend.post('/assets/add-image', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			const fileData = {
				Key: data.data.Key,
				Location: data?.data?.Location || '',
				VersionId: data?.data?.VersionId || '',
				LastModified: getCurrentDateFormatted(),
				Size: file.size,
				IsLatest: true,
			}
			if (file.sheetId) fileData.sheetId = file.sheetId
			return fileData
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const uploadXLSX = createAsyncThunk(
	'assets/uploadXLSX',
	async ({ workspaceId, projectId, data, vector }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/addXLSX',
				{
					type: 'data',
					workspaceId,
					projectId,
					data,
					vector,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error UploadXLSX:', error)
		}
	}
)

export const copyFile = createAsyncThunk(
	'assets/copyFile',
	async ({ sourceKey, destinationKey, file, driveId }, { dispatch }) => {
		try {
			const userId = driveId
			const body = {
				sourceBucket: userId,
				sourceKey,
				destinationBucket: userId,
				destinationKey,
				userId,
			}
			const { data } = await apiBackend.post('/assets/copy-file', body)
			dispatch(
				copyFileLocal({
					...file,
					Key: destinationKey,
					LastModified: getCurrentDateFormatted(),
				})
			)
			return data.data.body
		} catch (error) {
			throw new Error(error)
		}
	}
)
export const shareFiles = createAsyncThunk(
	'assets/shareFiles',
	async ({ users, directories, selectedFolders }, { dispatch }) => {
		try {
			const body = {
				users,
				directories,
				selectedFolders,
			}
			const { data } = await apiBackend.post('/assets/share-files', body)
			return data
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const moveFile = createAsyncThunk(
	'assets/copyFile',
	async (
		{ sourceKey, destinationKey, file, VersionId, driveId },
		{ dispatch }
	) => {
		try {
			const userId = driveId
			const body = {
				sourceBucket: userId,
				sourceKey,
				destinationBucket: userId,
				destinationKey,
				userId,
				VersionId,
			}
			dispatch(filterFolder(sourceKey))
			dispatch(
				copyFileLocal({
					...file,
					Key: destinationKey,
					LastModified: getCurrentDateFormatted(),
				})
			)
			const { data } = await apiBackend.post('/assets/move-file', body)

			return data.data.body
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const deleteFolder = createAsyncThunk(
	'assets/deleteFolder',
	async ({ path, bucket }, { dispatch }) => {
		try {
			const userId = bucket
			const query = buildQueryString({
				userId,
				path,
			})
			const { data } = await apiBackend.delete(`/assets/delete-folder?${query}`)
			dispatch(filterFolder(path))
			return data.data.body
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const unshareFolder = createAsyncThunk(
	'assets/unshareFolder',
	async ({ path, driveId }, { dispatch }) => {
		try {
			const userId = 'sharedfilesdb'
			const query = buildQueryString({
				userId,
				path,
			})
			const { data } = await apiBackend.delete(`/assets/delete-folder?${query}`)
			return path
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const deleteFile = createAsyncThunk(
	'assets/deleteFile',
	async ({ path, VersionId, Size, act, driveId }, { dispatch }) => {
		try {
			const userId = driveId
			const query = buildQueryString({
				userId,
				path,
				VersionId,
			})
			const { data } = await apiBackend.delete(`/assets/file?${query}`)
			const objectData = { Key: path, VersionId, Size, act }

			return objectData
		} catch (error) {
			throw new Error(error)
		}
	}
)


export const deletePermanentFile = createAsyncThunk(
	'assets/deleteFiles',
	async ({ folders, action, driveId }, { dispatch }) => {
		try {
			const objectData = { Key: path, VersionId, Size, act }

			return objectData
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const deleteFiles = createAsyncThunk(
	'assets/deleteFiles',
	async ({ folders, act, driveId }) => {
		try {
			const userId = act === 'unshare' ? 'sharedfilesdb' : driveId
			const body = { folders, userId }
			const { data } = await apiBackend.put(`/assets/files`, body)
			const objectData = { folders, act }

			return objectData
		} catch (error) {
			console.error(error)
			throw error 
		}
	}
)

export const asd = createAsyncThunk('assets/asd', async (folders, driveId) => {
	try {
		const userId = driveId
		return folders
	} catch (error) {
		throw new Error(error)
	}
})

export const deleteFolders = createAsyncThunk(
	'assets/deleteFolders',
	async ({ folders, act, driveId }, { dispatch }) => {
		try {
			const userId = driveId
			const { data } = await apiBackend.put(`/assets/delete-folders`, {
				id: userId,
				folders,
			})
			const objectData = { folders, act }

			return objectData
		} catch (error) {
			throw new Error(error)
		}
	}
)
export const makeGlacier = createAsyncThunk(
	'assets/makeGlacier',
	async (fileName, driveId, { dispatch }) => {
		try {
			const userId = driveId
			const body = {
				userId,
				fileName,
				StorageClass: 'GLACIER',
			}
			const { data } = await apiBackend.put('/assets/glacier', body)
			return { fileName, data: data.data }
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const restoreGlacier = createAsyncThunk(
	'assets/restoreGlacier',
	async (fileName, driveId) => {
		try {
			const userId = driveId
			const body = {
				userId,
				fileName,
			}
			const { data } = await apiBackend.put('/assets/restore-glacier', body)
			return { fileName }
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const downloadFilesZip = createAsyncThunk(
	'assets/downloadFilesZip',
	async ({ driveId, Prefix }, { dispatch }) => {
		try {
			const response = await apiBackend.post(
				`/assets/download`,
				{
					userId: driveId,
					Prefix,
				},
				{
					responseType: 'blob',
				}
			)


			if (response.status === 200) {
				const blob = response.data 

				const url = window.URL.createObjectURL(blob)

				const a = document.createElement('a')
				a.href = url
				a.download = 'output.zip' 

				document.body.appendChild(a)
				a.click()
				a.remove()

				window.URL.revokeObjectURL(url)
			} else {
				console.log('Error en la descarga del archivo:', response.statusText)
			}
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const directoriesDB = createAsyncThunk(
	'assets/directoriesDB',
	async (folders, driveId, { dispatch }) => {
		try {
			const userId = driveId
			const { data } = await apiBackend.post(`/assets/directories-db`, {
				id: userId,
				folders,
			})

			return folders
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const deleteFolderLocal = (directory) => (dispatch) => {
	try {
		dispatch(filterFolder(directory))
	} catch (error) {
		console.error('Ha ocurrido un error al eliminar la carpeta:', error)
	}
}

export const addFolderLocal = (Key) => (dispatch) => {
	const data = { LastModified: getCurrentDateFormatted(), Key, IsLatest: true }
	try {
		dispatch(pushFolder(data))
	} catch (error) {
		console.error('Ha ocurrido un error al crear la carpeta:', error)
	}
}

export const obtainFileData = (directory) => (dispatch) => {
	try {
		dispatch(setFileToCopy(directory))
	} catch (error) {
		console.error('Ha ocurrido un error al crear la carpeta:', error)
	}
}

export const duplicateFileLocal = (newFile) => (dispatch) => {
	try {
		dispatch(copyFileLocal(newFile))
	} catch (error) {
		console.error('Ha ocurrido un error al crear la carpeta:', error)
	}
}

export const setFileDragging = (src) => (dispatch) => {
	try {
		dispatch(setFile(src))
	} catch (error) {
		console.error('Ha ocurrido un error al crear la carpeta:', error)
	}
}

export const selectCategory = (category) => (dispatch) => {
	try {
		dispatch(setCategory(category))
	} catch (error) {
		console.error('Error Ha ocurrido un error al seleccionar una categoria:', error)
	}
}


export const addAyFolder = createAsyncThunk(
	'assets/addAyFolder',
	async ({ document, driveId }) => {
		try {
			const { data } = await apiBackend.post('/assets/ay-add-folder', {
				userId: driveId,
				document: document,
			})

			return data.data
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const deleteAyFolder = createAsyncThunk(
	'assets/deleteAyFolder',
	async ({ id }) => {
		try {
			const { data } = await apiBackend.post(
				`/assets/ay-delete-folder/${id}`,
				{}
			)

			return data.data
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const fetchAyFolder = createAsyncThunk(
	'assets/fetchAyFolder',
	async ({ id }) => {
		try {
			const { data } = await apiBackend.get(`/assets/ay-folder/${id}`)
			return data
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const fetchsAyFolder = createAsyncThunk(
	'assets/fetchsAyFolder',
	async ({ query, offset = 0, limit = 10 }) => {
		try {
			let endpoint = `/assets/ay-folder?offset=${offset}&limit=${limit}`
			if (query) endpoint += `&query=${encodeURIComponent(query)}`

			const { data } = await apiBackend.get(endpoint)

			return data
		} catch (error) {
			throw new Error(error)
		}
	}
)

export const dragAyFile = createAsyncThunk(
	'assets/dragAyFile',
	async ({ id, base64, file, driveId }) => {
		try {
			const userId = driveId
			const tokenGPT = localStorage.getItem('token-gpt')
			const formData = new FormData()
			formData.append('userId', userId)
			formData.append('image', file)
			formData.append('base64', base64)
			formData.append('tokenGPT', tokenGPT)
			const { data } = await apiBackend.post(
				`/assets/ay-folder/${id}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)

			return data
		} catch (err) {
			console.log('Error', err)
		}
	}
)

export const dragAyPrompt = createAsyncThunk(
	'assets/dragAyPrompt',
	async ({ id, base64, prompt }) => {
		try {
			const tokenGPT = localStorage.getItem('token-gpt')

			const response = await apiBackend.post(`/assets/ay-prompt/${id}`, {
				base64,
				token: tokenGPT,
				prompt,
			})

			return response.data
		} catch (error) {
			throw new Error(error)
		}
	}
)
