import React from "react";
import { useTranslation } from "react-i18next";



import Cookies from './Cookies'

import styles from '../index.module.css'


const Copyright = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={`${styles["copyright-area"]} ${styles["copyright-style-one"]}`}>
        <div className={styles["container"]}>
          <div className={`${styles["row"]} ${styles["align-items-center"]}`}>
            <div className={`${styles["col-lg-6"]} ${styles["col-md-8"]} ${styles["col-sm-12"]} ${styles["col-12"]}`}>
              <div className={styles["copyright-left"]}>
                <ul className={`${styles["ft-menu"]} ${styles["link-hover"]}`}>
                  <li>
                    <button href="/privacy-policy">{t("home1.footer.text5")}</button>
                  </li>
                  <li>
                    <button href="/terms-policy">{t("home1.footer.text6")}</button>
                  </li>
                  <li>
                    <button href="/contact">{t("home1.footer.text7")}</button>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`${styles["col-lg-6"]} ${styles["col-md-4"]} ${styles["col-sm-12"]} ${styles["col-12"]}`}>
              <div className={`${styles["copyright-right"]} ${styles["text-center"]} ${styles["text-lg-end"]}`}>
                <p className={styles["copyright-text"]}>
                  Copyright © 2024{" "}
                  <button
                    className={styles["btn-read-more"]}
                  >
                    <span>Aythen.</span>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cookies />
    </>
  );
};

export default Copyright;
