import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const fetchsAddon =
  createAsyncThunk('addon/fetchsAddon',
    async (addonId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          '/addon',
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchAddon =
  createAsyncThunk('addon/fetchAddon',
    async (id, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/addon/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );


        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const addAddon =
  createAsyncThunk('addon/addAddon',
    async (addon, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/addon',
          { addon },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const updateAddon =
  createAsyncThunk('addon/update',
    async (addon, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.put(
          '/addon',
          { addon },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const deleteAddon =
  createAsyncThunk('addon/delete',
    async (addonId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(
          `/addon`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: { id: addonId },
        });

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const fetchsMapAddon =
  createAsyncThunk('vector/fetchsMapAddon',
    async ({ id }) => {

      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.post(
          `/addon/public/map`,
          {
            id
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );


        return res.data
      } catch (error) {
        console.log('Error map addon:', error)
      }
    })

export const addMapAddon =
  createAsyncThunk('addon/addonMap',
    async ({ component, data }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          `/addon/map`,
          {
            component,
            data
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );


        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchsI18Addon =
  createAsyncThunk('vector/fetchsI18Addon',
    async ({ lng = 'es', template }) => {

      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.post(
          `/addon/public/i18`,
          {
            lng,
            template
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );


        return {
          id: template,
          text: res.data
        }
      } catch (error) {
        console.log('Error i18 addon:', error)
      }
    })

export const addI18Addon =
  createAsyncThunk('addon/addonI18',
    async ({ lng = 'es', template, data }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          `/addon/i18`,
          {
            lng,
            template,
            data
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );


        return {
          id: template,
          text: resp.data
        }
      } catch (error) {
        throw error;
      }
    }
  );














export const fetchsPublicAddon =
  createAsyncThunk('vector/fetchsPublicAddon',
    async ({ href, path }) => {

      try {
        const res = await apiBackend.post(
          `/addon/public/href`, {
          href,
          path
        }
        )

        return res.data
      } catch (error) {
        console.log('Error public addon:', error)
      }
    })


export const fetchsPublicTemplateAddon =
  createAsyncThunk('vector/fetchsPublicTemplate',
    async ({ workspaceId, projectId }) => {
      try {
        const res = await apiBackend.post(
          `/addon/public`, {
          workspaceId,
          projectId

        })

        return res.data
      } catch (error) {
        console.log('Error public template:', error)
      }
    })




export const addTemplate =
  createAsyncThunk('addon/addTemplate',
    async ({ addon, vector }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/addon/add',
          {
            addon,
            vector
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        
        return resp.data
      } catch (error) {
        console.log('Error', vector.id, error)
        throw error;
      }
    }
  );



export const fetchsTemplate =
  createAsyncThunk('vector/fetchsTemplate',
    async ({ id, name }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.post(
          `/addon/${id}/${name}`, {

        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )

        return res.data
      } catch (error) {
        console.log('Error fetch template:', errror)
      }
    })


export const deleteTemplate =
  createAsyncThunk('vector/deleteTemplate',
    async ({ id, name }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/addon/${id}/${name}`, {

        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )

        return res.data
      } catch (error) {
        console.log('Error delete template:', errror)
      }
    })






export const visionAddon =
  createAsyncThunk('addon/vision',
    async (vision, { dispatch }) => {
      try {
        const tokenGPT = localStorage.getItem('token-gpt')
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/addon/vision',
          {
            token: tokenGPT,
            vision
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp;
      } catch (error) {
        throw error;
      }
    }
  );






export const dataAddon =
  createAsyncThunk('addon/data',
    async ({ prompt, image, mask }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const tokenGPT = localStorage.getItem('token-gpt')
        const resp = await apiBackend.post(
          '/addon/data',
          {
            token: tokenGPT,
            prompt,
            image,
            mask
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );


export const imageAddon =
  createAsyncThunk('addon/image',
    async ({ prompt, image, mask }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const tokenGPT = localStorage.getItem('token-gpt')
        const resp = await apiBackend.post(
          '/addon/image',
          {
            token: tokenGPT,
            prompt,
            image,
            mask
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );




export const colorAddon =
  createAsyncThunk('addon/color',
    async ({ prompt }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const tokenGPT = localStorage.getItem('token-gpt')
        const resp = await apiBackend.post(
          '/addon/color',
          {
            token: tokenGPT,
            prompt,
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );






export const styleAddon =
  createAsyncThunk('addon/style',
    async ({ id, code, type, style }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const tokenGPT = localStorage.getItem('token-gpt')


        const resp = await apiBackend.post(
          '/addon/style',
          {
            token: tokenGPT,
            code,
            type,
            style
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );


export const codeAddon =
  createAsyncThunk('addon/code',
    async ({ workspaceId, projectId, type, components }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const tokenGPT = localStorage.getItem('token-gpt')

        const arrComponents = components.filter(component => component.code === '').map(component => {
          return {
            id: component.id,
            prompt: component.prompt
          }
        });

        const resp = await apiBackend.post(
          '/addon/code',
          {
            workspaceId,
            projectId,
            token: tokenGPT,
            components: arrComponents,
            type,
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );

export const codeFetchs =
  createAsyncThunk('addon/codeFetchs',
    async ({ workspaceId, query, offset = 0, limit = 10 }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/addon/code/all`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
            params: {
              workspaceId,
              query,
              offset,
              limit,
            },
          },
        );


        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const codeFetch =
  createAsyncThunk('addon/codeFetch',
    async ({ workspaceId, projectId, query, offset = 0, limit = 10}, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get( 
          `/addon/code`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
            params: {
              workspaceId,
              projectId,
              query,
              offset,
              limit,
            },
          },
        );


        return resp.data;
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );

export const codeDelete =
  createAsyncThunk('addon/codeDelete',
    async ({ id }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(
          `/addon/code/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });


        return resp.data
      } catch (error) {
        throw error;
      }
    }
  );

export const codeInsertAddon =
  createAsyncThunk('addon/codeInsertAddon',
    async ({ workspaceId, projectId, type, components }, { dispatch }) => {
      try {
        const resp = await apiBackend.post(
          '/addon/code/insert',
          {
            workspaceId,
            projectId,
            token: tokenGPT,
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        );

        return resp.data
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );



export const createWallepaper =
  createAsyncThunk('addon/createWallepaper',
    async (prompt, { dispatch }) => {
      try {
        const tokenGPT = localStorage.getItem('token-gpt')
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/addon/wallepaper',
          {
            token: tokenGPT,
            prompt
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchsWallepaperAddon =
  createAsyncThunk('addon/fetchsWallepaperAddon',
    async ({id, query, offset = 0, limit = 10 }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/addon/wallepaper/all`,{
            headers: {
              'Authorization': `Bearer ${token}`
            },
            params: {
              id,
              query,
              offset,
              limit,
            },
          }
        );


        return resp.data;
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );



export const deleteWallepaper =
  createAsyncThunk('addon/delete',
    async (wallepaperId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(
          `/addon/wallepaper/${wallepaperId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );




export const createIcon =
  createAsyncThunk('addon/createIcon',
    async (prompt, { dispatch }) => {
      try {
        const tokenGPT = localStorage.getItem('token-gpt')
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/addon/icon',
          {
            token: tokenGPT,
            prompt
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp;
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchsIconAddon =
  createAsyncThunk('addon/fetchsIconAddon',
    async ({ }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/addon/icon/all`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );


        return resp.data;
      } catch (error) {
        console.log('Error', error)
        throw error;
      }
    }
  );



export const deleteIcon =
  createAsyncThunk('addon/delete',
    async (iconId, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.delete(
          `/addon/icon/${iconId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const fetchChatAddon =
  createAsyncThunk('addon/fetchChatAddon',
    async ({ addonId, componentId }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/addon/chat/${addonId}/${componentId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const addChatAddon =
  createAsyncThunk('addon/addChatAddon',
    async ({ addonId, componentId, chat }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/addon/chat',
          {
            addonId,
            componentId,
            chat
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );



