const drive = {
    t1: `Búsqueda rápida`,
    t2: `Crear nuevo`,
    t3: `Nueva carpeta`,
    t4: `Carpeta inteligente`,
    t5: `Subir archivo`,
    t6: `Subir carpeta`,
    t7: `Usar`,
    t8: `Mis documentos`,
    t9: `Mis documentos`,
    t10: `Soon`,
    t11: `Prioritarios`,
    t12: `Compartidos`,
    t13: `Recientes`,
    t14: `Destacados`,
    t15: `Papelera`,
    t16: `Papelera`,
    t17: `Un momento...`,
    t18: `Cancelar`,
    t19: `Crear Carpeta`,

    x1: `Nombre`,
    x2: `Última modificación`,
    x3: `Seleccionar todos`,
    x4: `Quitar todos`,
    x5: `Archivos recientes`,
    x6: `LanceDB`,
    x7: `Aythen DB`,
    x8: `Ver drive`,
    x9: `Inicio`,
    x10: `Nombre`,
    x11: `Nombre`,
    x12: `Tamaño`,
    x13: `Última modificación`,
    x14: `Elementos seleccionados`,
    x15: `Compartido con`,
}

export default drive