import apiBackend, { apiUrl } from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


import { setModal, setChunkSearch, setPrevIsLoad } from '../slices/iamSlice'


import ModalLimit from "../pages/shared/ModalLimit"
// const ModalLimit = await import(`../pages/shared/ModalLimit`)

const ID = 'test/test'

const encodeVector = (id) => {
	const str = `${id}`
	const base64Str = btoa(str)
	return base64Str
}

export const setOpenMenuLeft = createAsyncThunk(
	'iam/setOpenMenuLeft',
	async (value, { dispatch }) => {
		try {

			console.log('value', value)
			return value
		} catch (error) {
			// throw error
		}
	}
)

export const setOpenMenuRight = createAsyncThunk(
	'iam/setOpenMenuRight',
	async (value, { dispatch }) => {
		try {
			return value
		} catch (error) {
			throw error
		}
	}
)

export const setOpenChatBot = createAsyncThunk(
	'iam/setOpenChatBot',
	async (value, { dispatch }) => {
		try {
			return value
		} catch (error) {
			throw error
		}
	}
)

export const setOpenModal = createAsyncThunk(
	'iam/setOpenModal',
	async (value, { dispatch }) => {
		try {
			return value
		} catch (error) {
			throw error
		}
	}
)


export const sendConfirmAccess = createAsyncThunk(
	'sendConfirmAccess',
	async ({ code }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/account/access/send',
				{
					token: code,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)

export const confirmAccess = createAsyncThunk(
	'confirmAccess',
	async ({ code }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/account/access/confirm',
				{
					token: code,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.status == 423) {
				return 423
			} else if (error.status == 429) {
				return 429
			}
		}
	}
)

export const sendError = createAsyncThunk(
	'iam/sendError',
	async (value, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/send/error',
				{
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const deleteUser = createAsyncThunk(
	'iam/deleteUser',
	async ({ id, type }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.delete('/iam/user', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					id,
				},
			})

			return {
				id: response.data,
				type,
			}
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const addUser = createAsyncThunk(
	'iam/addUser',
	async (user, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/user',
				{
					user,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)


			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const fetchsUser = createAsyncThunk(
	'iam/fetchsUser',
	async ({ type, query, user }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get('/iam/user', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					type: type,
					query: query,
				},
			})

			return res.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el contacto'
			}
		}
	}
)


export const fetchsInvoice = createAsyncThunk(
	'iam/fetchsInvoice',
	async ({ type, query, offset = 0, limit = 10 }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/invoice', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					type,
					query,
					offset,
					limit,
				},
			})

			return {
				type: type,
				invoices: response.data.invoices,
				total: response.data.total,
			}
		} catch (error) {
			console.log('Error', error)
			throw 'Ya existe el usuario'
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const fetchInvoice = createAsyncThunk(
	'iam/fetchInvoice',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get(`/invoice/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const sendInvoice = createAsyncThunk(
	'iam/sendInvoice',
	async (id, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/invoice/send',
				{
					id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const addInvoice = createAsyncThunk(
	'iam/addInvoice',
	async ({ invoice, file }, { dispatch }) => {
		try {
			const formData = new FormData()
			formData.append('image', file, 'invoice.pdf')
			formData.append('invoice', JSON.stringify(invoice))

			const token = localStorage.getItem('token')
			const response = await apiBackend.post('/invoice', formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			})

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const updateInvoice = createAsyncThunk(
	'iam/updateInvoice',
	async ({ billing }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.put(
				'/invoice',
				{
					billing,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error: ', error.response.data.message)
			throw error.response.data.message
		}
	}
)

export const deleteInvoice = createAsyncThunk(
	'iam/deleteInvoice',
	async ({ id, type }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.delete('/invoice', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					id,
				},
			})

			return {
				id: response.data,
				type,
			}
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const readInvoice = createAsyncThunk(
	'iam/readInvoice',
	async ({ data, file, type }, { dispatch }) => {
		try {
			const tokenGPT = localStorage.getItem('token-gpt')
			const token = localStorage.getItem('token')

			const formData = new FormData()
			formData.append('file', data)
			formData.append('image', file)
			formData.append('type', type)
			formData.append('base64', data)
			formData.append('tokenGPT', tokenGPT)
			formData.append('token', token)
			const response = await apiBackend.post('/invoice/ocr', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const fetchsBillingExpenses = createAsyncThunk(
	'iam/fetchsBillingExpenses',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/iam/billing/expenses', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			return response.data
		} catch (error) {
			console.log('Error: ', error)
			throw error.response.data.message
		}
	}
)

export const fetchsBilling = createAsyncThunk(
	'iam/fetchsBilling',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/iam/billing/fetchs', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			return response.data
		} catch (error) {
			console.log('Error: ', error.response.data.message)
			throw error.response.data.message
		}
	}
)

export const updateBilling = createAsyncThunk(
	'iam/updateBilling',
	async ({ billing }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/billing/update',
				{
					billing,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error: ', error.response.data.message)
			throw error.response.data.message
		}
	}
)

export const newsletter = createAsyncThunk(
	'iam/newsletter',
	async (state, { dispatch }) => {
		try {
			const response = await apiBackend.post('/iam/account/newsletter', {
				state,
			})

			return response
		} catch (error) {
			console.log('Error: ', error.response.data.message)
			if (error.response.data.message == 301) {
				throw 'Token expried resend email'
			} else {
				throw error.response.data.message
			}
		}
	}
)

export const support = createAsyncThunk(
	'iam/support',
	async (state, { dispatch }) => {
		try {
			const response = await apiBackend.post('/iam/account/support', {
				state,
			})

			return response
		} catch (error) {
			console.log('Error: ', error.response.data.message)
			if (error.response.data.message == 301) {
				throw 'Token expried resend email'
			} else {
				throw error.response.data.message
			}
		}
	}
)

export const login = createAsyncThunk(
	'iam/login',
	async ({ user, password, remember }, { dispatch }) => {
		const token = localStorage.getItem('token')
		try {
			const response = await apiBackend.post('/iam/account/login', {
				remember,
				user,
				password,
				token_login: token
			})

			return {
				user: response.data.user,
				token: response.data.token,
			}
		} catch (error) {
			console.log('Error: ', error)
			if (error.status == 301) {
				throw 'Token expried resend email'
			} else if (error.status == 302) {
				const now = new Date()
				const lockAtTime = new Date(error.response.data.message)
				const minutes = Math.ceil((lockAtTime - now) / (1000 * 60))
				
				throw `User bloqueado hasta ${minutes} minutos`
			} else {
				throw error.response.data.message
			}
		}
	}
)

export const register = createAsyncThunk(
	'iam/register',
	async ({ user, password }, { dispatch }) => {
		try {
			const response = await apiBackend.post('/iam/account/register', {
				user,
				password,
			})

			const token = response.data.token
			return token
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else {
				console.log('Error:', error)
			}
		}
	}
)

export const upgrade = createAsyncThunk(
	'iam/userUpgrade',
	async ({ plan = 101, amount = 0, month = 0 }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/account/upgrade',
				{
					plan,
					amount,
					month,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return {
				user: response.data.token,
				token: response.data.user,
			}
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const fetchsDefault = createAsyncThunk(
	'iam/fetchsDefault',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/load/default',
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return {
				addons: response.data.addons,
				gpts: response.data.gpts,
				changelogs: response.data.changelogs,
				news: response.data.news,
			}
		} catch (error) {
			console.log('Error', error.response)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const updateDefault = createAsyncThunk(
	'iam/updateDefault',
	async ({ table, data }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.put(
				'/iam/load/default',
				{
					table,
					data,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error.response.status)
			if (error.response.status == 400 || error.response.status == 500) {
				throw 500
			}
		}
	}
)

export const recoverPassword = createAsyncThunk(
	'iam/recoverPassword',
	async ({ email }, { dispatch }) => {
		try {
			const response = await apiBackend.post('/iam/account/recover-password', {
				path: encodeVector(ID),
				email,
			})

			return 'Send email'
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw error.response.data.message
			}
		}
	}
)

export const updateAvatar = createAsyncThunk(
	'iam/updateAvatar',
	async ({ id, avatar }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/account/avatar',
				{
					id: id,
					avatar: avatar,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return {
				user: response.data.user,
				token: response.data.token,
			}
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const updateAccount = createAsyncThunk(
	'iam/updateAccount',
	async ({ user }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/account',
				{
					user,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return {
				user: response.data.user,
				token: response.data.token,
			}
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const updatePasswordAccount = createAsyncThunk(
	'iam/updatePasswordAccount',
	async ({ token, password }, { dispatch }) => {
		try {
			const response = await apiBackend.post(
				'/iam/account/password',
				{
					password,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return {
				token: response.data.token,
				user: response.data.user,
			}
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const confirm = createAsyncThunk(
	'iam/confirm',
	async ({ token }, { dispatch }) => {
		try {
			const response = await apiBackend.post(
				'/iam/account/confirm',
				{
					token,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return {
				user: response.data.user,
				token: response.data.token,
			}
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 301) {
				throw 'Token expired'
			} else if (
				error.response &&
				error.response.status >= 500 &&
				error.response.status < 600
			) {
				throw 500
			}
		}
	}
)

export const verify = createAsyncThunk(
	'iam/verify',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			// const respp =  await apiBackend.get('/iam/account/verify')
			// console.log('eeeeee', respp)
			const response = await apiBackend.post(
				'/iam/account/verify',
				{
					// plan: 12,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)


			// const response = await  axios.post('https://ay-cloud.com/service/v1/iam/accoun1', {
			// 	plan: 12
			//   }, {
			// 	headers: {
			// 		Authorization: `Bearer ${token}`,
			// 	  'Content-Type': 'application/json'
			// 	}
			//   });

			// console.log('eeeeee', response)

			return {
				user: response.data.user,
				billing: response.data.billing,
				error: response.data.error,
			}
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 501) {
				return 501
			} else if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 409) {
				return 409
			} else if (error.response.status == 500) {
				return 500
			} else if (
				error.response &&
				error.response.status >= 500 &&
				error.response.status < 600
			) {
				return 502 // falta licencia
			}
		}
	}
)

export const upgradeLicense = createAsyncThunk(
	'iam/upgradeLicense',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/account/license',
				{
					plan: 100,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const isAuth = (user, password) => {
	const token = localStorage.getItem('token')
	return Boolean(token)
}

export const logout = () => {
	localStorage.removeItem('token')
	return true
}

export const addAccount = createAsyncThunk(
	'iam/addAccount',
	async ({ user, tags, group }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/account/add-account',
				{
					user,
					tags,
					group,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const deleteAccount = createAsyncThunk(
	'iam/deleteAccount',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/account/delete-account',
				{
					id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const fetchsAccount = createAsyncThunk(
	'iam/fetchsAccount',
	async ({ user }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get('/iam/account/all-account', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					query: user,
					order: {
						type: 'ASC',
						param: 'createdat',
					},
				},
			})

			return res.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const upgradeAccount = createAsyncThunk(
	'admin/account/upgrade',
	async ({ id, plan, amount, month }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/admin/account/upgrade',
				{
					id,
					amount,
					plan,
					month,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)


export const getAllUsers = createAsyncThunk('iam/getAllUsers', async () => {
	try {
		const token = localStorage.getItem('token')
		const res = await apiBackend.get(`/iam/user/get-all`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		return res.data
	} catch (error) {
		console.log('Error:', error)
	}
})

export const addLicense = createAsyncThunk(
	'iam/addLicense',
	async ({ license }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/license/add-license',
				{
					license,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe la aplicación'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const deleteLicense = createAsyncThunk(
	'iam/deleteLicense',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/license/delete-license',
				{
					id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const fetchsLicense = createAsyncThunk(
	'iam/fetchsLicense',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/iam/license/all-license', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el license'
			}
		}
	}
)

export const addPolice = createAsyncThunk(
	'iam/addPolice',
	async ({ police }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/police/add-police',
				{
					police,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe la police'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const deletePolice = createAsyncThunk(
	'iam/deletePolice',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/police/delete-police',
				{
					id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const fetchsPolice = createAsyncThunk(
	'iam/fetchsPolice',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/iam/police/all-police', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)

export const addApi = createAsyncThunk(
	'iam/addApi',
	async ({ api }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/api/add-api',
				{
					api,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe la API'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const deleteApi = createAsyncThunk(
	'iam/deleteApi',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/police/delete-api',
				{
					id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const fetchsApi = createAsyncThunk(
	'iam/fetchsApi',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/iam/api/all-api', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)

export const addLog = createAsyncThunk(
	'iam/addLog',
	async (log, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/logs',
				{
					log,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el logs'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const deleteLog = createAsyncThunk(
	'iam/deleteLog',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.delete('/logs', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					id,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const deleteLogs = createAsyncThunk(
	'iam/deleteLogs',
	async (id, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.delete('/logs/all', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const fetchLog = createAsyncThunk(
	'iam/fetchLog',
	async ({ trail }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get(`/logs/${trail}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)

export const fetchsLog = createAsyncThunk(
	'iam/fetchsLog',
	async (
		{ type, query, offset = 0, limit = 10, startat, endat },
		{ dispatch }
	) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/logs', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					type,
					query,
					offset,
					limit,
					startat,
					endat,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)


export const sendPublicMail = createAsyncThunk(
	'iam/sendPublicMail',
	async ({ email, options }, { dispatch }) => {
		try {
			const response = await apiBackend.post('/iam/send/mail/public', {
				email,
				options,
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)

export const sendMail = createAsyncThunk(
	'iam/sendMail',
	async ({ email, options }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/send/mail',
				{
					email,
					options,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)


export const addDomain = createAsyncThunk(
	'iam/addDomain',
	async (domain, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/domain',
				{
					domain,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error al verificar dominio'
			}
		}
	}
)

export const verifyDomain = createAsyncThunk(
	'iam/verifyDomain',
	async ({ domain, txt }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/domain/verify',
				{
					domain,
					txt,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			if (!response.data.isverify) {
				return response.data
			}

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error al verificar dominio'
			}
		}
	}
)

export const denyDomain = createAsyncThunk(
	'iam/denyDomain',
	async (domain, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/domain/deny',
				{
					domain,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error deny domain'
			}
		}
	}
)

export const connectDomainVector = createAsyncThunk(
	'iam/connectDomainVector',
	async ({ id, domains }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/domain/connect',
				{
					id,
					domains,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Error al verificar dominio'
			}
		}
	}
)

export const fetchsDomain = createAsyncThunk(
	'iam/fetchsDomain',
	async (options, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.get('/iam/domain', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: options,
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el police'
			}
		}
	}
)


export const fetchsContract = createAsyncThunk(
	'iam/fetchsContract',
	async ({ type, query, offset = 0, limit = 10 }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/contract', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					type,
					query,
					offset,
					limit,
				},
			})

			return {
				contracts: response.data.contracts,
				total: response.data.total,
			}
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const fetchContract = createAsyncThunk(
	'iam/fetchContract',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get(`/contract/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const deleteContract = createAsyncThunk(
	'iam/deleteContract',
	async ({ id }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.delete('/contract', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					id,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const addContract = createAsyncThunk(
	'iam/addContract',
	async (contract, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/contract',
				{
					contract,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error contract', error)
			if (error.response.status == 502) {
				console.log('wdqd')
				console.log('wdqd')
				dispatch(setModal(<ModalLimit />))
				// dispatch(setModal(<div>
				// 	hello world
				// </div>))
				// return { error: 502 }
				throw 'Ya existe el usuario'
			} else if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const updateContract = createAsyncThunk(
	'iam/updateContract',
	async ({ contract }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.put(
				'/contract',
				{
					contract,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error: ', error.response.data.message)
			throw error.response.data.message
		}
	}
)

export const signContract = createAsyncThunk(
	'iam/signContract',
	async ({ id, signature }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/contract/sign',
				{
					id,
					signature,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const sendContract = createAsyncThunk(
	'iam/sendContract',
	async (id, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/contract/send',
				{
					id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)


export const fetchsBusiness = createAsyncThunk(
	'iam/fetchsBusiness',
	async (item, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.get('/iam/business', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			}
		}
	}
)

export const addBusiness = createAsyncThunk(
	'iam/addBusiness',
	async (business, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/business',
				{
					business,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)


export const deleteDesktop = createAsyncThunk(
	'iam/deleteDesktop',
	async ({ id, type }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.delete('/iam/desktop', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					id,
				},
			})

			return {
				id: response.data,
				type,
			}
		} catch (error) {
			console.log('Error', error)
		}
	}
)

export const addDesktop = createAsyncThunk(
	'iam/addDesktop',
	async (desktop, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/iam/contact',
				{
					desktop,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 400) {
				throw 'Ya existe el usuario'
			} else if (error.response.status == 501) {
				throw 'Permision denied'
			}
		}
	}
)

export const fetchsDesktop = createAsyncThunk(
	'iam/fetchsDesktop',
	async ({ userId }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')

			const response = await apiBackend.get('/iam/desktop', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
				},
			})

			return response.data
		} catch (error) {
			console.log('Error desk', error)
			if (error.response.status == 400) {
				throw 'Ya existe el contacto'
			}
		}
	}
)


export const fetchsNew = createAsyncThunk(
	'iam/fetchsNews',
	async ({ year, month, day, lang }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get('/iam/news', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					year,
					month,
					day,
					lang,
				},
			})

			return res.data
		} catch (error) {
			console.log('Error desk', error)
			if (error.response.status == 400) {
				throw 'Ya existe el contacto'
			}
		}
	}
)

export const fetchsSearch = createAsyncThunk(
	'iam/fetchsSearch',
	async ({ query, setChunk }, { dispatch1 }) => {
		try {
			const tokenGPT = localStorage.getItem('token-gpt')
			const token = localStorage.getItem('token')

			const formData = new FormData()
			formData.append('tokenGPT', tokenGPT)
			formData.append('token', token)
			formData.append('search', query)

			fetch(`${apiUrl}/service/v1/iam/search`, {
				method: 'POST',
				body: formData,
			})
				.then((response) => {
					const reader = response.body.getReader()
					const decoder = new TextDecoder()
					let buffer = '' 
					let jsonChunks = []

					const processStream = async () => {
						while (true) {
							const { done, value } = await reader.read()
							if (done) break

							buffer += decoder.decode(value, { stream: true })

							let jsonObjects = []
							try {
								buffer
									.split('\n')
									.filter(Boolean)
									.forEach((chunk) => {
										try {
											const parsedChunk = JSON.parse(chunk)
											jsonObjects.push(parsedChunk)
										} catch (e) {
											console.error('Invalid JSON chunk, waiting for more data')
										}
									})
							} catch (error) {
								console.log('Error parsing JSON from buffer:', error)
							}

							jsonObjects.forEach((item) => {
								jsonChunks.push(item)
								setChunk((prev) => [...prev, item.data]) // Enviar el item procesado
							})

							buffer = ''
						}
					}

					processStream()
						.then(() => {
							//- console.log('Stream completo, jsonChunks:', jsonChunks)
						})
						.catch(console.error)
				})
				.catch((error) => {
					console.error('Error error:', error)
				})
		} catch (error) {
			console.log('Error', error)
			throw error
		}
	}
)





export const fetchsTime = createAsyncThunk(
	'iam/fetchsTime',
	async ({ year, month, day, city }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get('/iam/time', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					year,
					month,
					day,
					city,
				},
			})

			console.log('time ioen', res)

			return res.data
		} catch (error) {
			console.log('Error desk', error)
			if (error.response.status == 400) {
				throw 'Ya existe el contacto'
			}
		}
	}
)
