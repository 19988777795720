const support = {
  t1: `Instrucciones de Aythen`,
  t2: `Ayudas y guias`,
  t22: `Aythen Lite Tools`,
  t33: `Tickets abiertos`,
  t3: `Acuerdo de Nivel de Servicio (SLA) es establecer la disponibilidad de la plataforma de software`,
  t4: `Contacta con nosotros`,
  t5: `Crea un ticket de soporte`,
  t6: `Obtén ayuda directamente de nuestro equipo.`,
  t7: `Administrar incidencias`,
  t8: `Comprueba tu licencia así como tus tickets abiertos y cerrados.`,
  t9: `¿Quieres saber más?`,
  t10: `¿Conoces Aythen PRO?`,
  t11: `Entra aquí para entenderlo y regístrate en nuestra lista de espera`,
  t12: `Monitoreo y Reporte`,
  t13: `Envíanos tus recomendaciones para saber con qué más te gustaría trabajar tus datos.`,
  topics: {
    1: {
      title: "Cookies y Privacidad",
      data: {
        1: {
          title: `Están seguros mis datos?`,
          text: `Trabajamos con la certificación ISO 27001. Es un estándar reconocido internacionalmente para la gestión de
                        la seguridad de la información. Esta certificación implica que se ha implementado un sistema de gestión de seguridad
                         de la información (SGSI) que cumple con los controles y procesos necesarios para proteger los datos de manera efectiva.`,
        },
        2: {
          title: `Experiencia del Usuario`,
          text: `Al utilizar un proveedor de servicios en la nube con certificaciones como ISO 27001 y que opera en la Unión Europea, 
                        damos una mayor confianza en la seguridad y la protección de los datos personales que manejan. Esto ayuda a cumplir con los 
                        requisitos de seguridad de la RGPD y a mitigar los riesgos asociados con el procesamiento de datos personales.`,
        },
      },
    },
    2: {
      title: `Bases Vectorizadas`,
      data: {
        1: {
          title: `¿Qué son?`,
          text: `Representan una innovación significativa en el campo del almacenamiento y procesamiento de datos. Son utilizadas en una variedad de aplicaciones, 
                        incluyendo la ciencia de datos, el aprendizaje automático y la inteligencia artificial. <br /><br />
                        En contraste con las bases de datos tradicionales, que almacenan información en forma de tablas con filas y columnas, 
                        las bases de datos vectorizadas utilizan estructuras de datos vectoriales para almacenar y manipular información de manera más eficiente. 
                        Organizando así los datos en vectores multidimensionales. Estos vectores pueden contener datos en diversos formatos de archivos, 
                        como números, texto, imágenes, código... Cada elemento del vector representa una unidad de información, y las operaciones se realizan 
                        de forma eficiente creando flujos entre conjuntos de informaciones en lugar de filas y columnas individuales.`,
        },
        2: {
          title: `¿Para qué sirven?`,
          text: `Esta estructura permite un acceso rápido a grandes volúmenes de datos y facilita operaciones matemáticas y lógicas complejas de manera simultánea. 
                        Es decir, elimina la latencia del dato sin la necesidad de procesar toda la información, solo la de interés, la cual se puede segmentar y organizar en clusters 
                        entre dimensiones y agentes. 
                        <br /><br />
                        - Cada dimensión representa una coordenada en un espacio multidimensional donde los datos están organizados. Esto significa que un conjunto de datos vectorizados 
                        puede tener múltiples dimensiones, lo que permite una representación más completa y detallada de la información.
                        <br /><br />
                        - Los agentes son entidades que realizan operaciones sobre los datos vectorizados. Estos agentes pueden ser programas, procesos o consultas que interactúan con 
                        la base de datos para recuperar, procesar o manipular información. Pueden acceder a diferentes dimensiones de los datos y realizar operaciones complejas en función 
                        de los requisitos para representar gráficamente o contestar a la pregunta realizada. `,
        },
      },
    },
    3: {
      title: `Back-office Serverless`,
      data: {
        1: {
          title: `¿En qué me beneficia?`,
          text: `En un entorno serverless, no es necesario administrar servidores físicos o virtuales. La infraestructura es gestionada para que la aplicación se 
                        pueda escalar automáticamente según la demanda. 
                        <br /><br />
                        Esto significa que no hay necesidad de prever picos de tráfico ni de ajustar manualmente la capacidad del servidor. La plataforma escalará automáticamente 
                        los recursos necesarios para manejar la carga de trabajo.`,
        },
        2: {
          title: `Flexibilidad Operativa`,
          text: `Por tanto, solo se pagan los recursos utilizados, lo que puede ahorrar en comparación con los servidores tradicionales donde se paga por capacidad
                        reservada, independientemente de si se utiliza o no.`,
        },
      },
    },
    4: {
      title: `Servicios on-demand`,
      data: {
        1: {
          title: `¿Lo puedo adaptar a mi empresa?`,
          text: `Puedes crear tus necesidades en base a tu propia estructura empresarial.`,
        },
        2: {
          title: `Logo de la empresa`,
          text: `Fundamental el poder agregar tu logo de la empresa en el registro para mantener la coherencia de marca en todas las interacciones con clientes, 
                        proveedores y otros actores.`,
        },
        3: {
          title: `Usuarios y roles`,
          text: `Puedes añadir y gestionar diferentes tipos de usuarios y roles en el IAM, si son trabajadores internos, o tu clientes externos, proveedores y otros 
                        colaboradores en nuestras tablas dinámicas. Así cada tipo de usuario puede tener distintos niveles de acceso y permisos para garantizar la seguridad y la 
                        privacidad de los datos.`,
        },
        4: {
          title: `Colores corporativos`,
          text: `Sería importante personalizar la apariencia del CRM con los colores corporativos de la empresa para reforzar la identidad visual de la marca. 
                        Esto incluiría la posibilidad de elegir los colores predominantes en la interfaz del CRM, como fondos, botones y barras de navegación.`,
        },
        5: {
          title: `Vinculación de cuentas bancarias`,
          text: `Para una gestión financiera eficiente, puedes vincular las tarjetas serverless Aythen a los IAM (accesos y roles) para que cada personal de la
                        empresa realice el seguimiento de transacciones, pagos y otras operaciones financieras directamente desde la plataforma, simplificando el proceso de gestión.`,
        },
      },
    },
    5: {
      title: `Interacciones a medida`,
      data: {
        1: {
          title: `Análisis Profundo`,
          text: `La representación vectorial permite un análisis más profundo de los datos.`,
        },
        2: {
          title: `Insights Estratégicos`,
          text: `Ofrecemos insights valiosos para la toma de decisiones estratégicas mediante dimensiones vectoriales.`,
        },
      },
    },
    6: {
      title: `Herramientas esenciales`,
      data: {
        1: {
          title: `Documentos Propios`,
          text: `Puedes subir nuevos documentos de texto, formato y colaboración en tiempo real. Admite cualquier variedad de 
                        formatos de archivo, incluidos documentos de texto, hojas de cálculo, presentaciones, formularios, dibujos y más.`,
        },
        2: {
          title: `Almacenamiento`,
          text: `Los documentos pueden ocupar espacio en tu cuenta. Por lo que puedes congelarlos para tener acceso a ellos cuando 
                        quieras o bien eliminarlos si no son de interés.`,
        },
        2: {
          title: `Compartir y Colaborar`,
          text: `Puedes compartir tus documentos con otros usuarios y trabajar juntos en tiempo real. Los colaboradores pueden ver 
                        los cambios en el documento a medida que se realizan y agregar comentarios para discutir ideas.`,
        },
        3: {
          title: `Factura electrónica`,
          text: `Ahorra tiempo y recursos. Automatiza el proceso de emisión, envío y recepción de facturas, lo que reduce la carga 
                        administrativa y costos asociados al papel. Reduce errores humanos y mejora la precisión en el registro y seguimiento de 
                        las transacciones financieras. Todo lo necesario para cumplir con la normativa de carácter obligatorio para cualquier autónomo 
                        y empresa para la regulación fiscal y legal.`,
        },
        3: {
          title: `Contratos`,
          text: `Centralización de la información relacionada con los clientes y los acuerdos comerciales, lo que facilita la gestión y 
                        el seguimiento de los contratos a lo largo de su ciclo de vida.`,
        },
        4: {
          title: `Dashboards de tus gráficas favoritas`,
          text: `Creando tus dashboards a medida en base a tus interacciones hechas te permite visualizar datos clave y métricas importantes 
                        de manera clara y concisa, lo que facilita la identificación de tendencias, patrones y áreas de oportunidad para poder tomar decisiones informadas. `,
        },
        5: {
          title: `Tablas dinámicas`,
          text: `Las tablas dinámicas ofrecen una manera flexible de resumir tus datos en función de tus proyectos. Enumera las fichas de 
                        las personas involucradas, el status, fechas, datos desde diferentes perspectivas y niveles de detalle. Puedes filtrar, agrupar y resumir datos de manera interactiva para obtener información detallada y tomar decisiones informadas.`,
        },
      },
    },
  },
  mvps: {
    1: {
      title: "Aythen Clendar",
      data: {
        1: {
          title: `Están seguros mis datos?`,
          text: `Organiza y planifica todas tus tareas en un calendario inteligente que se adapta a tus necesidades. Mantén un control preciso de tus compromisos y optimiza tu tiempo sincronizando todos tus planificadores en un mismo sitio. Permite a cada equipo planificar campañas, coordinar reuniones con clientes y establecer plazos de entrega gracias a su planificación con el Kan Ban. Con él puedes crear y asignar tickets y listas de tareas según el estatus y la prioridad.`,
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `- Planificación de Proyectos: Un equipo de desarrollo puede utilizar Aythen Calendar para asignar tareas y establecer plazos, asegurando que todos los miembros estén al tanto de sus responsabilidades.
      - Gestión Personal: Profesionales independientes pueden gestionar reuniones, fechas límite y eventos personales en un solo lugar.`,
        },
        3: {
          title: `Objetivos`,
          text: `- Mejorar la gestión del tiempo.
      - Facilitar la coordinación de equipos.
      - Aumentar la productividad personal y profesional.`,
        },
      },
    },
    2: {
      title: "Aythen DB",
      data: {
        1: {
          title: `Descripción`,
          text: `Almacena datos de forma inteligente gracias a las bases de datos vectorizadas. Facilita el acceso y la gestión de tus datos de manera óptima a través de la IA, las carpetas y sus rutas, así como asegurar una gestión documental eficaz realizando automatizaciones y copias de seguridad para cualquier información relevante. Incorpora cualquier formato o extensión de archivo para garantizar un manejo eficiente y seguro a través de la ISO 27001 cumpliendo con los estándares de la RGPD.`,
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `- Gestión Documental: Cualquier sector puede usar Aythen DB para gestionar grandes volúmenes de datos de documentos, fotos, vídeos, etc.
      - Investigación Científica: Investigadores pueden almacenar y acceder a grandes conjuntos de datos de forma rápida y eficiente.`,
        },
        3: {
          title: `Objetivos`,
          text: `- Optimizar el almacenamiento y acceso a datos.
      - Garantizar la seguridad de la información.
      - Facilitar la gestión de grandes volúmenes de datos.`,
        },
      },
    },
    3: {
      title: "Aythen OCR",
      data: {
        1: {
          title: `Descripción`,
          text: `Reconoce los datos de tu gestión documental con inteligencia artificial para agilizar la recogida de datos de tu negocio. Reduce el tiempo y los errores en la gestión de documentos contables, fiscales, laborables, catálogos, informes, certificados. Te permite enfocarte en lo que realmente importa. Al automatizar la lectura y clasificación de documentos en las carpetas inteligentes de Aythen DB, realizarás menos tareas repetitivas y minimizarás errores humanos.`,
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `- Contabilidad Empresarial: Empresas pueden automatizar la entrada de datos de facturas y recibos, reduciendo errores manuales.
      - Gestión de Certificados: Profesionales independientes pueden escanear y registrar leyes para confirmar la fabricación de cualquier producto.`,
        },
        3: {
          title: `Objetivos`,
          text: `- Agilizar la introducción de datos  y gestión de documentos.
      - Reducir errores humanos.
      - Mejorar la eficiencia operativa.`,
        },
      },
    },
    4: {
      title: "Aythen Trail",
      data: {
        1: {
          title: `Descripción`,
          text: `Firma y traza cualquier documento o acuerdo para crear lazos de confianza. Asegura la integridad y autenticidad de tus documentos, fortaleciendo la transparencia y seguridad en todas tus transacciones. Firma digitalmente y rastrear cada documento, garantizando su validez y permitiendo auditorías fáciles y rápidas. Esto no solo mejora la eficiencia, sino que también aumenta la confianza del cliente en la seguridad de sus acuerdos.`,
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `- Contratos Digitales: Empresas pueden firmar y verificar contratos electrónicamente, asegurando autenticidad y reduciendo papeleo.
      - Gestión Legal: Abogados pueden garantizar la integridad de documentos legales a lo largo de su ciclo de vida.`,
        },
        3: {
          title: `Objetivos`,
          text: `- Asegurar la integridad de los documentos.
      - Fortalecer la transparencia en transacciones.
      - Mejorar la seguridad documental.`,
        },
      },
    },
    5: {
      title: "Aythen View",
      data: {
        1: {
          title: `Descripción`,
          text: `Visualiza tus datos en tablas inteligentes y modifica cualquier estado de información con facilidad. Te ofrece una interfaz intuitiva para analizar y actualizar tus datos en tiempo real, mejorando la toma de decisiones informadas. Monitorea estados, seguimientos, datos, propuestas pudiendo cambiar y adaptar cualquier información en filas y columnas.`,
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `- Análisis de Ventas: Equipos de ventas pueden visualizar y analizar datos de rendimiento para ajustar estrategias en tiempo real.
      - Gestión de Inventario: Empresas pueden monitorizar y actualizar estados de inventario, mejorando la gestión logística.`,
        },
        3: {
          title: `Objetivos`,
          text: `- Facilitar la visualización y análisis de datos.
      - Mejorar la toma de decisiones.
      - Permitir actualizaciones en tiempo real.`,
        },
      },
    },
    6: {
      title: "Aythen Node",
      data: {
        1: {
          title: `Descripción`,
          text: `Tu espacio tridimensional donde gestionar tus flujos de información a medida tanto para interfaz como datos. Te proporciona un espacio de trabajo infinito y versátil para estructurar y editar tus procesos de desarrollo de forma eficiente y personalizada. Ubica todos tus intereses y organiza sus flujos facilitando un mayor acceso y coherencia en el proyecto.`,
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `- Desarrollo Web: Desarrolladores pueden estructurar flujos de trabajo de frontend y backend en un entorno integrado.
      - Proyectos Complejos: Equipos pueden coordinar diferentes partes de un proyecto, asegurando que todos los elementos se alineen correctamente.`,
        },
        3: {
          title: `Objetivos`,
          text: `- Facilitar la gestión de flujos de trabajo de desarrollo.
      - Proporcionar una plataforma versátil para desarrollo frontend y backend.
      - Mejorar la eficiencia en proyectos de desarrollo.`,
        },
      },
    },
    7: {
      title: "Aythen Vision",
      data: {
        1: {
          title: `Descripción`,
          text: `Mapea el tráfico de tus usuarios a través de eventos y análisis de calor. Obtén una visión detallada del comportamiento de tus usuarios, identificando patrones y oportunidades para optimizar la experiencia del cliente. Perfecto para analizar las áreas donde los clientes pasan más tiempo. Estos insights permiten mejorar el diseño y la funcionalidad del sitio para aumentar la conversión y la satisfacción del cliente.`,
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `- E-commerce: Analizar el comportamiento de los usuarios para mejorar la navegación y aumentar las conversiones.
      - Marketing Digital: Equipos de marketing pueden identificar qué partes de una página web atraen más atención y ajustar sus estrategias en consecuencia.`,
        },
        3: {
          title: `Objetivos`,
          text: `- Entender el comportamiento del usuario.
      - Identificar oportunidades de optimización.
      - Mejorar la experiencia del cliente.`,
        },
      },
    },
    8: {
      title: "Aythen Matrix",
      data: {
        1: {
          title: `Descripción`,
          text: `Edita y programa a medida el código fuente de tu proyecto y lánzalo rápidamente. La herramienta ideal para desarrolladores que buscan flexibilidad y rapidez en el desarrollo y despliegue de sus aplicaciones. Corrige y visualiza en tiempo real tus cambios acortando el ciclo de desarrollo y permitiendo lanzamientos más rápidos y frecuentes.`,
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `- Desarrollo Rápido: Startups pueden desarrollar y lanzar aplicaciones rápidamente, ajustando el código fuente a sus necesidades específicas.
      - Personalización de Aplicaciones: Equipos de desarrollo pueden adaptar y mejorar aplicaciones existentes de manera eficiente.`,
        },
        3: {
          title: `Objetivos`,
          text: `- Facilitar el desarrollo y despliegue rápido de aplicaciones.
      - Proporcionar flexibilidad en la programación del código fuente.
      - Mejorar la eficiencia en el desarrollo de software.`,
        },
      },
    },
    9: {
      title: "Aythen BI",
      data: {
        1: {
          title: `Descripción`,
          text: `Gracias a los vectores y a los nodos, tendrás tus datos en una latencia nula. 
              Invita a tu equipo según sus roles y crea análisis y predicciones a medida con gráficas, 
              dashboards y preguntas dinámicas. Centraliza los datos en un solo sistema, permitiendo un 
              sacar conclusiones informadas en tiempo real de cualquier propósito para tomar decisiones 
              en datos  en datos precisos y actualizados.`
        },
        2: {
          title: `Ejemplos de Aplicación`,
          text: `
- Inteligencia Empresarial: Empresas pueden utilizar dashboards y gráficos para tomar decisiones basadas en datos.
- Seguridad y Roles: Administradores pueden gestionar roles y accesos, asegurando que la información esté disponible solo para quienes la necesiten.`
        },
        3: {
          title: `Objetivos`,
          text: `- Facilitar la gestión y análisis de datos.
- Proporcionar herramientas avanzadas de inteligencia empresarial.
- Mejorar la toma de decisiones basada en datos.`
        }
      }
    }
  }
}

export default support