import { createSlice } from '@reduxjs/toolkit'
import {
	createCalendar,
	createEvent,
	deleteCalendar,
	deleteEvent,
	getCalendarEvents,
	getUserCalendars,
	updateCalendar,
} from '../actions/calendar'

export const calendarSlices = createSlice({
	name: 'calendar',
	initialState: {
		userCalendars: [],
		selectedCalendar: null,
		selectedCalendarData: {},
		selectedCalendarEvents: [],
		allUserEvents: [],
	},
	reducers: {
		setUserCalendars(state, action) {
			state.userCalendars = action.payload
		},
		setSelectedCalendar(state, action) {
			state.selectedCalendarData = action.payload
			state.selectedCalendar = action.payload.id
			state.selectedCalendarEvents = action.payload.events
		},
		setSelectedCalendarEvents(state, action) {
			state.selectedCalendarEvents = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserCalendars.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserCalendars.fulfilled, (state, action) => {
				state.loading = false
				state.userCalendars = action.payload
					? action.payload
					: state.userCalendars
				state.allUserEvents = action.payload
					? action.payload.map((cal) => cal.events).flat()
					: state.allUserEvents
			})
			.addCase(getUserCalendars.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(updateCalendar.pending, (state) => {
				state.loading = true
			})
			.addCase(updateCalendar.fulfilled, (state, action) => {
				if(action.payload){
					state.loading = false
				state.userCalendars = [
					...state.userCalendars.filter(
						(calendar) => calendar.id !== action.payload.id
					),
					action.payload,
				]
				state.selectedCalendarData =
					action.payload.id === state.selectedCalendar
						? action.payload
						: state.selectedCalendarData
				}
			})
			.addCase(updateCalendar.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(getCalendarEvents.pending, (state) => {
				state.loading = true
			})
			.addCase(getCalendarEvents.fulfilled, (state, action) => {
				state.loading = false
				state.selectedCalendarEvents = action.payload
			})
			.addCase(getCalendarEvents.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(createCalendar.pending, (state) => {
				state.loading = true
			})
			.addCase(createCalendar.fulfilled, (state, action) => {
				state.loading = false
				state.userCalendars = [...state.userCalendars, action.payload]
			})
			.addCase(createCalendar.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(deleteCalendar.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteCalendar.fulfilled, (state, action) => {
				state.loading = false
				state.userCalendars = [...state.userCalendars].filter(
					(calendar) => calendar.id !== action.payload
				)
			})
			.addCase(deleteCalendar.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(createEvent.pending, (state) => {
				state.loading = true
			})
			.addCase(createEvent.fulfilled, (state, action) => {
				state.loading = false
				state.selectedCalendarEvents = action.payload
			})
			.addCase(createEvent.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})

			.addCase(deleteEvent.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteEvent.fulfilled, (state, action) => {
				state.loading = false
				state.selectedCalendarEvents = [...state.selectedCalendarEvents].filter(
					(event) => event.id !== action.payload
				)
			})
			.addCase(deleteEvent.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
	},
})

export const {
	setUserCalendars,
	setSelectedCalendar,
	setSelectedCalendarEvents,
} = calendarSlices.actions

export default calendarSlices.reducer
