import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import gsap from 'https://cdn.skypack.dev/gsap@3.12.0';
import { Power2, Power4 } from 'https://cdn.skypack.dev/gsap@3.12.0/EasePack';
import ScrollTrigger from 'https://cdn.skypack.dev/gsap@3.12.0/ScrollTrigger';

import styles from './Intro.module.css';

import { useAppContext } from "@home/context/Context";


import ImageBalanceDark from './assets/intro/Balance Dark.svg';
import ImageBalanceLight from './assets/intro/Balance Light.svg';
import ImageBankDark from './assets/intro/Bank dark.svg';
import ImageBankLight from './assets/intro/Bank light.svg';
import ImageCalendarDark from './assets/intro/Calendar dark.svg';
import ImageCalendarLight from './assets/intro/Calendar light.svg';
import ImageConversionDark from './assets/intro/Conversion dark.svg';
import ImageConversionLight from './assets/intro/Conversion light.svg';
import ImageFinanceDark from './assets/intro/Finance Dark.svg';
import ImageFinanceLight from './assets/intro/Finance Light.svg';
import ImageHealthDark from './assets/intro/Health Dar.svg';
import ImageHealthLight from './assets/intro/Health Light.svg';
import ImageMapDark from './assets/intro/Map Dark.svg';
import ImageMapLight from './assets/intro/Map Light.svg';
import ImageMusicDark from './assets/intro/Music Dark.svg';
import ImageMusicLight from './assets/intro/Music Light.svg';
import ImageTasksDark from './assets/intro/Tasks dark.svg';
import ImageTasksLight from './assets/intro/Tasks light.svg';
import ImageTiempoDark from './assets/intro/Tiempo Dark.svg';
import ImageTiempoLight from './assets/intro/Tiempo Light.svg';

import ImageMobile from './assets/intro/mobile.svg';





const Test = () => {
  const { isLightTheme } = useAppContext();

  const widgetsRefs = useRef([]);

  widgetsRefs.current = [];

  const addWidgetRef = (element) => {
    if (element && !widgetsRefs.current.includes(element)) {
      widgetsRefs.current.push(element);
    }
  };

  useEffect(() => {
    if (widgetsRefs.current.length !== 11) return

    gsap.registerPlugin(ScrollTrigger);
    const iphone = document.querySelector(`[class^="iphone"]`);
    const widgets = document.querySelectorAll(`[class^="widgets"]`);

    if (!iphone || widgets.length === 0) {
      console.error("Error: Uno o más elementos no están presentes en el DOM.");
      return;
    }

    gsap.set(iphone, { x: -450, rotation: 90 });
    gsap.set(widgets, { opacity: 1, scale: 0 });

    function iPhoneAnimation() {
      const tl = gsap.timeline({ defaults: { duration: 1 } });
      tl.to(iphone, { x: 0 })
        .to(iphone, { rotation: 0, scale: 0.9 })
        .to(iphone, { duration: 3, scale: 1 });
      return tl;
    }

    function widgetAnimation() {
      const tl = gsap.timeline();
      tl
      .to(widgets, { duration: 0, scale: 0.3 });
      return tl;
    }

    const animations = [
      {
        selector: "#app-store",
        duration: 2,
        scale: 0.9,
        x: 500,
        y: 100,
        ease: 'cubic-bezier(0.165, 0.84, 0.44, 1)' 
      },
      {
        selector: "#screen-time",
        duration: 2,
        scale: 0.9,
        x: -500,
        y: -300,
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)' 
      },
      {
        selector: "#weather",
        duration: 2,
        scale: 1.1,
        x: -400,
        y: 350,
        ease: 'cubic-bezier(0.165, 0.84, 0.44, 1)' 
      },
      {
        selector: "#stocks",
        duration: 2,
        scale: 0.9,
        x: 530,
        y: -170,
        ease: 'cubic-bezier(0.165, 0.84, 0.44, 1)' 
      },
      {
        selector: "#fitness",
        duration: 2,
        scale: 1.1,
        x: -350,
        y: -100,
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)' 
      },
      {
        selector: "#find-my",
        duration: 2,
        scale: 1.1,
        x: 400,
        y: -360,
        ease: 'cubic-bezier(0.165, 0.84, 0.44, 1)' 
      },
      {
        selector: "#calendar",
        duration: 2,
        scale: 0.9,
        x: -630,
        y: 0,
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)' // Power2.easeOut
      },
      {
        selector: "#wallet",
        duration: 2,
        scale: 1,
        x: -280,
        y: 100,
        ease: 'cubic-bezier(0.165, 0.84, 0.44, 1)' 
      },
      {
        selector: "#apple-tv",
        duration: 2,
        scale: 1,
        x: 500,
        y: 300,
        ease: 'cubic-bezier(0.165, 0.84, 0.44, 1)' 
      },
      {
        selector: "#sleep",
        duration: 2,
        scale: 0.9,
        x: 270,
        y: -50,
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)' 
      },
      {
        selector: "#socials",
        duration: 2,
        scale: 1,
        x: 330,
        y: 120,
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)' 
      }
    ];

    const startTime = 2;
    const masterTimeline = gsap.timeline();
    masterTimeline.add(iPhoneAnimation()).add(widgetAnimation(), startTime);


    animations.forEach((animation, index) => {
      const { selector, duration, scale, x, y, ease } = animation;
      const element = document.querySelector(selector);
      masterTimeline.add(
        gsap.to(element, { duration, scale, x, y, ease }),
        startTime + (index % 3) / 2
      );
    });


    ScrollTrigger.create({
      animation: masterTimeline,
      trigger: "#animation",
      scrub: 1,
      pin: true,
      markers: false
    });
  }, [widgetsRefs]);

  return (
    <section id='animation' className={styles['animation']}>
      <img className={styles["iphone"]}
        src={ImageMobile}
        alt="" />
      <img
        id='app-store'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageBalanceLight : ImageBalanceDark}
        alt="" />
      <img
        id='screen-time'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageBankLight : ImageBankDark}
        alt="" />
      <img
        id='weather'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageCalendarLight : ImageCalendarDark}
        alt="" />
      <img
        id='stocks'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageFinanceLight : ImageFinanceDark}
        alt="" />
      <img
        id='calendar'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageConversionLight : ImageConversionDark}
        alt="" />
      <img
        id='fitness'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageHealthLight : ImageHealthDark}
        alt="" />
      <img
        id='find-my'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageMapLight : ImageMapDark}
        alt="" />
      <img
        id='sleep'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageMusicLight : ImageMusicDark}
        alt="" />
      <img
        id='apple-tv'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageTasksLight : ImageTasksDark}
        alt="" />
      <img
        id='wallet'
        className={styles['widgets']}
        ref={addWidgetRef}
        src={isLightTheme ? ImageTiempoLight : ImageTiempoDark}
        alt="" />
      <div
        id='socials'
        className={`${styles['socials']} ${styles['widgets']}`}
        ref={addWidgetRef}>
        <button href='https://twitter.com/GibsonSMurray' target="_blank" title="𝕏 account">
          <span>𝕏</span>
        </button>
        <button href="https://haptic.app/" target="_blank" title='inspiration'>
          <span>✰</span>
        </button>
      </div>
    </section>
  );
};

export default Test;
