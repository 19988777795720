import React, { useState, useEffect, useRef, createContext } from "react"


import Image0 from '../app/v1-1/components/BarTheme/assets/background-0.jpg'
import Image1 from '../app/v1-1/components/BarTheme/assets/background-1.jpg'
import Image2 from '../app/v1-1/components/BarTheme/assets/background-2.jpg'
import Image3 from '../app/v1-1/components/BarTheme/assets/background-3.jpg'



import styles from './background.module.css'

const Background = () => {
  
    const images = [
      Image0,
      Image1,
      Image2,
      Image3,
    ]
  
    const [imageSrc, setImageSrc] = useState(null);
  
  
    useEffect(() => {
      const savedIndex = localStorage.getItem('background-desktop');
      const savedIndexAY = localStorage.getItem('background-desktop-ay');
  
      if (savedIndexAY !== null) {
        setImageSrc(savedIndexAY);
      } else if (savedIndex !== null) {
        const index = parseInt(savedIndex, 10);
        if (index >= 0 && index < images.length) {
          setImageSrc(images[index]);
        }
      }
    }, []);
  
    return (
      <div
        className={styles["app-background"]}
      >
        <div
          style={{ backgroundImage: `url('${imageSrc}')` }}
          className={`${styles["app-background-image"]} ${styles["background-image"]}`}
        />
      </div>
    )
  }

  
  export default Background