
const home = {
    breadcrumb: {
        text1: 'Inicio'
    },
    web: {
        component_0: {
            text1: `Empieza a explorar ahora`
        },
        component0: {
            text1: `Ahorra tiempo y automatiza tus `,
            text2: `datos`,
            text3: `Aythen, tu sistema operativo en la nube`,
            text4: `Pruébalo`,
        },
        component1: {
            text1: 'Juega con tus datos',
            label1: 'Word',
            label2: 'PDF',
            label3: 'PNG',
            label4: 'Excel',
            text2: 'para tu negocio',
            text3: `Libera tu potencial gracias
al Sistema Operativo Cloud de Aythen`,
            holder1: `Empieza por tus documentos. Por ejemplo: Detecta los datos de este #albarán#certificado#ticket#factura y conéctalo con #excels#carpetas#terceros#APIs`,
            button1: `Empezar con Aythen`
        },
        component2: {
            text1: `Tus datos, en la nube con Scaleway. RGPD / ISO:27001 - 550001 con Europa.`,
            text2: `Bienvenido a tu escritorio en la nube`,
            text3: `Entiende todo el potencial y juega con tus datos`
        },
        component3: {
            text1: `Crea carpetas inteligentes`,
            text2: `Donde puedas reconocer y automatizar los datos de tus documentos`
        },
        component4: {
            text1: `Interconectado`,
            text2: `Automatiza procesos con la entrada de datos que, junto con terceros, crearás proyectos`,
            button1: `Probar Ahora`
        },
        component44: {
            text1: `Únete a nuestra campaña`,
            text2: `Aquí vas a poder disfrutar de beneficios limitados con ofertas solo disponibles para los primeros usuarios de Aythen`,
            text3: `Descúbrelos`,
        },
        component5: {
            text1: `Tarifas al uso`,
            text2: `Para cualquier situación`,
        },
        component6: {
            text1: `Asistencia a personas`,
            text2: `Opiniones de la comunidad`,
            text3: `Nuestro compromiso es la sastifación del cliente`
        },
        component66: {
            text1: `Empieza tu gestión inteligente`,
            text2: `Juega con tus datos con carpetas
dentro de nuestro escritorio Cloud`,
            text3: `Si quieres saber más como los datos vectorizados permiten una gestión eficiente de tu escalabilidad, solo tienes que echar un vistazo a:`,
        }
    },
    footer: {
        text1: `Forma parte de la hueva revolución digital en el nuevo escritorio Cloud`,
        text2: `Únete a nuestras notícias`,
        text3: `Escribe tu email`,
        text4: `Contacta`,
        text5: `Política de privacidad`,
        text6: `Terminos y condiciones`,
        text7: `Contactanos`,
    },
    header: {
        "menu": {
            text1: "Iniciar sesion"
        },
        "topbar": {
            text1: `Por tiempo limitado`,
            text2: `Subscríbete a nuestra lista de espera para tener descuentos exclusivos`,
            text3: `Registrarse`,
        },
        "component0": {
            text1: "Login"
        },
        "navDashboardItem": [
            {
                "link": "profile-details",
                "text": "Profile"
            },
            {
                "link": "notification",
                "text": "Notification"
            },
            {
                "link": "chat-export",
                "text": "Chat Export"
            },
            {
                "link": "appearance",
                "text": "Appearance"
            },
            {
                "link": "plans-billing",
                "text": "Plans and Billing"
            },
            {
                "link": "sessions",
                "text": "Sessions"
            }
        ],
        "nav": [
            {
                "link": "",
                "isIcon": false,
                "isMenu": false,
                "text": "Aythen"
            },
            {
                "link": "pricing",
                "isIcon": false,
                "isMenu": false,
                "text": "Precio"
            },
            {
                "link": "contact",
                "isIcon": false,
                "isMenu": false,
                "text": "Contacta"
            },
            {
                "link": "roadmap",
                "isIcon": false,
                "isMenu": false,
                "text": "RoadMap"
            },
            {
                "link": "cloud",
                "isIcon": false,
                "isMenu": false,
                "text": "Cloud"
            }
        ],
        "smallNavItem": [
            {
                "link": "text-generator",
                "isDisable": false,
                "type": "default",
                "img": "../images/generator-icon/text.png",
                "text": "Text Generator",
                "badge": "",
                "icon": ""
            },
            {
                "link": "image-generator",
                "isDisable": false,
                "type": "default",
                "img": "../images/generator-icon/photo.png",
                "text": "Image Generator",
                "badge": "Hot",
                "icon": ""
            },
            {
                "link": "code-generator",
                "isDisable": false,
                "type": "default",
                "img": "../images/generator-icon/code-editor.png",
                "text": "Code Generator",
                "badge": "",
                "icon": ""
            },
            {
                "link": "image-editor",
                "isDisable": false,
                "type": "default",
                "img": "../images/generator-icon/photo.png",
                "text": "Image Editor",
                "badge": "",
                "icon": ""
            },
            {
                "link": "vedio-generator",
                "isDisable": false,
                "type": "default",
                "img": "../images/generator-icon/video-camera.png",
                "text": "Video Generator",
                "badge": "",
                "icon": ""
            },
            {
                "link": "email-generator",
                "isDisable": false,
                "type": "default",
                "img": "../images/generator-icon/email.png",
                "text": "Email Generator",
                "badge": "",
                "icon": ""
            },
            {
                "link": "",
                "isDisable": true,
                "type": "default",
                "img": "../images/generator-icon/website-design.png",
                "text": "Website Generator",
                "badge": "Coming",
                "icon": ""
            },

            {
                "link": "profile-details",
                "isDisable": false,
                "type": "setting",
                "text": "Profile Details",
                "badge": "",
                "icon": "user"
            },
            {
                "link": "/notification",
                "isDisable": false,
                "type": "setting",
                "text": "Notification",
                "badge": "",
                "icon": "shopping-bag"
            },
            {
                "link": "/chat-export",
                "isDisable": false,
                "type": "setting",
                "text": "Chat Export",
                "badge": "",
                "icon": "users"
            },
            {
                "link": "/appearance",
                "isDisable": false,
                "type": "setting",
                "text": "Apperance",
                "badge": "",
                "icon": "home"
            },
            {
                "link": "/plans-billing",
                "isDisable": false,
                "type": "setting",
                "text": "Plans and Billing",
                "badge": "",
                "icon": "briefcase"
            },
            {
                "link": "/sessions",
                "isDisable": false,
                "type": "setting",
                "text": "Sessions",
                "badge": "",
                "icon": "users"
            },
            {
                "link": "/application",
                "isDisable": false,
                "type": "setting",
                "text": "Application",
                "badge": "",
                "icon": "list"
            }
        ],
        "toolsItem": [
            {
                "link": "/text-generator",
                "isDisable": false,
                "title": "Text Generator",
                "img": "@home/assets/images/generator-icon/text_line.png",
                "badge": "",
                "wdt": 40
            },
            {
                "link": "/vedio-generator",
                "isDisable": false,
                "title": "Video Generator",
                "img": "@home/assets/images/generator-icon/video-camera_line.png",
                "badge": "Hot",
                "wdt": 27
            },
            {
                "link": "/code-generator",
                "isDisable": false,
                "title": "HTML Generator",
                "img": "@home/assets/images/generator-icon/code-editor_line.png",
                "badge": "",
                "wdt": 44
            },
            {
                "link": "#",
                "isDisable": true,
                "title": "Lyrics Generator",
                "img": "@home/assets/images/generator-icon/lyrics_line.png",
                "badge": "Coming",
                "wdt": 27
            },
            {
                "link": "/image-editor",
                "isDisable": false,
                "title": "Photo Editor",
                "img": "@home/assets/images/generator-icon/photo-editor_line.png",
                "badge": "Hot",
                "wdt": 36
            },
            {
                "link": "/image-generator",
                "isDisable": false,
                "title": "Image Generator",
                "img": "@home/assets/images/generator-icon/photo_line.png",
                "badge": "",
                "wdt": 36
            },
            {
                "link": "/text-generator",
                "isDisable": false,
                "title": "Speech to text",
                "img": "@home/assets/images/generator-icon/voice_line.png",
                "badge": "",
                "wdt": 27
            },
            {
                "link": "#",
                "isDisable": true,
                "title": "Website Generator",
                "img": "@home/assets/images/generator-icon/website-design_line.png",
                "badge": "Coming",
                "wdt": 36
            },
            {
                "link": "/code-generator",
                "isDisable": false,
                "title": "Code Generator",
                "img": "@home/assets/images/generator-icon/code-editor_line.png",
                "badge": "Hot",
                "wdt": 44
            },
            {
                "link": "/email-generator",
                "isDisable": false,
                "title": "Email Writer",
                "img": "@home/assets/images/generator-icon/email_line.png",
                "badge": "",
                "wdt": 44
            },
            {
                "link": "/text-generator",
                "isDisable": false,
                "title": "Text to speech",
                "img": "@home/assets/images/generator-icon/text-voice_line.png",
                "badge": "",
                "wdt": 36
            },
            {
                "link": "#",
                "isDisable": true,
                "title": "Chat with Documents",
                "img": "@home/assets/images/generator-icon/document_line.png",
                "badge": "Coming",
                "wdt": 31
            }
        ],
        "rightPanel": [
            {
                "today": [
                    {
                        "title": "AIWave Defination",
                        "isActive": true,
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Your last Question",
                        "isActive": false,
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Business Shortcurt Methode",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Best way to maintain code Quality",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    }
                ],
                "yesterday": [
                    {
                        "title": "How to write a code",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Form Html CSS JS",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "HTML Shortcurt Methode",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Best way to maintain code Quality",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "AIWave Defination",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Your last Question",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Unique Shortcurt Methode",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Generate a circle Image",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    }
                ],
                "previous": [
                    {
                        "title": "User Assistant Request",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Funtion Js",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Generate a Image",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Best way to maintain code Quality",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "AIWave Defination",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Your last Question",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Unique Shortcurt Methode",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Best way to maintain Remote Team",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    }
                ],
                "older": [
                    {
                        "title": "AI writing: Free Trial",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Your last Question",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Education Shortcurt Methode",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "1992 Environment Policy",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Senior UX/UI Design",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Your last Question",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Dark Mode Html CSS JS",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    },
                    {
                        "title": "Best way to maintain Remote Team",
                        "list": [
                            {
                                "icon": "refresh-cw",
                                "text": "Regenerate"
                            },
                            {
                                "icon": "tag",
                                "text": "Pin Chat"
                            },
                            {
                                "icon": "file-text",
                                "text": "Rename"
                            },
                            {
                                "icon": "share-2",
                                "text": "Share"
                            },
                            {
                                "icon": "trash-2",
                                "text": "Delete Chat"
                            }
                        ]
                    }
                ]
            }
        ],
        "leftPanel": [
            {
                "link": "/text-generator",
                "isDisable": false,
                "title": "Text Generator",
                "img": "@home/assets/images/generator-icon/text.png",
                "badge": ""
            },
            {
                "link": "/image-generator",
                "isDisable": false,
                "title": "Image Generator",
                "img": "@home/assets/images/generator-icon/photo.png",
                "badge": "NEW"
            },
            {
                "link": "/code-generator",
                "isDisable": false,
                "title": "Code Generator",
                "img": "@home/assets/images/generator-icon/code-editor.png",
                "badge": ""
            },
            {
                "link": "/vedio-generator",
                "isDisable": false,
                "title": "Video Generator",
                "img": "@home/assets/images/generator-icon/video-camera.png",
                "badge": ""
            },
            {
                "link": "/image-editor",
                "isDisable": false,
                "title": "Photo Editor",
                "img": "@home/assets/images/generator-icon/photo.png",
                "badge": ""
            },
            {
                "link": "/email-generator",
                "isDisable": false,
                "title": "Email Writer",
                "img": "@home/assets/images/generator-icon/email.png",
                "badge": ""
            },
            {
                "link": "/code-generator",
                "isDisable": false,
                "title": "HTML Generator",
                "img": "@home/assets/images/generator-icon/code-editor.png",
                "badge": ""
            },
            {
                "link": "#",
                "isDisable": false,
                "title": "Speech to text",
                "img": "@home/assets/images/generator-icon/voice.png",
                "badge": ""
            },
            {
                "link": "#",
                "isDisable": false,
                "title": "Text to speech",
                "img": "@home/assets/images/generator-icon/text-voice.png",
                "badge": ""
            },
            {
                "link": "#",
                "isDisable": true,
                "title": "Website Generator",
                "img": "@home/assets/images/generator-icon/website-design.png",
                "badge": "Coming"
            },
            {
                "link": "#",
                "isDisable": true,
                "title": "Lyrics Generator",
                "img": "@home/assets/images/generator-icon/lyrics.png",
                "badge": "Coming"
            },
            {
                "link": "#",
                "isDisable": true,
                "title": "Chat with Documents",
                "img": "@home/assets/images/generator-icon/document.png",
                "badge": "Coming"
            }
        ]
    },
    utilize: {
        "sidebar": [
            {
                "id": "getstarted",
                "text": "Getting started",
                "isShow": true,
                "list": [
                    {
                        "id": "text-generator-sec",
                        "text": "Connect with AI text generator"
                    },
                    {
                        "id": "pernalized-sec",
                        "text": "Get Personalized advices"
                    },
                    {
                        "id": "engage-sec",
                        "text": "Explore and engage"
                    }
                ]
            },
            {
                "id": "pricing",
                "text": "Getting started",
                "isShow": false,
                "list": [
                    {
                        "id": "example1",
                        "text": "Connect with AI text generator"
                    },
                    {
                        "id": "example2",
                        "text": "Get Personalized advices"
                    },
                    {
                        "id": "example3",
                        "text": "Get Personalized advices"
                    }
                ]
            },
            {
                "id": "corefeature",
                "text": "Core Feature's",
                "isShow": false,
                "list": [
                    {
                        "id": "#",
                        "text": "Connect with AI text generator"
                    },
                    {
                        "id": "#",
                        "text": "Get Personalized advices"
                    },
                    {
                        "id": "#",
                        "text": "Get Personalized advices"
                    }
                ]
            }
        ],
        "data": [
            {
                "id": "getstarted_two",
                "title": "Getting started",
                "body": [
                    {
                        "id": "text-generator-sec",
                        "title": "Connect with AI text generator",
                        "subTitle": "Deep Learning and Transformers",
                        "img": "/images/utilize-img/ut-image-01.png",
                        "desc": "All you have to do to register for an account on Rainbow - AI chat app is provide your name, email address, and password. You can begin examining the app's features as soon as you've registered. Chatting with friends, family, and even new acquaintances is made simple with the Rainbow",
                        "list": [
                            {
                                "text": "Around 2013, the use of deep learning, especially transformer architectures, revolutionized NLP."
                            },
                            {
                                "text": "Transformer models, like OpenAI's GPT (Generative Pre-trained Transformer) series, became"
                            },
                            {
                                "text": "AI text generators, including GPT-3, have found applications in chatbots, content creation"
                            }
                        ]
                    },
                    {
                        "id": "pernalized-sec",
                        "title": "Get Personalized advices",
                        "subTitle": "Deep Learning and Transformers",
                        "img": "/images/utilize-img/ut-image-02.png",
                        "desc": "All you have to do to register for an account on Rainbow - AI chat app is provide your name, email address, and password. You can begin examining the app's features as soon as you've registered. Chatting with friends, family, and even new acquaintances is made simple with the Rainbow",
                        "list": [
                            {
                                "text": "Around 2013, the use of deep learning, especially transformer architectures, revolutionized NLP."
                            },
                            {
                                "text": "Transformer models, like OpenAI's GPT (Generative Pre-trained Transformer) series, became"
                            },
                            {
                                "text": "AI text generators, including GPT-3, have found applications in chatbots, content creation"
                            }
                        ]
                    },
                    {
                        "id": "engage-sec",
                        "title": "Explore and engage",
                        "subTitle": "Deep Learning and Transformers",
                        "img": "/images/utilize-img/ut-image-03.png",
                        "desc": "All you have to do to register for an account on Rainbow - AI chat app is provide your name, email address, and password. You can begin examining the app's features as soon as you've registered. Chatting with friends, family, and even new acquaintances is made simple with the Rainbow",
                        "list": [
                            {
                                "text": "Around 2013, the use of deep learning, especially transformer architectures, revolutionized NLP."
                            },
                            {
                                "text": "Transformer models, like OpenAI's GPT (Generative Pre-trained Transformer) series, became"
                            },
                            {
                                "text": "AI text generators, including GPT-3, have found applications in chatbots, content creation"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "pricing_two",
                "title": "Getting started",
                "body": [
                    {
                        "id": "example1",
                        "title": "Connect with AI text generator",
                        "subTitle": "Deep Learning and Transformers",
                        "img": "/images/utilize-img/ut-image-01.png",
                        "desc": "All you have to do to register for an account on Rainbow - AI chat app is provide your name, email address, and password. You can begin examining the app's features as soon as you've registered. Chatting with friends, family, and even new acquaintances is made simple with the Rainbow",
                        "list": [
                            {
                                "text": "Around 2013, the use of deep learning, especially transformer architectures, revolutionized NLP."
                            },
                            {
                                "text": "Transformer models, like OpenAI's GPT (Generative Pre-trained Transformer) series, became"
                            },
                            {
                                "text": "AI text generators, including GPT-3, have found applications in chatbots, content creation"
                            }
                        ]
                    },
                    {
                        "id": "example2",
                        "title": "Connect with AI text generator",
                        "subTitle": "Deep Learning and Transformers",
                        "img": "/images/utilize-img/ut-image-02.png",
                        "desc": "All you have to do to register for an account on Rainbow - AI chat app is provide your name, email address, and password. You can begin examining the app's features as soon as you've registered. Chatting with friends, family, and even new acquaintances is made simple with the Rainbow",
                        "list": [
                            {
                                "text": "Around 2013, the use of deep learning, especially transformer architectures, revolutionized NLP."
                            },
                            {
                                "text": "Transformer models, like OpenAI's GPT (Generative Pre-trained Transformer) series, became"
                            },
                            {
                                "text": "AI text generators, including GPT-3, have found applications in chatbots, content creation"
                            }
                        ]
                    },
                    {
                        "id": "example3",
                        "title": "Connect with AI text generator",
                        "subTitle": "Deep Learning and Transformers",
                        "img": "/images/utilize-img/ut-image-03.png",
                        "desc": "All you have to do to register for an account on Rainbow - AI chat app is provide your name, email address, and password. You can begin examining the app's features as soon as you've registered. Chatting with friends, family, and even new acquaintances is made simple with the Rainbow",
                        "list": [
                            {
                                "text": "Around 2013, the use of deep learning, especially transformer architectures, revolutionized NLP."
                            },
                            {
                                "text": "Transformer models, like OpenAI's GPT (Generative Pre-trained Transformer) series, became"
                            },
                            {
                                "text": "AI text generators, including GPT-3, have found applications in chatbots, content creation"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    release: [
        {
            "date": "22 October 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "1.0",
            "content": [
                {
                    "status": "fixed",
                    "text": "Portfolio Single Page translate the date"
                },
                {
                    "status": "update",
                    "text": "Advanced Custom Fields PRO Plugin"
                },
                {
                    "status": "fixed",
                    "text": "AiWave Core"
                },
                {
                    "status": "improve",
                    "text": "Added Visit Site Button Target Options"
                }
            ]
        },
        {
            "date": "24 March 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "1.1",
            "content": [
                {
                    "status": "fixed",
                    "text": "Deprecations Issues"
                },
                {
                    "status": "fixed",
                    "text": "Team Addons Issues"
                },
                {
                    "status": "fixed",
                    "text": "Agency Banner Issues"
                },
                {
                    "status": "fixed",
                    "text": "Typography Issues"
                },
                {
                    "status": "fixed",
                    "text": "Color Issues"
                },
                {
                    "status": "update",
                    "text": "Advanced Custom Fields PRO Plugin"
                },
                {
                    "status": "update",
                    "text": "AiWave Core"
                },
                {
                    "status": "improve",
                    "text": "Page Speed"
                }
            ]
        },
        {
            "date": "12 January 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "1.2",
            "content": [
                {
                    "status": "fixed",
                    "text": "Team email issues"
                },
                {
                    "status": "update",
                    "text": "ACF Pro Plugin"
                }
            ]
        },
        {
            "date": "19 june 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "1.3",
            "content": [
                {
                    "status": "fixed",
                    "text": "Lightbox popup style issues fixing"
                },
                {
                    "status": "fixed",
                    "text": "Portfolio clickable issues fixing"
                }
            ]
        },
        {
            "date": "16 February 2024",
            "text": "Added",
            "issue": "Issues Fixing",
            "version": "1.4",
            "content": [
                {
                    "status": "new",
                    "text": "WooCommerce"
                },
                {
                    "status": "new",
                    "text": "Light/light Mode Switcher for All Demos"
                },
                {
                    "status": "fixed",
                    "text": "Demo Import Issues"
                },
                {
                    "status": "fixed",
                    "text": "Pricing Table Color Issues"
                },
                {
                    "status": "fixed",
                    "text": "Infobox Color Issues"
                },
                {
                    "status": "fixed",
                    "text": "Footer Menu Spacing Issus"
                },
                {
                    "status": "fixed",
                    "text": "Header Social Network and Button Color Issues"
                },
                {
                    "status": "fixed",
                    "text": "Theme Gradient Issue On Safari"
                },
                {
                    "status": "fixed",
                    "text": "Theme Options Layout Broken Issues"
                }
            ]
        },
        {
            "date": "04 January 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "1.5",
            "content": [
                {
                    "status": "fixed",
                    "text": "Portfolio single page issues"
                }
            ]
        },
        {
            "date": "10 November 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "2.0",
            "content": [
                {
                    "status": "fixed",
                    "text": "Portfolio single page issues"
                },
                {
                    "status": "fixed",
                    "text": "Contact form checkbox issues"
                },
                {
                    "status": "fixed",
                    "text": "Contact form radio button issues"
                },
                {
                    "status": "fixed",
                    "text": "Contact form acceptance issues"
                },
                {
                    "status": "fixed",
                    "text": "Contact form dropdown issues"
                }
            ]
        },
        {
            "date": "02 November 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "2.1",
            "content": [
                {
                    "status": "fixed",
                    "text": "Page title issues with SEO plugins"
                }
            ]
        },
        {
            "date": "17 October 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "2.2",
            "content": [
                {
                    "status": "fixed",
                    "text": "Global Typography Issues"
                }
            ]
        },
        {
            "date": "10 October 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "2.3",
            "content": [
                {
                    "status": "fixed",
                    "text": "One page scroling issues"
                },
                {
                    "status": "fixed",
                    "text": "Portfolio date format changing issues"
                },
                {
                    "status": "fixed",
                    "text": "Some responsive css issues"
                }
            ]
        },
        {
            "date": "3 October 2024",
            "text": "",
            "issue": "Issues Fixing",
            "version": "2.4",
            "content": [
                {
                    "status": "new",
                    "text": "Theme release"
                }
            ]
        }
    ],
    pricing: {
        breadcrumb: {
            text1: `Planes de Precios Para Todos`,
            text2: `Precios`,
        },
        component1: {
            text1: `Precios`,
            text2: `Comienza tu recorrido de contenido con IA`,
            text3: `Colabora con IA para generar contenido que
            tenga impacto.`,
            text4: `Basado en más de 20 000 reseñas en`,
            text5: `Compara planes y funciones`,
            text6: `Comparación de precios`,
            text7: `¿Tienes preguntas? Aquí puedes encontrar respuestas`,
            text8: `Si no has encontrado tu respuesta, contacta con nosotros y especifica en qué podemos ayudarte. `,
            button1: `Contactar`,
        },
        component2: {
            text1: `Comience ahora`,
            text2: `Mejor oferta`,
        },
        compare: {
            first: [
                ["Incluído", "Incluído", "Incluído", "Incluído", "Incluído"],
                ["Acceso", "Ilimitado", "Ilimitado", "Ilimitado", "Ilimitado"],
                ["Carpetas", "5 carpetas", "Ilimitado", "Ilimitado", "Ilimitado"],
                ["Capacidad", "1 GB", "20GB", "100GB", "200GB"],
                ["Escáner", "100/año", "150/mes", "500/mes", "1000/mes"],
                ["Firma", "20/año", "10/mes", "50/mes", "100/mes"],
                ["Soporte", false, true, true, true],
            ],
            second: [
                ["Soporte", "Soporte", "Soporte", "Soporte", "Soporte"],
                ["Tutoriales", true, true, true, true],
                ["Atención básica", false, true, true, true],
                ["Atención prioritaria", false, false, true, true],
                ["Atención urgente", false, false, false, true]
            ],
            third: [
                ["Escritorio", "Escritorio", "Escritorio", "Escritorio", "Escritorio"],
                ["Aythen Store", true, true, true, true],
                ["Carpetas", true, true, true, true],
                ["Tus notas", true, true, true, true],
                ["PIN a medida", true, true, true, true],
                ["Fondo personalizable", true, true, true, true],
                ["Paleta de colores", true, true, true, true],
            ],
            four: [
                ["Aythen Tools", "Aythen Tools", "Aythen Tools", "Aythen Tools", "Aythen Tools"],
                ["Calendario", false, true, true, true],
                ["Videollamada", false, true, true, true],
                ["Planificador", false, true, true, true],
                ["Business Intelligence", false, false, true, true],
                ["Mapas de calor", false, false, false, true],
                ["Acceso al código", false, false, false, true],
                ["Tarjeta Aythen", false, false, true, true]
            ],
            seven: [
                ["Formatos", "Formatos", "Formatos", "Formatos", "Formatos"],
                [".DOC", true, true, true, true],
                [".PDF", true, true, true, true],
                [".CSV", true, true, true, true],
                [".XML", false, false, true, true],
                [".PNG", false, false, true, true],
                [".JPG", false, false, true, true],
                [".MP3", false, false, true, true],
                [".HTML", false, false, true, true],
                ["Import", false, false, true, true],
                ["Export", false, false, true, true],
            ]
        }
    },
    accordion: [
        {
            "title": "¿Qué es Aythen?",
            "desc": `Tu sistema operativo con escritorios Clouds. 
          “Ay” es nuestro nueva extensión de archivo, para que luego, “then”, tengas tus datos en la nube, es decir, serverless. 
Nuestro logo es una Lambda. Representa la latencia nula del dato.
Pero la utilizamos del revés como una Y. 
En otras palabras, tus datos donde y cuando quieras son conectados en los 3 conceptos esenciales de cualquier proyecto: 
“Modelo / Vista / Controlador” o “UX-UI / front-end / back-end”. 
Por ello, nos dedicamos a almacenar, reconocer, analizar, sincronizar, construir y compartir que cualquier dato sea capaz de crear. `,
            "isExpand": true
        },
        {
            "title": "¿Cómo empiezo?",
            "desc": `Tienes una demostración en nuestra pantalla inicial. Allí vas a poder registrate para empezar tu prueba gratuita. 
          En ésta, tienes hasta 1GB de datos incluídos para toda la vida.   
          Esto implica que puedes crear y reconocer datos de tus documentos para almacenar en tus carpetas inteligentes. Vas a tener un 
          límite de acciones disponibles, pero te ayudará a entender y navegar por la plataforma. 
          Si necesitas un análisis de los mismos datos o crear a medida tu propio software, debes ampliar tu licencia para obtener todas las 
          funcionalidades y crear > escalar tu proyecto con Aythen.`,
            isExand: false
        },
        {
            "title": "¿Qué puedo hacer?",
            "desc": `Crea gráficas e interacciona con tus datos, de esta manera siempre vas a poder tomar decisiones informadas para tu presente y
           futuro, gracias a los datos del pasado, todo en la nube. 
Desde tu planificación de proyectos con Kan Ban, reuniones de equipo, crear automatizaciones para tus procesos manuales e, incluso, concetar éstos a 
tu ERP para que Aythen funcione “por detrás” vitaminando tu propio sistema.Puedes crear tu propia piscina de datos que tengas o que hayas creado en 
forma de “Big Data” gracias a nuestros bots. Asi, puedes sincronizarlos con interfaces de código inscrutado, dando forma a crear cualquier software a medida. 
Descubre el engranaje de las 9 herramientas donde cada una de ellas interaccionan con los datos de una forma única y sincronizada. 
Te recomendamos entrar en la plataforma, descúbrelo.`,
            "isExpand": false
        },
        {
            "title": "¿Cuánto vale Aythen?",
            "desc": `Tenemos diferentes maneras de medir esto según los GBs, los gráficos, las preguntas, etc. Es decir, lo mucho o poco que se utilice el programa 
          para que interaccionar con tus datos. 
          Hay dos formatos: 
          1. En base al uso, como “saldo” en tu Aythen Card, un sistema habitual entre particulares. Si consumes poco, pagas poco. Si por el contrario, consumes 
          bastantes recursos, pagas más. Paquetización, es decir “Kits” con descuentos para ofrecer una mensualidad, normalmente son los más populares en el mundo 
          empresarial.`,
            isExpand: false
        },
        {
            "title": "¿Somos seguros?",
            "desc": `El primer pensamiento al pensar en la nube es que no tienes el control de tus datos. Eso es una ilusión, no una verdad. 
          En nuestro sistema, ya no solo puedes  jugar con tus datos, sino que los controlas siempre bajo unos estándares de calidad de ISO:27001 para garantizar la 
          seguridad de tu información así como el RGPD Europeo. Con nuestro partnership de Scaleway (Cloud n.1 en Europa) podemos abastecer cualquier necesidad. 
          Todos los proveedores que quieran incorporar Aythen en su Cloud deben pasar un rigoroso estudio de validación para asegurar que los datos no se ven comprometidos. 
          Así, dentro de Aythen, podrás configurar servidores privados para cada uno de tus propósitos, a medida. `,
            "isExpand": false
        }, {
            "title": "¿Dónde y cuándo quiera? ",
            "desc": `Trabajar con un escritorio en la nube ofrece múltiples ventajas que mejoran la flexibilidad, accesibilidad y seguridad de tu entorno de trabajo. 
            Accesibilidad desde cualquier lugar, desde cualquier dispositivo con conexión a Internet, ya sea en casa, en una cafetería o incluso en una biblioteca pública. 
            Esto significa que no necesitas estar físicamente en tu oficina o con tu ordenador para trabajar con las herramientas y datos que necesitas.
Por tanto, no dependes de un único dispositivo, puedes simplemente iniciar sesión desde cualquier ordenador o móvil disponible y continuar tu trabajo. Incluo, si pierdes, 
te roban o se daña tu dispositivo, no perderás tus archivos, ya que todo está respaldado en la nube con actualizaciones y mantenimientos automatizados. 
De esta forma, obtienes una escalabilidad y un ahorro de costes para adaptarnos a tus necesidades, pudiendo entonces trabajar tus archivos desde el navegador, estés en iOS, Android, Windows, Linux, Ubuntu, etc. 
Sea cual sea tu sistema, con Aythen pones a trabajar tus datos. `,
            "isExpand": true
        }
    ],
    contact: {
        form: {
            text1: `Compañia`,
            text2: `Nombre`,
            text3: `Email`,
            text4: `Web`,
            text5: `Consulta`,
            text6: `Click para aceptar y enviar tu consulta`,
        },
        component1: {
            text1: `Get Started with a free quotation`,
            text2: `Contact`,
        },
        component2: {
            text1: `¿Con qué documentos podemos ayudarte?`,
            text2: `Contacto`,
            text3: `Online`,
            text6: `Legal`,
            text7: `Online`,
            text8: `RRHH`,
            text9: `Online`,
            text10: `Asunto: DUDAS`,
            text11: `Envía tu consulta por correo electrónico para revisar tu cuestión.`,
            text12: `Asunto: PARTNER`,
            text122: `Envía tu consulta por correo electrónico para revisar tu propuesta.`,
            text13: `Asunto: TALENTO`,
            text133: `Envía tu consulta por correo electrónico para revisar tu candidatura.`,
            text14: `Si ninguno de estos departamentos encaja en tu necesidades de contacto, no te preocupes. Envia con el asunto que creas conveniente y contactaremos contigo cuanto antes sea posible.`,

        }
    },
    roadmap: {
        beadcrumb: {
            text1: `Roadmap`,
            text2: `Roadmap`,
        },
        component1: {
            text1: `Missing a feature?`,
            text2: `Anything you're missing in our product? Drop <br /> a
                    message here to let us know!`,
            button1: `Send feature request`,

        },
        data: [
            {
                "heading": "Servicios de programación",
                "date": "Creación de Web-Apps, plugins y código a medida",
                "isCheck": true,
                "isLoading": false,
                "isBorader": true,
                "title": "Basados en:",
                "desc": `Esta primera fase, está dedicada a la creación y desarrollo a medida de de propuestas específicas por parte de agencias de marketing, empresas y particulares finales. `,
                "list": [
                    {
                        "desc": "React / Phyton / Node / UX-UI."
                    },
                    {
                        "desc": "Scrum / Agile / Reports"
                    }
                ]
            },
            {
                "heading": "Desarrollo de Aythen",
                "date": "Foco en la creación y mejora del sistema operativo",
                "isCheck": true,
                "isLoading": false,
                "isBorader": true,
                "title": "Basados en:",
                "desc": "Esta segunda fase se basa en poder tener la disponibilidad de hacer foco en el sistema operativo en la nube. De esta manera, se empiezan los primeros Beta Testers.",
                "list": [
                    {
                        "desc": "Gestión documental inteligente"
                    },
                    {
                        "desc": "Ahorro de tiempo y dinero"
                    },
                ]
            },
            {
                "heading": "Partnerships realizados",
                "date": "Colaboración con instituciones enfocadas al impulso de Aythen",
                "isCheck": true,
                "isLoading": true,
                "isBorader": true,
                "list": [
                    {
                        "desc": "Start Up Programm Scaleway (Cloud N.1 en Europa)"
                    },
                    {
                        "desc": "Docentes técnicos en la UAB (Universidad Autónoma de Barcelona)"
                    },
                    {
                        "desc": "Miembros de Tech Barcelona  (Hub Tecnológico de referencia) "
                    },
                    {
                        "desc": "Ponentes de IA y BigData (Formación y concienciación) "
                    },
                    {
                        "desc": "Agencias de MKT e IT (Sistemas por referidos) "
                    },
                ]
            },
            {
                "heading": "Primera entrada",
                "date": "¿Con qué documentos trabajas en tu día a día?",
                "isCheck": false,
                "isLoading": true,
                "isBorader": true,
                "title": "Basados en:",
                "desc": "Esta fase se centra en aliviar el dolor administrativo, es decir, papeleo. Automatizar tu entrada de datos en #PDF, #CSV, #PNG, lo que sea, pueda conectarse a una salida para crear alertas, conectar con terceros, etc.",
                "list": [
                    {
                        "desc": "Abierto de cara al público. "
                    },
                    {
                        "desc": "Betatesters satsfactorios."
                    },
                ]
            },
            {
                "heading": "Aythen AI",
                "date": "Crea con voz, textos, imágenes y genera creación de contenido. ",
                "isCheck": false,
                "isLoading": true,
                "isBorader": true,
                "title": "Basados en:",
                "desc": "Conecta el motor de Inteligencia Artificial que prefieras. Por defecto ofrecemos GPT, pero puedes conectar otros sistemas para alimentar tus procesos. Además, podrás utilizar y crear tu propia IA local.",
                "list": [
                    {
                        "desc": "Modelos predictivos de terceros."
                    },
                    {
                        "desc": "Herramientas de IA nativas."
                    },
                ]
            },
            {
                "heading": "Siguientes pasos",
                "date": "¿Ya trabajas bien tus documentos?",
                "isCheck": false,
                "isLoading": false,
                "isBorader": true,
                "title": "Basados en:",
                "desc": "Sincroniza estos datos, hasta ahora, almacenados. Con ellos, se pueden relacionar y analizar para crear tu panel de control administrador totalmente personalizable.",
                "list": [
                    {
                        "desc": "Sistema low-code con/sin código."
                    },
                    {
                        "desc": "Compartir y planificar tu proyecto."
                    },
                ]
            },
            {
                "heading": "Funciones avanzadas",
                "date": "Genera piscinas de datos en los que crear tu propia IA local",
                "isCheck": false,
                "isLoading": false,
                "isBorader": true,
                "title": "",
                "desc": "Gracias a las bases de datos vectorizadas tienes la capacidad de generar largos modelos de lenguaje/aprendizaje. Así puedes trabajar en una latencia nula mientras crear redes de datos extensas.",
                "list": [
                    {
                        "desc": "Sistema serverless en vector"
                    },
                    {
                        "desc": "Segmentación y clusters K-Means"
                    },
                ]
            },
            {
                "heading": "Futuras interacciones",
                "date": "Aplicaciones nativas y externas para procesar",
                "isCheck": false,
                "isLoading": false,
                "isBorader": true,
                "title": "",
                "desc": "Instala Netflix, Spotify, Fortnite y procesa de forma simultánea tus accesos de forma única. Es decir, puedes elegir tus servidores dedicados para cada App. Contacta con nosotros para publicar tu proyecto.",
                "list": [
                    {
                        "desc": "Acceso Cloud personalizable."
                    },
                    {
                        "desc": "Con Internet, Apple Store y Google Play."
                    },
                ]
            },
            {
                "heading": "Aythen Pay",
                "date": "Gestiona tus cuentas de gastos de tu empresa con tu tarjeta virtual o física",
                "isCheck": false,
                "isLoading": false,
                "isBorader": true,
                "title": "",
                "desc": "Conecta tu Repsol, Ticket Restaurante, cuenta de gastos, réditos de tu software o directamente gestiona tu empresa desde tu tarjeta gracias a la vinculación de tu ID. Así solo tú podrás tener acceso.",
                "list": [
                    {
                        "desc": "Identificación de rol."
                    },
                    {
                        "desc": "Control y gestión de cuentas."
                    },
                ]
            },
            {
                "heading": "Aythen Metaverse",
                "date": "Crea realidades a través del AR/XR/VR con dispositivos físicos",
                "isCheck": false,
                "isLoading": false,
                "isBorader": true,
                "title": "",
                "desc": "Conecta todas tus pantallas y nodos en un ambiente 3D para relacionar y gestionar tu creación de una forma mucho más interactiva. De esta forma, tu experencia con los datos es directa.",
                "list": [
                    {
                        "desc": "Representación immersiva."
                    },
                    {
                        "desc": "Mejora la experiencia del usuario."
                    },
                ]
            },
            {
                "heading": "Edición 3D",
                "date": "Crea y modela tus prototipos gracias a la generación de objetos",
                "isCheck": false,
                "isLoading": false,
                "isBorader": false,
                "title": "",
                "desc": "Crea objetos 3D a partir de texto. Gracias al motor de edición podrás editar sus vértices y modificar su forma para crear prototipos únicos en los que poder plantear cualquier proyecto. ",
                "list": [
                    {
                        "desc": "Innovación y disrupción."
                    },
                    {
                        "desc": "Compatibilidad y creación. "
                    },
                ]
            }
        ],
    },
    login: {
        text1: `Login with Google`,
        text2: `Login with Facebook`,
        text3: `Or continue with`,
        text4: `Enter email address`,
        text5: `Password`,
        text6: `Forgot password`,
        text7: `Sign In`,
        text8: `Don't have an account?`,
        text9: `Sign Up`,
        text10: `Rainbow-Themes is now a crucial component of our work!
                          We made it simple to collaborate across departments by
                          grouping our work`,
    },
    signup: {
        text1: `Login with Google`,
        text2: `Login with Facebook`,
        text3: `Or continue with`,
        text4: `Enter Your Name`,
        text5: `Enter email address`,
        text6: `Create Password`,
        text7: `Confirm Password`,
        text8: `Forgot password`,
        text9: `Sign Up`,
        text10: `Do you have an account?`,
        text11: `Sign In`,
        text12: `Rainbow-Themes is now a crucial component of our work!
                          We made it simple to collaborate across departments by
                          grouping our work`,
    }

}


export default home