import { createSlice } from "@reduxjs/toolkit"

import {
  addVector,
  deleteVector,
  duplyVector,
  fetchsVector,
  fetchVector,
  addVectorMatrixVersion,
  fetchsMatrixVersion,
  deleteMatrixVersion,
} from "../actions/vector"

const vectorSlice = createSlice({
  name: "vector",
  initialState: {
    vector: null,
    vectors: [],

    versionMatrix: {},
  },
  reducers: {
    setVector: (state, action) => {
      state.vector = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setMatrix: (state, action) => {
      state.matrix = action.payload
    },
    setDimension: (state, action) => {
      state.dimension = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsVector.fulfilled, (state, action) => {
        state.vectors = action.payload
      })
      .addCase(fetchVector.fulfilled, (state, action) => {
        state.vector = action.payload
      })

      .addCase(addVector.fulfilled, (state, action) => {
        state.vector = {
          ...action.payload,
          nodes: JSON.parse(action.payload.nodes),
          edges: JSON.parse(action.payload.edges),
        }
      })

      .addCase(deleteVector.fulfilled, (state, action) => {
        const { id } = action.payload

        state.vectors = state.vectors.filter((vector) => vector.id !== id)

        state.vector = null
      })
      .addCase(duplyVector.fulfilled, (state, action) => {
        state.vectors.push(action.payload)
      })

      .addCase(deleteMatrixVersion.fulfilled, (state, action) => {
        const deletedId = action.payload
        state.versionMatrix = state.versionMatrix.filter(
          (item) => item.id !== deletedId,
        )
      })
      .addCase(addVectorMatrixVersion.fulfilled, (state, action) => {
        if (action.payload) {
          state.versionMatrix.push(action.payload)
        }
      })
      .addCase(fetchsMatrixVersion.fulfilled, (state, action) => {
        state.versionMatrix = action.payload
      })
  },
})

export const { setVector, setData, setMatrix, setDimension } =
  vectorSlice.actions

export default vectorSlice.reducer
