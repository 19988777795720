import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'


import gsap from 'https://cdn.skypack.dev/gsap@3.12.0'
import ScrollTrigger from 'https://cdn.skypack.dev/gsap@3.12.0/ScrollTrigger'




import styles from './IntroSecond.module.css'

const Test = () => {
  const dispatch = useDispatch()

 

  const [audios, setAudios] = useState([]);

  const heroRef = useRef(null); 
  const imgRef = useRef(null); 

 
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.timeline({
      scrollTrigger: {
        trigger: heroRef.current, 
        start: 'top top',
        end: '-=60%',
        pin: true,
        scrub: true,
        markers: false,
      },
    })
    .to(imgRef.current, {
      scale: 2,
      z: 350,
      transformOrigin: 'center center',
      ease: 'power1.inOut',
    })
    .to(heroRef.current, {
      scale: 1.1,
      transformOrigin: 'center center',
      ease: 'power1.inOut',
    }, "<");

  }, []);



  
  return (
      <div className={styles["wrapper"]}>
        <div className={styles["content"]}>
          <section className={`${styles["section"]} ${styles["hero"]}`} ref={heroRef}></section>
          <section className={`${styles["section"]} ${styles["gradient-purple"]}`}></section>
          <section className={`${styles["section"]} ${styles["gradient-blue"]}`}></section>
        </div>
        <div className={styles["image-container"]}>
          <img ref={imgRef} src="https://assets-global.website-files.com/63ec206c5542613e2e5aa784/643312a6bc4ac122fc4e3afa_main%20home.webp" alt="image" />
        </div>
      </div>
  );
}


export default Test