import React from "react";

import styles from '../index.module.css'

const Sidebar = ({ sidebar }) => {
  return (
    <>
      <div className={`${styles.inner}`} >
        <div className={`${styles["content-item-content"]}`} >
          <div className={`${styles['rbt-default-sidebar-wrapper']}`} >
            <nav className={`${styles["mainmenu-nav"]}`} >
              <ul className={`${styles['dashboard-mainmenu']} ${styles['rbt-default-sidebar-list']}`} >
                {sidebar &&
                  sidebar.map((data, index) => (
                    <li className={`${styles["has-submenu"]}`}  key={index}>
                      <a
                        className={`${styles["collapse-btn"]} ${
                          data.isShow ? "" : styles.collapsed
                        }`}
                        data-bs-toggle="collapse"
                        href={`#${data.id}`}
                        role="button"
                        aria-expanded={data.isShow}
                        aria-controls={data.id}
                      >
                        <span>Getting started</span>
                      </a>
                      <div
                        className={`${data.isShow ? styles.show : styles.collapse}`}
                        id={data.id}
                      >
                        <ul className={`${styles.submenu} ${styles["rbt-default-sidebar-list"]}`} >
                          {data.list.map((item, i) => (
                            <li key={i}>
                              <a href={`#${item.id}`}>
                                <span>{item.text}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ))}
                <li>
                  <a href="#">
                    <span>Roadmap</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span>Enhanced Features</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span>Help and support</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
