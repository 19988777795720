import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createMeet = createAsyncThunk(
	'meet/createMeet',
	async ({ meetName, participantsIds, creatorId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/meet/createMeet',
				{ meetName, participantsIds, creatorId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error create meet:', error)
		}
	}
)

export const getUserMeets = createAsyncThunk(
	'meet/getUserMeets',
	async ({ userId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/getUserMeets',
				{
					userId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error user meets:', error)
		}
	}
)

export const addUsersToExistentMeet = createAsyncThunk(
	'meet/addUsersToExistentMeet ',
	async ({ meetId, participantsIds }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/addUsersToExistentMeet',
				{ meetId, participantsIds },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error users meet:', error)
		}
	}
)

export const deleteMeet = createAsyncThunk(
	'meet/deleteMeet ',
	async ({ meetId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/deleteMeet',
				{
					meetId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error delete meet:', error)
		}
	}
)

export const leaveMeet = createAsyncThunk(
	'meet/leaveMeet ',
	async ({ meetId, leaverId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/leaveMeet',
				{ meetId, leaverId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error leave meet:', error)
		}
	}
)

export const getMeetMessages = createAsyncThunk(
	'meet/getMeetMessages',
	async ({ meetId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/getMeetMessages',
				{
					meetId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error meet message:', error)
		}
	}
)

export const sendMessage = createAsyncThunk(
	'meet/sendMessage ',
	async ({
		meetId,
		type,
		senderId,
		senderName,
		senderPicture,
		message,
		hasFiles,
		files,
	}) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/sendMessage',
				{
					meetId,
					type,
					senderId,
					senderName,
					senderPicture,
					message,
					hasFiles,
					files,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error send message:', error)
		}
	}
)

export const setMessagesToReaded = createAsyncThunk(
	'meet/setMessagesToReaded ',
	async ({ meetId, readerId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/meet/setMessagesToReaded',
				{ meetId, readerId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error messages to readed:', error)
		}
	}
)
