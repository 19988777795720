import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit'

const encodeVector = (obj) => {
	var str = `${obj.workspaceId}/${obj.projectId}`

	if (obj.path) str += `/${obj.path}`

	const base64Str = btoa(str)
	return base64Str
}

export const decodeVector = (base64Str) => {
	const str = atob(base64Str)

	const [workspaceId, projectId] = str.split('/')
	return { workspaceId, projectId }
}

export const iniVector = (obj) => {
	return encodeVector(obj)
}

export const openVector = createAsyncThunk(
	'vector/openVector',
	async ({ path }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/file',
				{
					token,
					path,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			const file = JSON.parse(res.data.data.data[0].data)
			const blob = new Blob([file.buffer.data], { type: file.mimetype })

			const downloadLink = document.createElement('a')
			downloadLink.href = URL.createObjectURL(blob)
			downloadLink.download = file.originalname

			downloadLink.style.display = 'none'
			document.body.appendChild(downloadLink)

			downloadLink.click()

			document.body.removeChild(downloadLink)

			return 200
		} catch (error) {
			console.log('Error open vector:', error)
		}
	}
)

export const openFile = createAsyncThunk(
	'vector/openFile',
	async ({ data }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/file',
				{
					path: data.uri,
					vector: data.vector,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			const { type, data: base64Contente } = res.data.data.data[0]

			if (type == 'application/sheet') {
				return base64Contente
			}

			const dataURI = `data:${type};base64,${base64Content}`

			return dataURI
		} catch (error) {
			console.log('Error open file:', error)
		}
	}
)

export const loadVector = createAsyncThunk(
	'vector/loadVector',
	async ({ id, name, file }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/load/${id}/${name}`,
				{
					file,
					vector: [2, 2],
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return {
				uri: res.data.data.uri,
				vector: res.data.data.vector,
			}
		} catch (error) {
			console.log('Error load vector:', error)
		}
	}
)

export const addVector = createAsyncThunk(
	'vector/addVector',
	async ({ workspaceId, data }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/add',
				{
					workspaceId,
					data,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error add vector:', error)
		}
	}
)

export const addVectorMatrix = createAsyncThunk(
	'vector/addVectorMatrix',
	async ({ workspaceId, projectId, vector, data, version = null }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/data',
				{
					type: 'matrix',
					workspaceId,
					projectId,
					vector,
					data,
					version,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error vector matrix:', error)
		}
	}
)

export const addVectorMatrixVersion = createAsyncThunk(
	'vector/addVectorMatrixVersion',
	async ({ workspaceId, projectId, vector, data, version = null }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/data',
				{
					type: 'matrix',
					workspaceId,
					projectId,
					vector,
					data,
					version,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error matrix version:', error)
		}
	}
)

export const deleteMatrixVersion = createAsyncThunk(
	'vector/deleteMatrixVersion',
	async ({ projectId, id, version }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.delete(
				`/vector/matrix/${projectId}/${id}/${version}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error delete matrix version:', error)
		}
	}
)

export const fetchsMatrixVersion = createAsyncThunk(
	'vector/fetchsMatrixVersion',
	async ({ id, projectId, version }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get(
				`/vector/matrix/${projectId}/${id}/${version}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error fetchs matrix version:', error)
		}
	}
)

export const updateSheet = createAsyncThunk(
	'vector/updateSheet',
	async ({ workspaceId, projectId, data, vector }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/updateSheet',
				{
					workspaceId,
					projectId,
					data,
					vector,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error update sheet:', error)
		}
	}
)
export const changeStylesToEntireColumn = createAsyncThunk(
	'vector/changeStylesToEntireColumn',
	async ({
		workspaceId,
		projectId,
		vectorId,
		page,
		selectedColumn,
		styleToApply,
	}) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/changeStylesToEntireColumn',
				{
					workspaceId,
					projectId,
					vectorId,
					page,
					selectedColumn,
					styleToApply,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error styles entire column:', error)
		}
	}
)
export const fetchFirstRows = createAsyncThunk(
	'vector/fetchFirstRows',
	async ({ workspaceId, projectId, vectorId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/fetchFirstRows',
				{ workspaceId, projectId, vectorId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error first rows:', error)
		}
	}
)

export const addInitialSheetData = createAsyncThunk(
	'vector/addVectorData',
	async ({ workspaceId, projectId, data, vector }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/vector/uploadInitialSheetData',
				{
					type: 'data',
					workspaceId,
					projectId,
					data: data.data,
					vector,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			return 'Data sent!'
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const addVectorData = createAsyncThunk(
	'vector/addVectorData',
	async ({ workspaceId, projectId, data, vector, chunkIndex, totalChunks }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				'/vector/data',
				{
					type: 'data',
					workspaceId,
					projectId,
					data,
					vector,
					chunkIndex,
					totalChunks,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error add vector data:', error)
		}
	}
)

export const fetchsVectorData = createAsyncThunk(
	'vector/fetchsVectorData',
	async ({ workspaceId, projectId, vector }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/data/${workspaceId}/${projectId}/${vector}`,
				{
					filter: {
						id: vector,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error fetchs vector data:', error)
		}
	}
)

export const getSheetPagesRowQty = createAsyncThunk(
	'vector/fetchsVectorData',
	async ({ tableName }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/getSheetPagesRowQty`,
				{
					tableName,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error sheet pages row qty:', error)
		}
	}
)

export const updateVector = createAsyncThunk(
	'vector/updateVector',
	async ({ workspaceId, vector }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/update`,
				{
					workspaceId,
					vector,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error update vector:', error)
		}
	}
)

export const deleteVector = createAsyncThunk(
	'vector/deleteVector',
	async ({ workspaceId, projectId, data, couchDbs }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.delete(`/vector`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					workspaceId,
					projectId,
					data,
					couchDbs,
				},
			})

			return res.data
		} catch (error) {
			console.log('Error delete vector:', error)
		}
	}
)

export const removeAllVector = createAsyncThunk(
	'vector/removeAllVector',
	async ({ path }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/remove/all`,
				{
					path,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data.data.slice(0, 4)
		} catch (error) {
			console.log('Error remove all:', error)
		}
	}
)

export const fetchsVector = createAsyncThunk(
	'vector/fetchsVector',
	async ({ workspaceId, projectId = null, filter }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/all`,
				{
					workspaceId,
					projectId,
					filter,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			
			return res.data
		} catch (error) {
			console.log('Error fetchs vector:', error)
		}
	}
)

export const fetchXLSX = createAsyncThunk(
	'vector/fetchXLSX',
	async ({ sheetId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				`/vector/getSheetInitialData`,
				{
					workspaceId: null,
					projectId: sheetId,
					vectorId: null,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error xlx:', error)
		}
	}
)

export const fetchSheet = createAsyncThunk(
	'vector/fetchSheet',
	async ({ tableName, page, filter, newValues, remove }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/fetchSheetData`,
				{
					tableName,
					page,
					filter,
					newValues,
					remove,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error sheet:', error)
		}
	}
)

export const addFollowingRow = createAsyncThunk(
	'vector/addFollowingRow',
	async ({ tableName, page, rowData }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/addFollowingRow`,
				{
					tableName,
					page,
					rowData,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error following row:', error)
		}
	}
)

export const getRowData = createAsyncThunk(
	'vector/getRowData',
	async ({ tableName, page, row }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/getRowData`,
				{
					tableName,
					page,
					row,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error row data:', error)
		}
	}
)

export const getRowRangeData = createAsyncThunk(
	'vector/getRowRangeData',
	async ({ tableName, page, fromRow, toRow }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/getRowRangeData`,
				{
					tableName,
					page,
					fromRow,
					toRow,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error row range data:', error)
		}
	}
)

export const addNewPageToSheet = createAsyncThunk(
	'vector/addNewPageToSheet',
	async ({ workspaceId, projectId, vectorId, newPage }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				`/vector/addNewPageToSheet`,
				{
					workspaceId,
					projectId,
					vectorId,
					newPage,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error page to sheet:', error)
		}
	}
)

export const getSheetInitialData = createAsyncThunk(
	'vector/getSheetInitialData',
	async ({ workspaceId, projectId, vectorId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				`/vector/getSheetInitialData`,
				{
					workspaceId,
					projectId,
					vectorId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error sheet initial data:', error)
		}
	}
)

export const updateRowData = createAsyncThunk(
	'vector/updateRowData',
	async ({ tableName, page, row, updates }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/updateRowData`,
				{
					tableName,
					page,
					row,
					updates,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error update row data:', error)
		}
	}
)

export const getSheetPageRenamedColumns = createAsyncThunk(
	'vector/getSheetPageRenamedColumns',
	async ({ tableName, page }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/getSheetPageRenamedColumns`,
				{
					tableName,
					page,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error sheet page randem columns:', error)
		}
	}
)

export const updateSheetPageRenamedColumns = createAsyncThunk(
	'vector/updateSheetPageRenamedColumns',
	async ({ tableName, page, updates }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector/updateSheetPageRenamedColumns`,
				{
					tableName,
					page,
					updates,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error sheet page columns:', error)
		}
	}
)

export const fetchVector = createAsyncThunk(
	'vector/fetchVector',
	async ({ workspaceId, projectId, id }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				`/vector`,
				{
					workspaceId,
					projectId,
					id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error vector:', error)
		}
	}
)

export const getAllVector = createAsyncThunk(
	'vector/getAllVector',
	async () => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.get('/vector', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			return res.data.data.data.slice(0, 4)
		} catch (error) {
			console.log('Error all vector:', error)
		}
	}
)

export const duplyVector = createAsyncThunk(
	'vector/duplyVector',
	async ({ workspaceId, projectId }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				`/vector/duply`,
				{
					workspaceId,
					projectId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error duply vector:', error)
		}
	}
)

export const addBackupVector = createAsyncThunk(
	'vector/addBackupVector',
	async ({ id, name }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				`/vector/backup/${id}/${name}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error backup vector:', error)
		}
	}
)

export const deleteBackupVector = createAsyncThunk(
	'vector/deleteBackupVector',
	async ({ id, name }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.delete(
				`/vector/backup/${id}/${name}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.log('Error backup vector:', error)
		}
	}
)

export const invokeCode = createAsyncThunk(
	'vector/invokeCode',
	async ({ fn }) => {
		try {
			const token = localStorage.getItem('token')

			const res = await apiBackend.post(
				`/vector/code`,
				{
					fn,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			return { error: error.response.data }
		}
	}
)


export const detectDrop = (file) => {
	if (file.type.startsWith('video/vnd.dlna.mpeg-tts')) {
		return 'video/vnd.dlna.mpeg-tts'
	} else if (
		file.type.startsWith(
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		)
	) {
		return 'application/sheet'
	} else if (file.type.startsWith('text/javascript')) {
		return 'text/javascript'
	} else if (file.type.startsWith('text/css')) {
		return 'text/css'
	} else if (file.type.startsWith('text/html')) {
		return 'text/html'
	} else if (file.type.startsWith('video/')) {
		return file.type
	} else if (file.type.startsWith('image/')) {
		return file.type
	} else if (file.type.startsWith('application/x-zip-compressed')) {
		return 'application/x-zip-compressed'
	} else if (file.type.startsWith('application/pdf')) {
		return 'application/pdf'
	} else if (file.type.startsWith('text/plain')) {
		return 'text/plain'
	} else if (file.type.startsWith('audio/mpeg')) {
		return 'audio/mpeg'
	} else if (file.type.startsWith('application/json')) {
		return 'application/json'
	} else if (file.type.startsWith('application/postscript')) {
		return 'application/postscript'
	} else {
		const fileExtension = file.name.split('.').pop()

		switch (fileExtension.toLowerCase()) {
			case 'jsx':
				return 'text/jsx'
			case 'md':
				return 'text/md'
		}
	}
}

export const detectBot = async (value, type) => {
	let response

	const allowDrop = [
		'video/vnd.dlna.mpeg-tts',
		'text/javascript',
		'text/css',
		'text/html',
		'application/x-zip-compressed',
		'application/pdf',
		'text/plain',
		'audio/mpeg',
		'application/json',
		'application/postscript',
		'text/jsx',
		'text/md',
		'video/mp4',
		'image/png',
		'record',
	]

	const target = allowDrop.includes(type)
	if (target) {
		switch (type) {
			case 'record':
				response = {
					currentDate: new Date(),
					message: value.uri,
					data: JSON.stringify(value.volumeArray),
					type,
				}
				break
			default:
				response = {
					currentDate: new Date(),
					message: value.uri,
					data: JSON.stringify(value),
					type,
				}
		}
	} else {
		const regex = /@([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*)/
		const match = value.match(regex)

		if (match) {
			const camelCase = match[1]
			switch (camelCase) {
				case 'bot':
				case 'help':
					response = { currentDate: new Date(), message: value, type: 'bot' }
					break
				case 'text':
					response = { currentDate: new Date(), message: value, type: 'text' }
					break
				default:
					if (camelCase.startsWith('edit-')) {
						value = {
							flag: camelCase,
							component: 'component de prueba test A1',
						}
						response = {
							currentDate: new Date(),
							message: value,
							type: `edit/${camelCase.slice(5)}`,
						}
					} else {
						response = { currentDate: new Date(), message: value, type }
					}
			}
		} else {
			response = { currentDate: new Date(), message: value, type: 'text' }
		}
	}

	return response
}
