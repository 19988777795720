const iam = {
    account: {
        t1: `¿Accede a tu cuenta?`,
        t2: `Tu licencia de Windows expirará pronto`,
        t3: `Tienes que activar Windows en Configuración`,
        t4: `Ir a Configuración`,
        t5: `Cerrar`,
        t6: `Añadir Cuenta`,
        t7: `Administrar Perfil`,
    },
    auth: {
        t2: `Crea y comparte tus datos inteligentes`,
        t3: `Organiza y automatiza tus documentos Crea tu propia arquitectura de datos `,
        t4: `Diseña y desarrolla en el "low-code"`,
        t5: `Bases de datos vectorizadas`,
    },
    login: {
        t1: `Inicia Sesión`,
        t2: `Y accede a tu Escritorio`,
        t3: `O`,
        t4: `Email Address`,
        t5: `Password`,
        t6: `Recuperar contraseña`,
        t7: `Iniciar sesión`,
        t8: `Regístrate`,
        t9: `¿No tienes una cuenta?`,
        t10: `Recuerdame`,
    },
    register: {
        t1: `Regístrate gratis`,
        t2: `Y accede a tu espacio personal`,
        t3: `O`,
        t4: `Dirección de correo electrónico`,
        t5: `Contraseña`,
        t6: `Repetir contraseña`,
        t7: `Registrar`,
        t8: `¿Ya tienes una cuenta?`,
        t9: `Iniciar sesión`,
    },
    recover: {
        t1: `Recupera tu contraseña`,
        t2: `Y accede a tu espacio personal`,
        t3: `O`,
        t4: `Nueva contraseña`,
        t5: `Confirmar nueva contraseña`,
        t6: `Actualizar contraseña`,
        t7: `Dirección de correo electrónico`,
        t8: `Recuperar contraseña`,
        t9: `¿No tienes una cuenta?`,
        t10: `Iniciar sesión`,
    },
    table: {
        title: `No hemos encontrado datos en tu base vectorizada`,
        text: `Crear Nuevo`,
        button: `Más información`,
    },
    header: {
        t1: `Regresar atrás`,
        t2: `Gestión de identidad y acceso (IAM)`,
        t3: `Usuarios`,
        t4: `Licencias`,
        t5: `Políticas`,
        t6: `API Keys`,
        t7: `Logs`,
    },
    invoices: {
        title: 'No hemos encontrado facturas en tu base vectorizada',
        text: 'Crear Nuevo',
        button: 'Más información'
    },
    users: {
        t1: `A continuación se muestra una lista de usuarios de esta organización. 
            Puede ver más información sobre cada usuario.`,
        t2: `Qué son los usuarios`,
        t3: `Añadir usuario`,
        t4: `Invitar usuario(s)`,
        t5: `Ingrese la dirección de correo electrónico de cada usuario a invitar, separada por comas.`,
        t6: `Introduzca etiquetas de valor clave`,
        t7: `Las etiquetas de valor clave le ayudan a organizar a sus usuarios. Puede asignar hasta 10 etiquetas por usuario.`,
        t8: `Agregar a un grupo existente (opcional)`,
        t9: `Invitar`,
        t10: `Informacion del usuario`,
        t11: `Estado`,
        t12: `Activado`,
        t13: `Tipo`,
        t14: `Invitado`,
        t15: `Se unió`,
        t16: `Último acceso`,
        t17: `Eliminar usuario`,
        t18: `Al eliminar a un usuario de esta organización, se eliminan automáticamente sus claves API y cualquier política directamente asociada a ellas quedará huérfana.`,
        t19: `Eliminar usuario`,
    },
    licenses: {
        t1: `A continuación se muestra una lista de licencias en esta Organización. Puede ver más información sobre cada licencia.`,
        t2: `¿Qué son las licencias?`,
        t3: `Crear Licencia`,
        t4: `Licencias`,
        t5: `Crear una aplicación`,
        t6: `Ingrese un nombre y una descripción opcional`,
        t7: `Nombre`,
        t8: `El nombre de su aplicación sólo puede contener caracteres alfanuméricos, puntos y guiones.`,
        t9: `Descripción`,
        t10: `Ingrese etiquetas de valor clave (opcional)`,
        t11: `Las etiquetas de valor clave le ayudan a organizar sus licencias. Puede asignar hasta 10 etiquetas por licencia.`,
        t12: `Accesos a usuarios (opcional)`,
        t13: `Crear aplicación`,
    },
    polices: {
        t1: `Esta es una lista de las políticas de esta Organización. Puede ver más información sobre cada póliza.`,
        t2: `¿Qué son las políticas?`,
        t3: `Crear política`,
        t4: `Politicas`,
        t5: `Reglas`,
        t6: `Una regla consta de un alcance (por ejemplo, nivel de proyecto o nivel de organización) y uno o más conjuntos de permisos (por ejemplo, "enumerar todas las instancias").`,
        t7: `Obtenga más información sobre las reglas.`,
        t8: `Regla #1`,
        t9: `Hacer`,
        t10: `Organización`,
        t11: `Los conjuntos de permisos de IAM y facturación solo se pueden seleccionar en el nivel de organización.`,
        t12: `Acceso a recursos`,
        t13: `Da acceso a recursos (Instancias, Almacenamiento de objetos, Bases de datos, etc.) en tus Proyectos.`,
        t14: `Acceso a las funciones de la organización`,
        t15: `Brinde acceso a IAM, facturación, soporte y tickets de abuso y gestión de proyectos, todo manejado a nivel de organización.`,
        t16: `Validar`,
        t17: `Conjuntos de permisos`,
        t18: `Gerente de organización`,
        t19: `Agregar regla`,
    },
    apis: {
        t1: `A continuación se muestra una lista de las claves API de esta organización.`,
        t2: `Cómo crear claves API`,
        t3: `Generar clave API`,
        t4: `Apis`,
        t5: `Generar una clave API`,
        t6: `Copie y almacene su clave secreta. Solo se mostrará una vez.`,
        t7: `Gestiona tus vectores a través del acceso IAM añadiendo tus
            Claves API para usarlas`,
        t8: `ID de clave de acceso`,
        t9: `Llave secreta`,
        t10: `Cerrar Generar clave`,
        t11: `Generar una clave API`,
        t12: `Seleccionar portador de clave API`,
        t13: `Yo mismo (usuario de IAM)`,
        t14: `Una aplicación`,
        t15: `Descripción`,
        t16: `Vencimiento`,
        t17: `¿Se utilizará esta clave API para el almacenamiento de objetos?`,
        t18: `Yo mismo (usuario de IAM)`,
        t19: `Yo mismo (usuario de IAM)`,
        t20: `Generar clave API`,
        t21: `Cerrar`,
    },
    logs: {
        t1: `A continuación se muestra la lista de sus registros de recursos de IAM. Los recursos de IAM pueden ser usuarios, licencias, grupos, claves API y políticas de IAM. Puedes obtener más detalles sobre cada uno de ellos.`,
        t2: `¿Cómo entender mis registros?`,
        t3: `Filtrar por ID de recurso de IAM exacto`,
        t4: `Fecha`,
        t5: `Fecha de iam`,
        t6: `Categoria`,
        t7: `categoria`,
        t8: `Acción`,
        t9: `Acción`,
        t10: `Añadir`,
        t11: `Reiniciar`,
        t12: `Filtrar`,
        t13: `Logs`,
        t14: `Logs información`,
        t15: `Estado`,
        t16: `Activado`,
        t17: `Tipo`,
        t18: `Invitado`,
        t19: `Se unió`,
        t20: `Último acceso`,
        t21: `Eliminar registro`,
        t22: `Al eliminar un registro de esta organización, se eliminan automáticamente sus claves API y cualquier política directamente adjunta a ellas quedará huérfana.`,
        t23: `Eliminar registro`,
    },
}

export default iam