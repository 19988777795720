
import light from "@home/assets/images/light/switch/sun-01.svg";
import dark from "@home/assets/images/light/switch/vector.svg";

import styles from '../index.module.css'

const DarkSwitch = ({ isLight, switchTheme }) => {
  return (
    <div id="my_switcher" className={styles["my_switcher"]}>
      <ul onClick={switchTheme}>
        {isLight ? (
          <li>
            <button data-theme="light" className={`${styles["setColor"]} ${styles["light"]}`}>
              <img src={dark} alt="Sun images" />
            </button>
          </li>
        ) : (
          <li>
            <button data-theme="dark" className={`${styles["setColor"]} ${styles["dark"]}`}>
              <img src={light} alt="Vector Images" />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default DarkSwitch;
