import React from "react";
import Sidebar from "./Sidebar";
import { useTranslation } from "react-i18next";



import home from '@src/locales/es/home1'

import UtilizeBody from "./UtilizeBody";

import styles from '../index.module.css'

const Utilize = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={`${styles['rbt-utilize-area']} ${styles['rainbow-section-gap-big']}`} >
        <div className={styles.container} >
          <div className={`${styles["utilize-inner"]}`} >
            <div className={`${styles.row} ${styles['g-5']}`} >
              <div className={`${styles["col-lg-3"]}`} >
                <div className={`${styles["rbt-default-sidebar"]} ${styles["sticky-top"]} ${styles["rbt-shadow-box"]}`} >
                  <Sidebar sidebar={home.utilize.sidebar} />
                </div>
              </div>
              <div className={`${styles["col-lg-9"]} ${styles["inner-content"]} ${styles["pl--0"]}`} >
                <UtilizeBody utilize={home.utilize.data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Utilize;
