import { createSlice } from '@reduxjs/toolkit'
import {
	createMeet,
	deleteMeet,
	getMeetMessages,
	getUserMeets,
	leaveMeet,
	sendMessage,
	setMessagesToReaded,
} from '../actions/meet'
import { getAllUsers } from '../actions/iam'

export const meetSlices = createSlice({
	name: 'meets',
	initialState: {
		socketId: null,
		allUsers: [],
		userMeets: [],
		selectedMeet: null,
		joinedVideoChat: null,
		selectedMeetMessages: [],
	},
	reducers: {
		setUserMeets(state, action) {
			state.userMeets = action.payload
		},
		setSelectedMeet(state, action) {
			state.selectedMeet = action.payload
		},
		setSelectedMeetMessages(state, action) {
			state.selectedMeetMessages = action.payload
		},
		setSocketId(state, action) {
			state.socketId = action.payload
		},
		setJoinedVideoChat(state, action) {
			state.joinedVideoChat = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllUsers.pending, (state) => {
				state.loading = true
			})
			.addCase(getAllUsers.fulfilled, (state, action) => {
				state.loading = false
				state.allUsers = action.payload
			})
			.addCase(getAllUsers.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(getUserMeets.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserMeets.fulfilled, (state, action) => {
				state.loading = false
				state.userMeets = action.payload
			})
			.addCase(getUserMeets.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(createMeet.pending, (state) => {
				state.loading = true
			})
			.addCase(createMeet.fulfilled, (state, action) => {
				state.loading = false
				state.userMeets = [...state.userMeets, action.payload]
			})
			.addCase(createMeet.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(deleteMeet.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteMeet.fulfilled, (state, action) => {
				state.loading = false
				state.userMeets = [...state.userMeets].filter(
					(meet) => meet.id !== action.payload
				)
			})
			.addCase(deleteMeet.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(leaveMeet.pending, (state) => {
				state.loading = true
			})
			.addCase(leaveMeet.fulfilled, (state, action) => {
				state.loading = false
				state.userMeets = [...state.userMeets].filter(
					(meet) => meet.id !== action.payload
				)
			})
			.addCase(leaveMeet.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(getMeetMessages.pending, (state) => {
				state.loading = true
			})
			.addCase(getMeetMessages.fulfilled, (state, action) => {
				state.loading = false
				state.selectedMeetMessages = action.payload
			})
			.addCase(getMeetMessages.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(sendMessage.pending, (state) => {
				state.loading = true
			})
			.addCase(sendMessage.fulfilled, (state, action) => {
				state.loading = false
				state.selectedMeetMessages = action.payload
			})
			.addCase(sendMessage.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			.addCase(setMessagesToReaded.pending, (state) => {
				state.loading = true
			})
			.addCase(setMessagesToReaded.fulfilled, (state, action) => {
				state.loading = false
				state.selectedMeetMessages = action.payload
					? action.payload.sort((a, b) => {
							return new Date(a.createdAt) - new Date(b.createdAt)
						})
					: [...state.selectedMeetMessages]
			})
			.addCase(setMessagesToReaded.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
	},
})

export const {
	setUserMeets,
	setSelectedMeet,
	setSelectedMeetMessages,
	setSocketId,
	setJoinedVideoChat,
} = meetSlices.actions

export default meetSlices.reducer
