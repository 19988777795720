import React, { useEffect } from 'react'
import gsap from 'https://cdn.skypack.dev/gsap@3.12.0'
import MotionPathPlugin from 'https://cdn.skypack.dev/gsap@3.12.0/MotionPathPlugin';
import ScrollTrigger from 'https://cdn.skypack.dev/gsap@3.12.0/ScrollTrigger'


import styles from './Footer.module.css'

const Footer = () => {

    useEffect(() => {

        const mgBack = document.querySelector(`.${styles["mg-back"]}`)

        if (!mgBack) return false

        gsap.registerPlugin(MotionPathPlugin);
        gsap.registerPlugin(ScrollTrigger);

        const ballTL = gsap.timeline({ repeat: -1, defaults: { duration: 2, ease: 'none' } })
            .to('#b1', {
                scale: 0.75,
                motionPath: {
                    path: "#b1Path",
                    align: "#b1Path",
                    start: 0.25,
                    end: 0.75
                }
            }, 0)
            .add(() => mgBack.append(document.querySelector('#b1')), 1)
            .to('#b1', {
                scale: 1,
                motionPath: {
                    path: "#b1Path",
                    align: "#b1Path",
                    start: 0.75,
                    end: 1.25
                }
            }, 2)
            .add(() => document.querySelector(`.${styles["mg-front"]}`).append(document.querySelector('#b1')), 3)
            .to('#b1-dark', { scaleY: 0.8, ease: 'sine.inOut', transformOrigin: '88% 80%', yoyo: true, repeat: 1 }, 0)
            .to('#b1 ellipse', { scale: 0.6, ease: 'sine.inOut', transformOrigin: '0 50%', yoyo: true, repeat: 1 }, 0)
            .timeScale(0.25)

        window.onclick = () => ballTL.isActive() ? ballTL.pause() : ballTL.play() //toggle play on click

        gsap.timeline({
            scrollTrigger: {
                trigger: '#s1',
                start: 'top top', 
                endTrigger: '#s2',
                end: 'bottom top',
                scrub: 0.1
            }
        })
            .to(`.${styles.ring2}`, { y: 99, scaleY: 1.08 }, 0)
            .fromTo(`.${styles.ring1}`, { y: 2 }, { y: 60, skewY: 3 }, 0)
    }, [])

    return (
        <div className={styles.container}>

            <svg xmlns="http://www.w3.org/2000/svg" fill="#1d0042" stroke="#1d0042" strokeWidth="1.8" viewBox="10 0 970 200">

                <g className={styles.ring3}>
                    <path d="M543.5 60.6C269.8 27.7 1 26.8 1 64.2v386h998v-278c0-27.4-181.8-78.7-455.5-111.6z" />
                    <image mask="url(#m1)" y="35" width="999" height="170" preserveAspectRatio="none" href="data:image/jpg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wCEAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDIBERISGBUYLxoaL2NCOEJjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY//CABEIAEUBTQMBIgACEQEDEQH/xAAaAAEBAQADAQAAAAAAAAAAAAAAAgEDBQcG/9oACAEBAAAAAOzzQAAAAACus2qva3d1oAAAAX8dV3dXd1dXVVtbu60AAF+Z7V1V3dXd3d1d1dXVVVbu7rQL8jbu1tVW3VXV1d3dXd3V1dXVVVVu8vj2a1ut3d3a2q2ququrq7uru6u6vm8xwA01rdbu7tbW1VVdVdXV8/xuAAANNa3dbW7tVtc/WYAAAABrWt1zf//EABoBAQEBAAMBAAAAAAAAAAAAAAABAgMFBgT/2gAKAgIQAxAAAADm76JZSAAAAAPRdbjWLjXHcaxcazc6zc2WJYAD2fQSyyyy5ubnWbi41i41jWLjWNZuNZeu6MACFIWWWXNlzc3Nxr7vjoAAAAgFlj//xAAeEAACAwEBAQEBAQAAAAAAAAABBBESEwMCAEAgMP/aAAgBAQABAgA+7WmZmZmZmZmZmZmZmZmZmZmZmZmfBLQZDIYDA77a6XtP7efxcDgcDgcDgcDYbDQaDIZDIYDA77a6XtP4ufxYDIZDQaDQbDYbDYcDgcDgcDgcDYbDQaDQZDIZDAYHfbXS9p/vn966aa667DsO47hgMBkMhoNBoNBsNhsNhsNhwOBsNhsNhoNBoMhkMhkMBjcdtefT2bWta97300001122HcdwwGAyGQyGg0Gg2Gw2Gw2Gw2Gw2Gw2Gw0GuTXv/GZm1rWve99NNNddh2Hcdx3DAYDIZDIaDQaDYbDfFv3+SZm1rWve9x00011HYdtx3DHBj1wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxxxxxxyyy48v/EACgQAAMAAwAABAYCAwAAAAAAAAABYVFioQIQISIRMUBBUnESMiAwUP/aAAgBAQADPwD1fuHkeR5Y8seWPLHljyx5Y8seWPLHljyx5Y8seWPLHljyx5Y8seWPLHljyx5Y8seWPLHljyx5Y8seWPLHljyx5Y8sb+PqxfyfqLIsl8kIQhCF9d8z3P1+5SlKUpSlKUpSlEIQhCF9H8x/zfr9ylKUpSlKUpSlKUpSlKUpSiyIQhCEL/R8z3v9/wCL8qPI8lKUpSlKUpSlKUpSlKUpSl8qLIhCF6nv8X7+ipSlKUpSlKUpSlKUpSlKfP1Pf4v39bfKlKUpSlKU/t6nv8X7/wCRT+3qe9+774NuG3Dbhtw24bcNuG3Dbhtw24bcNuG3Dbhtw24bcNuG3Dbhtw24bcNuG3Dbhtw24bcNuG3Dbhtw24bcNuD/AC4P8uD/AC4eL3fDx8P/xAAeEQACAwEBAAMBAAAAAAAAAAAAAQQREhMDECAwQP/aAAgBAgEBPwDkzDMsplfwuOhxhxhxhxhxhxhx2PwZxZyZzZhmWUyvtkyZMGEc0ckcUPxQ/BDjocYcYcYcYcYccccfgcB+L/CiiijJkwYRzRyRxQ/FD8EPwRZZZZZZZZZZZZZZZZZZfwz/xAAbEQEBAQEBAQEBAAAAAAAAAAAAExIRAUAgMP/aAAgBAwEBPwDfjXjvjvxVVVVVVVVUUbb8a8d8d/emm221FFFFFFVVVVVVVVFP4dddaaabbbbUUUU+b//Z" />
                    <mask id="m1" fill="#fff">
                        <path d="M999,172c0,32.9-240.3,39.3-514,6.4S1,98.2,1,64c0-37.4,268.8-36.5,542.5-3.6S999,144.6,999,172z M520,64.6 c-193.6-24.6-409.4-26.4-410,5s167.8,65.7,361.4,90.3s421.1,27.9,421.7-3.6S713.6,89.2,520,64.6z" />
                    </mask>
                </g>

                <g id="b2">
                    <ellipse cx="933.8" cy="172.6" rx="7" ry="46" transform="rotate(-83.812 933.77606 172.57357)" />
                    <mask id="m6" fill="#fff" strokeWidth="1">
                        <circle id="c2" cx="899.2" cy="144.2" r="27.2" />
                    </mask>
                    <use fill="#ea42c2" href="#c2" />
                    <circle mask="url(#m6)" cx="917.8" cy="162.8" r="27.2" opacity="0.7" />
                </g>
                <use href="#b2" x="-853" y="-84" transform="scale(0.87)" />

                <g mask="url(#m4)">
                    <g className={styles.ring2}>
                        <path fill="#ff8caf" d="M217 70c0-18.3 128.9-37.2 283.9-38.8C655.8 29.6 782 42.1 782 58M217 70v260h565V58" />
                        <rect x="646" y="64" width="125" height="127" />
                        <path d="M782 58c0 19-135.6 40-290.5 41.5S217 89.8 217 70c0-18.3 128.9-37.2 283.9-38.8C655.8 29.6 782 42.1 782 58z" />
                        <image mask="url(#m2)" width="565" height="69" x="217" y="31" preserveAspectRatio="none" href="data:image/jpg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wCEAA4KCw0LCQ4NDA0QDw4RFiQXFhQUFiwgIRokNC43NjMuMjI6QVNGOj1OPjIySGJJTlZYXV5dOEVmbWVabFNbXVkBFRcXHhoeOyEhO3xTRlN8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fP/CABEIADIBRgMBIgACEQEDEQH/xAAaAAEBAQEBAQEAAAAAAAAAAAACAQMABgcF/9oACAEBAAAAAPwMgI3pprpppo203UqqlVbVbb1vzvPMctHrprpo222kqqlVVbVb1t+e5idW9NNNNG020lVUqqrVbetvgQZ3NaPRttNpJVVKqq1W3rb4YHutTbbSTSSqVSqqtVtvW+LJ7uSSTSVaVSqqVVVqtvW3yUk61VJVVJVVVVK1W1W3rb//xAAZAQEBAQEBAQAAAAAAAAAAAAACAQMABgT/2gAKAgIQAxAAAAD0m2uV7LTHXHbLTPTNDhxJhghhhh4zp6759D3BZa5aZa5vOimEmCGGGCTjOnqDZ3RZsPNhGjjCTBDDDBD0nT0as7pQigzTTDDDBCTDxhh7p//EAB8QAQEBAQACAwEBAQAAAAAAAAEAAlEREhAhMDEgIv/aAAgBAQABPwDeny/bOnrOnrO3rG3rGnrGnrZ09bOnrZXrZXrZXrC9hewvYXsL2FhYWFhYWFhYYYYf0Lf9bRJJBBBZLJZsxEREREREREREfB8n+y2fbJOb1vWMwQQQQQRERERERERERHwfkWz/AKZzOb1vWMxmMwQQQQRERERERERER8EfiWs/bOb1vW9YzGYzGYIIIIIiIiIiIiIiPgj8S1n7Zzet63resZjMZgggggiIiIiIiIiI+D8iQ8v0f2Q4Xg4Xg4Xg4Xg4QHC8HCA4QHCA4QHCA5AcvByA5AQEBAQEBAQEBBAQEEEHwfgf2//EABkRAQEAAwEAAAAAAAAAAAAAAAEAAhAgEv/aAAgBAgEBPwDGJmZmZnTMzPQ3q9TlLLMzOnbPRler1OUssszt0z2cMzp2zpnr/8QAGREBAQEBAQEAAAAAAAAAAAAAAQASMEAC/9oACAEDAQE/AGYiIiOyWbMEEER2zZsx8wQQeQ8H/9k=" />
                        <g className={styles["mg-back"]}></g>
                        <mask id="m2" fill="#fff">
                            <path d="M782 58c0 19-135.6 40-290.5 41.5S217 89.8 217 70c0-18.3 128.9-37.2 283.9-38.8C655.8 29.6 782 42.1 782 58zM508.1 36.3c-86.4 0-182.8 11.2-183 25-.3 13.8 74.9 19.5 161.3 19.5s188-11.2 188.2-25-80-19.5-166.5-19.5z" />
                        </mask>
                        <mask id="m4" fill="#fff">
                            <path d="M110 69.7c-.6 31.5 167.8 65.7 361.4 90.3s421.1 27.9 421.7-3.6L893 0H109l1 69.7z" />
                        </mask>


                        <g mask="url(#m3)">
                            <g className={styles.ring1}>
                                <path fill="#c307f9" d="M415 8.8C415 3 454.3-1 501.7 1S588 9 588 14M415 8.8V90h173V14" />
                                <rect x="536" y="14" width="45" height="70" />
                                <path fill="#ffa5c0" d="M588 14c0 6-41.7 10.4-89.1 8.4S415 15 415 8.8C415 3 454.3-1 501.7 1S588 9 588 14z" />
                                <path d="M501 4c-16.6 0-35 2-35 4.5s14.4 3.5 31 3.5 36-1.5 36-4-15.4-4-32-4z" />
                                <mask id="m3" fill="#fff">
                                    <path d="M674.7,55.7c-0.3,13.8-101.8,25-188.2,25s-161.6-5.6-161.3-19.5L325-0.5h349L674.7,55.7z" />
                                </mask>
                            </g>
                        </g>

                        <g className={styles["mg-front"]}>
                            <g id="b1">
                                <ellipse cx="734" cy="61" rx="33" ry="4" opacity="0.8" />
                                <mask id="m5" fill="#fff" strokeWidth="1">
                                    <circle id="c1" cx="709" cy="42" r="20" />
                                </mask>
                                <use fill="#ea42c2" href="#c1" />
                                <g mask="url(#m5)">
                                    <circle id="b1-dark" cx="724" cy="56" r="20" opacity="0.5" />
                                </g>
                            </g>
                        </g>
                        <path id="b1Path" fill="none" stroke="none" d="M693.8,23.9C693.5,37.4,597,54,462.5,54C296,54,250,43.7,250.2,30.3C250.5,16.8,369,3,487.2,3
		C606,3,694,10.4,693.8,23.9z"/>
                    </g>
                </g>

            </svg>

            <section id="s2" className={styles.s2}>
                <h1>
                    <span className={styles.pink}>Open the door</span>
                    <span className={styles.radial}>to a new universe</span>
                </h1>
            </section>
        </div>
    )
}




export default Footer