import React, { useEffect, useState } from "react";
import Sal from "sal.js";

import AdvanceTabData from "@home/assets/constants/tabStyle.json";

import styles from '../index.module.css'

const AdvanceTab = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    Sal();
  }, []);

  const handleClick = (index) => {
    setSelectedTab(index);
  };

  return (
    <>
      <div className={`${styles.row} ${styles["row--30"]}`} >
        <div className={`${styles["col-lg-12"]}`} >
          <div className={`${styles["tab-content"]}`} >
            {AdvanceTabData &&
              AdvanceTabData.advanceTab.map((data, index) => (
                <div
                  className={`${styles["tab-pane"]} ${styles.fade} ${selectedTab === index ? `${styles.show} ${styles.active}` : ""
                    } ${styles["advance-tab-content-1"]} ${styles["right-top"]}`}
                  id={`${data.tabId}-${index + 1}`}
                  role="tabpanel"
                  aria-labelledby={`${data.target}-${index + 1}`}
                  key={index}
                >
                  <div className={`${styles["rainbow-splite-style"]}`} >
                    <div className={`${styles["split-wrapper"]}`} >
                      <div className={`${styles.row} ${styles["g-0"]} ${styles["radius-10"]} ${styles["align-items-center"]}`} >
                        <div className={`${styles["col-lg-4"]} ${styles["col-xl-5"]} ${styles["col-12"]}`} >
                          <div className={`${styles.thumbnail}`} >
                            <img
                              className={`${styles.radius}`}
                              src={data.img}
                              width={544}
                              height={504}
                              alt="split Images"
                            />
                          </div>
                        </div>
                        <div className={`${styles["col-lg-6"]} ${styles["col-xl-7"]} ${styles["col-12"]}`} >
                          <div className={`${styles["split-inner"]}`} >
                            <div className={styles.subtitle} >
                              <span className={`${styles["theme-gradient"]}`} >
                                {data.subTitle}
                              </span>
                            </div>
                            <h2
                              className={`${styles.title} ${styles["sal-animate"]}`}
                              data-sal="slide-up"
                              data-sal-duration="400"
                              data-sal-delay="200"
                            >
                              {data.title}
                            </h2>
                            <p
                              className={`${styles.description} ${styles["sal-animate"]}`}
                              data-sal="slide-up"
                              data-sal-duration="400"
                              data-sal-delay="300"
                            >
                              {data.desc}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={`${styles["col-lg-12"]} ${styles["mt--60"]}`} >
          <div className={`${styles['advance-tab-button']} ${styles['advance-tab-button-1']} ${styles['right-top']}`} >
            <ul
              className={`${styles.nav} ${styles["nav-tabs"]} ${styles["tab-button-list"]}`}
              id="myTab-3"
              role="tablist"
            >
              {AdvanceTabData &&
                AdvanceTabData.advanceTab.map((list, i) => (
                  <li className={`${styles["col-lg-3"]} ${styles["nav-item"]}`} role="presentation" key={i}>
                    <button
                      className={`${styles["nav-link"]} ${styles["tab-button"]} ${selectedTab === i ? styles.active : ""
                        }`}
                      id={`${list.target}-${i + 1}`}
                      onClick={() => handleClick(i)}
                    >
                      <div className={`${styles.tab}`} >
                        <div className={`${styles["count-text"]}`} >
                          <span className={`${styles["theme-gradient"]}`} >0{i + 1}</span>
                        </div>
                        <h4 className={styles.title} >{list.menuText} </h4>
                      </div>
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvanceTab;
