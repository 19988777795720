// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.wrapper_VywD5,
.content_SYHgh {
  position: relative;
  width: 100%;
  z-index: 9 !important;
}

.content_SYHgh {
  overflow-x: hidden;
}

.content_SYHgh {
  height: 0vh;
  .section_uNVh7 {
    width: 100%;
    height: 100vh;
  }
}


.section_uNVh7{
  &.hero_L53wl {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.image-container_Gtw4c {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  perspective: 500px;
  overflow: hidden;
}


.image-container_Gtw4c {

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}


`, "",{"version":3,"sources":["webpack://./src/views/web/components/Home/IntroSecond.module.css"],"names":[],"mappings":";AACA;;EAEE,kBAAkB;EAClB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX;IACE,WAAW;IACX,aAAa;EACf;AACF;;;AAGA;EACE;IACE,kCAAkC;IAClC,4BAA4B;IAC5B,sBAAsB;EACxB;AACF;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,UAAU;EACV,kBAAkB;EAClB,gBAAgB;AAClB;;;AAGA;;EAEE;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;EAChC;AACF","sourcesContent":["\n.wrapper,\n.content {\n  position: relative;\n  width: 100%;\n  z-index: 9 !important;\n}\n\n.content {\n  overflow-x: hidden;\n}\n\n.content {\n  height: 0vh;\n  .section {\n    width: 100%;\n    height: 100vh;\n  }\n}\n\n\n.section{\n  &.hero {\n    background-position: center center;\n    background-repeat: no-repeat;\n    background-size: cover;\n  }\n}\n\n.image-container {\n  width: 100%;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 2;\n  perspective: 500px;\n  overflow: hidden;\n}\n\n\n.image-container {\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    object-position: center center;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper_VywD5`,
	"content": `content_SYHgh`,
	"section": `section_uNVh7`,
	"hero": `hero_L53wl`,
	"image-container": `image-container_Gtw4c`
};
export default ___CSS_LOADER_EXPORT___;
