import React from "react";
import Slider from "react-slick";

import ServiceData from "@home/assets/constants/serviceStyle.json";

import bg from "@home/assets/images/service/bg.png";
import bgHover from "@home/assets/images/service/bg-hover.png";

import darkBg from "@home/assets/images/light/service/bg.png";
import darkBgHover from "@home/assets/images/light/service/bg-hover.png";

import { useAppContext } from "@home/context/Context";

import styles from '../index.module.css'

const ServiceStyleOne = () => {
  const { isLightTheme } = useAppContext();
  var settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: true,
    arrows: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 581,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className={`${styles.container}`} >
        <div className={`${styles.row}`} >
          <div className={`${styles["col-md-12"]}`} >
            <Slider
              {...settings}
              className={`${styles['service-wrapper']} ${styles['rainbow-service-slider-actvation']} ${styles['slick-grid-15']} ${styles['rainbow-slick-dot']} ${styles['rainbow-gradient-arrows']}`} 
            >
              {ServiceData &&
                ServiceData.serviceOne.map((data, index) => (
                  <div className={`${styles["slide-single-layout"]}`}  key={index}>
                    <div className={`${styles["rainbow-box-card"]} ${styles["card-style-default"]} ${styles["aiwave-service-default"]} ${styles["has-bg-shaped"]}`} >
                      <div className={`${styles.inner}`} >
                        <div className={`${styles.icon}`} >
                          <img
                            src={data.img}
                            width={48}
                            height={48}
                            alt="Servece Icon"
                          />
                        </div>
                        <div className={`${styles.description} ${styles["centered-shape"]}`} >
                          <h5 className={styles.title} >{data.title}</h5>
                          <p className={`${styles.desc}`} >{data.desc}</p>
                          <button className={`${styles["read-more-btn"]}`}  href="#">
                            Explore More{" "}
                            <span>
                              <i className={`${styles["fa-sharp"]} ${styles["fa-solid"]} ${styles["fa-arrow-right"]}`} ></i>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className={styles["bg-shaped"]} >
                        <img
                          src={!isLightTheme ? bg : darkBg}
                          width={415}
                          height={344}
                          alt=""
                          className={`${styles.bg}`} 
                        />
                        <img
                          src={!isLightTheme ? bgHover : darkBgHover}
                          width={415}
                          height={344}
                          alt=""
                          className={`${styles["bg-hover"]}`} 
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceStyleOne;
