import { createSlice } from "@reduxjs/toolkit"

import {
  setOpenMenuLeft,
  setOpenMenuRight,
  setOpenChatBot,
  setOpenModal,
  fetchsDefault,
  updateDefault,
  fetchsBillingExpenses,
  fetchsBilling,
  updateBilling,
  deleteInvoice,
  fetchsInvoice,
  readInvoice,
  addInvoice,
  fetchUser,

  login,
  confirm,
  verify,
  register,
  upgrade,
  updateAccount,
  recoverPassword,
  updatePasswordAccount,

  addUser,
  deleteUser,
  fetchsUser,

  addLicense,
  deleteLicense,
  fetchsLicense,
  addPolice,
  deletePolice,
  fetchsPolice,
  addApi,
  deleteApi,
  fetchsApi,
  fetchLog,
  addLog,
  deleteLog,
  deleteLogs,
  fetchsLog,
  fetchsContract,
  addContract,
  deleteContract,
  fetchsDomain,
  addDomain,
  verifyDomain,
  denyDomain,
  fetchsBusiness,
  addBusiness,
  fetchsIdea,
  addIdea,
  deleteIdea,
} from "../actions/iam"

const iamSlice = createSlice({
  name: "iam",
  initialState: {
    themeColor: "#fff",
    themeFontFamily: "'Roboto'",

    user: null,
    users: [],
    billing: null,
    expenses: null,
    token: null,

    vector: {},
    vectors: [],

    addons: [],
    gpts: [],
    changelogs: [],
    news: [],

    status: "idle",
    status: "idle", 
    error: null,

    modal: null,
    openModal: false,
    openNotification: false,
    openMenuLeft: false,
    openMenuRight: false,
    openChatBot: false,

    notification: null,
    upload: null,

    invoices: {
      issued: [],
      received: [],
      rejected: [],
    },
    infoInvoices: {
      issued: 0,
      received: 0,
    },

    log: {},
    users: [],
    licenses: [],
    polices: [],
    apis: [],
    logs: [],

    contracts: [],
    infoContracts: 0,

    domains: [],
    business: null,
    ideas: null,
    prevIsLoad: false,
    chunkSearch: []
  },
  reducers: {
    setUser: (state, action) => {
    },
    setModal: (state, action) => {
      if (action.payload && action.payload !== "esc") {
        state.openModal = true
        state.modal = action.payload
      } else {
        state.openModal = false
        state.modal = action.payload
      }
    },
    setNotification: (state, action) => {
      if (action.payload?.status) {
        state.notification = action.payload
      } else {
        state.notification = null
      }
    },
    setUpload: (state, action) => {
      if (action.payload?.status) {
        state.uplaod = action.payload
      } else {
        state.upload = null
      }
    },
    setLog: (state, action) => {
      state.log = action.payload
    },
    setVector: (state, action) => {
      state.vector = action.payload
    },
    setVectors: (state, action) => {
      state.vectors = action.payload
    },

    setThemeColor: (state, action) => {
      state.themeColor = action.payload
    },
    setThemeFontFamily: (state, action) => {
      state.themeFontFamily = action.payload
    },


    setPrevIsLoad: (state, action) => {
      state.prevIsLoad = action.payload
    },
    setChunkSearch: (state, action) => {
      if(action.payload){
        state.chunkSearch.push(action.payload.data)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setOpenMenuLeft.fulfilled, (state, action) => {
        console.log('4', action.payload)
        state.openMenuLeft = action.payload
      })
      .addCase(setOpenMenuRight.fulfilled, (state, action) => {
        state.openMenuRight = action.payload
      })
      .addCase(setOpenChatBot.fulfilled, (state, action) => {
        state.openChatBot = action.payload
      })
      .addCase(setOpenModal.fulfilled, (state, action) => {
        if (action.payload) {
          state.openModal = true
          state.modal = action.payload
        } else {
          state.openModal = false
        }
      })

      .addCase(fetchsDefault.fulfilled, (state, action) => {
        state.addons = action.payload.addons
        state.gpts = action.payload.gpts
        state.changelogs = action.payload.changelogs
        state.news = action.payload.news
      })

      .addCase(updateDefault.fulfilled, (state, action) => {
        const { table, data } = action.payload
        const arrayIndex = state[table].findIndex((item) => item.id === data.id)

        if (arrayIndex !== -1) {
          state[table][arrayIndex] = data
        }
      })

      .addCase(fetchsBillingExpenses.fulfilled, (state, action) => {
        state.expenses = action.payload
      })

      .addCase(fetchsBilling.fulfilled, (state, action) => {
        state.billing = action.payload
        if (action.payload.billings) {
          state.billing = action.payload.billings
        }
      })
      .addCase(updateBilling.fulfilled, (state, action) => {
        state.billing = action.payload
      })

      .addCase(fetchsInvoice.fulfilled, (state, action) => {
        if (action.payload.type) {
          if (action.payload.type == "issued") {
            state.invoices.issued = action.payload.invoices
          } else if (action.payload.type == "received") {
            state.invoices.received = action.payload.invoices
          }
        }

        if (action.payload.total >= 0) {
          if (action.payload.type == "issued") {
            state.infoInvoices.issued = action.payload.total
          } else if (action.payload.type == "received") {
            state.infoInvoices.received = action.payload.total
          }
        }
      })

      .addCase(readInvoice.fulfilled, (state, action) => {
        if (action.payload) {
          const invoice = action.payload

          if (invoice.type == "issued") {
            state.invoices.issued.push(invoice)
          } else if (invoice.type == "received") {
            state.invoices.received.push(invoice)
          } else if (invoice.type == "rejected") {
            state.invoices.rejected.push(invoice)
          }
        }
      })
      .addCase(addInvoice.fulfilled, (state, action) => {
        state.invoices.issued.push(action.payload)
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        const id = action.payload.id
        const type = action.payload.type

        const index = state.invoices[type].findIndex((i) => i._id == id)

        let updatedInvoices = state.invoices[type]
        delete updatedInvoices[index]

        state.infoInvoices[type]--
        state.invoices[type] = updatedInvoices.filter(Boolean)
      })

      .addCase(register.pending, (state) => {
        state.status = "pending"
      })
      .addCase(register.fulfilled, (state, action) => {
        state.error = "Email enviado de confirmación"
      })
      .addCase(register.rejected, (state, action) => {
        state.error = action.error.message
      })
      .addCase(upgrade.fulfilled, (state, action) => {
      })
      .addCase(upgrade.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(upgrade.rejected, (state, action) => {
        state.error = action.error.message
      })
      .addCase(login.fulfilled, (state, action) => {
        localStorage.setItem('token', action.payload.token)
        state.user = action.payload.user
        state.token = action.payload.token
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error.message
      })

      .addCase(confirm.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.token = action.payload.token
      })
      .addCase(confirm.rejected, (state, action) => {
        if (action.error.message == 501) {
          state.token = null
          state.user = null
        }
        state.error = action.error.message
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.billing = action.payload.billing
      })
      .addCase(verify.rejected, (state, action) => {
        if (
          action.error.message &&
          action.error.message >= 500 &&
          action.error.message < 600
        ) {
          state.token = null
          state.user = null
        }
        state.error = action.error.message
      })

      .addCase(updateAccount.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload.token)
        state.user = action.payload.user
        state.token = action.payload.token
      })

      .addCase(recoverPassword.fulfilled, (state, action) => {
        state.error = action.payload
      })
      .addCase(recoverPassword.rejected, (state, action) => {
        state.error = action.error.message
      })
      .addCase(updatePasswordAccount.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload.token)
        state.user = action.payload.user
        state.token = action.payload.token
      })
      .addCase(updatePasswordAccount.rejected, (state, action) => {
        state.error = action.error.message
      })

      .addCase(addUser.fulfilled, (state, action) => {
      })

      .addCase(deleteUser.fulfilled, (state, action) => {
        const indexToDelete = state.users.findIndex(
          (user) => user.id === action.payload,
        )
        if (indexToDelete !== -1) {
          state.users = [
            ...state.users.slice(0, indexToDelete),
            ...state.users.slice(indexToDelete + 1),
          ]
        }
      })
      .addCase(fetchsUser.fulfilled, (state, action) => {
        state.users = action.payload.user
      })

      .addCase(addLicense.fulfilled, (state, action) => {
        state.licenses = action.payload.concat(
          state.licenses.filter(
            (license2) => !action.payload.find((license1) => license1.id === license2.id),
          ),
        )
      })
      .addCase(deleteLicense.fulfilled, (state, action) => {
        const indexToDelete = state.licenses.findIndex(
          (license) => license.id === action.payload,
        )
        if (indexToDelete !== -1) {
          state.licenses = [
            ...state.licenses.slice(0, indexToDelete),
            ...state.licenses.slice(indexToDelete + 1),
          ]
        }
      })
      .addCase(fetchsLicense.fulfilled, (state, action) => {
        state.licenses = action.payload
      })

      .addCase(addPolice.fulfilled, (state, action) => {
        state.polices = action.payload.concat(
          state.polices.filter(
            (police2) =>
              !action.payload.find((police1) => police1.id === police2.id),
          ),
        )
      })
      .addCase(deletePolice.fulfilled, (state, action) => {
        const indexToDelete = state.polices.findIndex(
          (police) => police.id === action.payload,
        )
        if (indexToDelete !== -1) {
          state.polices = [
            ...state.polices.slice(0, indexToDelete),
            ...state.polices.slice(indexToDelete + 1),
          ]
        }
      })
      .addCase(fetchsPolice.fulfilled, (state, action) => {
        state.polices = action.payload
      })

      .addCase(addApi.fulfilled, (state, action) => {
        state.apis = action.payload.concat(
          state.apis.filter(
            (api2) => !action.payload.find((api1) => api1.id === api2.id),
          ),
        )
      })
      .addCase(deleteApi.fulfilled, (state, action) => {
        const indexToDelete = state.apis.findIndex(
          (api) => api.id === action.payload,
        )
        if (indexToDelete !== -1) {
          state.apis = [
            ...state.apis.slice(0, indexToDelete),
            ...state.apis.slice(indexToDelete + 1),
          ]
        }
      })
      .addCase(fetchsApi.fulfilled, (state, action) => {
        state.apis = action.payload
      })

      .addCase(addLog.fulfilled, (state, action) => {
        state.log = action.payload
        state.logs.push(action.payload)
      })
      .addCase(deleteLog.fulfilled, (state, action) => {
        if(state.log._id == action.payload){
          state.log = {}
        }

        const indexToDelete = state.logs.findIndex(
          (log) => log._id === action.payload,
        )
        if (indexToDelete !== -1) {
          state.logs = [
            ...state.logs.slice(0, indexToDelete),
            ...state.logs.slice(indexToDelete + 1),
          ]
        }
      })
      .addCase(deleteLogs.fulfilled, (state, action) => {
        state.logs = [];
      })
      .addCase(fetchLog.fulfilled, (state, action) => {
        state.log = action.payload
      })
      .addCase(fetchsLog.fulfilled, (state, action) => {
        state.logs = action.payload
      })

      .addCase(fetchsContract.fulfilled, (state, action) => {
        state.contracts = action.payload.contracts

        if (action.payload.total >= 0) {
          state.infoContracts = action.payload.total
        }
      })
      .addCase(addContract.fulfilled, (state, action) => {
        const index = state.contracts.findIndex(
          (contract) => contract.id === action.payload,
        )

        if (index > -1) {
          state.contracts[index] = action.payload
        } else {
          state.contracts.push(action.payload)
        }
      })
      .addCase(deleteContract.fulfilled, (state, action) => {
        const indexToDelete = state.contracts.findIndex(
          (contract) => contract._id === action.payload
        );
        if (indexToDelete !== -1) {
          if (indexToDelete !== -1) {
            const updatedContracts = [...state.contracts]
            updatedContracts.splice(indexToDelete, 1)
            state.contracts = updatedContracts
          }
        }
      })

      .addCase(fetchsDomain.fulfilled, (state, action) => {
        state.domains = action.payload
      })

      .addCase(addDomain.fulfilled, (state, action) => {
        if (action.payload) {
          const index = state.domains.findIndex(
            (domain) => domain.id == action.payload.id,
          )

          if (action.payload) {
            const index = state.domains.findIndex(
              (domain) => domain.id == action.payload.id,
            )
            if (index == -1) {
              state.domains = [...state.domains, action.payload]
            }
          }
        }
      })
      .addCase(verifyDomain.fulfilled, (state, action) => {
        const index = state.domains.findIndex(
          (domain) => domain.id == action.payload.id,
        )

        if (index > -1 && action.payload.isverify) {
          state.domains[index] = action.payload
        }
      })
      .addCase(denyDomain.fulfilled, (state, action) => {
        const id = action.payload
        const indexToDelete = state.domains.findIndex(
          (domain) => domain.id === id,
        )
        if (indexToDelete !== -1) {
          state.domains = [
            ...state.domains.slice(0, indexToDelete),
            ...state.domains.slice(indexToDelete + 1),
          ]
        }
      })

      .addCase(fetchsBusiness.fulfilled, (state, action) => {
        state.business = action.payload
      })

      .addCase(addBusiness.fulfilled, (state, action) => {
        const index = state.business.findIndex((b) => b.id == action.payload.id)

        if (index == -1) {
          state.business = [...state.business, state.action]
        }
      })
      
  },
})

export const {
  setUser,
  setModal,
  setNotification,
  setUpload,
  setVector,
  setVectors,
  setThemeColor,
  setThemeFontFamily,
  setLog,

  setChunkSearch,
  setPrevIsLoad,
} = iamSlice.actions

export default iamSlice.reducer
