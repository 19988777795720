import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TabData from "@home/assets/constants/tabStyle.json";
import styles from '../index.module.css'

const TabStyleOne = () => {
  const { t } = useTranslation();
  const [updatedData, setUpdatedData] = useState({ ...TabData });

  const handleTab = (id) => {
    const index = updatedData.TabStyleOne.findIndex(x => x.menuId == id);
    if (index > -1) {
      let _updatedData = {
        ...updatedData,
        TabStyleOne: updatedData.TabStyleOne.map(x => ({ ...x, isSelect: false }))
      };
      _updatedData.TabStyleOne[index].isSelect = true;
      setUpdatedData(_updatedData);
    }
  };

  const goToNextTab = () => {
    const currentIndex = updatedData.TabStyleOne.findIndex(tab => tab.isSelect);
    let nextIndex = currentIndex + 1;

    if (nextIndex >= updatedData.TabStyleOne.length) {
      nextIndex = 0;
    }

    const nextTabId = updatedData.TabStyleOne[nextIndex].menuId;
    handleTab(nextTabId);
  };

  return (
    <>
      <div className={`${styles.row} ${styles["row--30"]} ${styles["align-items-center"]}`}>
        <div className={`${styles.className} ${styles["col-lg-12"]}`}>
          <div className={`${styles["rainbow-default-tab"]} ${styles["style-three"]} ${styles["generator-tab-defalt"]}`}>
            <ul className={`${styles.nav} ${styles["nav-tabs"]} ${styles["tab-button"]}`} role="tablist">
              {updatedData &&
                updatedData.TabStyleOne.map((data, index) => (
                  <li
                    className={`${styles["nav-item"]} ${styles["tabs__tab"]}`}
                    role="presentation"
                    key={index}
                  >
                    <button
                      className={`${styles["nav-link"]} ${styles["rainbow-gradient-btn"]} ${styles["without-shape-circle"]} ${data.isSelect ? styles.active : ""
                        }`}
                      onClick={() => handleTab(data.menuId)}
                    >
                      <span className={`${styles["generator-icon"]}`}>
                        <img
                          src={data.iconImg}
                          width={24}
                          height={24}
                          alt="Icon"
                        />
                        {data.text}
                      </span>
                      <span className={styles["border-bottom-style"]}></span>
                    </button>
                  </li>
                ))}
            </ul>

            <div className={`${styles["rainbow-tab-content"]} ${styles["tab-content"]}`}>
              {updatedData &&
                updatedData.TabStyleOne.map((tab, index) => (
                  <div
                    className={`${styles["tab-pane"]} ${styles["fade"]} ${tab.isSelect ? `${styles.show} ${styles.active}` : ""
                      }`}
                    id={tab.target}
                    role="tabpanel"
                    aria-labelledby={`${tab.menuId}-tab`}
                    key={index}
                  >
                    <div className={`${styles.inner}`}>
                      <div className={`${styles.row}`}>
                        <div className={`${styles['col-xl-6']}`}>
                          <div className={styles["section-title"]}>
                            <h2 className={styles.title}>{tab.title}</h2>
                            <div className={styles["features-section"]}>
                              <ul className={`${styles["list-style--1"]}`}>
                                {tab.subItem.map((item, i) => (
                                  <li key={i}>
                                    <svg fill="none" viewBox="0 0 24 24">
                                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    {item.text}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className={styles["read-more"]}>
                              <button
                                className={`${styles["mt--30"]} ${styles["btn-default"]} ${styles["color-blacked"]}`}
                                onClick={goToNextTab} // Llamar a la función para cambiar de tab
                              >
                                {t("home1.web.component_0.text1")}
                                <svg fill="none" viewBox="0 0 24 24">
                                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className={`${styles["col-xl-6"]} ${styles["mt_md--30"]} ${styles["mt_sm--30"]}`}>
                          <div className={styles["export-img"]}>
                            <div className={styles["inner-without-padding"]}>
                              <div className={`${styles["export-img"]} ${styles["img-bg-shape"]}`}>
                                <img
                                  src={tab.img}
                                  width={569}
                                  height={483}
                                  alt="Chat example Image"
                                />
                                <div className={styles["image-shape"]}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabStyleOne;
