import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';


import {
  createIdeaKanban, 
  
  addProject,
  updateProject,

  deleteProject,

  fetchsProject,
  fetchProject,
} from '../actions/project'




export const initialProject = {
  id: uuidv4(),
  title: '',
  description: '',
  status: '',
  priority: '',
  collaborators: [],
  tickets: [],
  startedAt: new Date().toISOString(),
  endedAt: new Date().toISOString(),
  createdBy: '',
  updatedBy: '',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
}

export const initialTicket = {
  id: uuidv4(),
  title: '',
  description: '',
  color: '',
  collaborator: [],
  tasks: [],
  createdBy: '',
  updatedBy: '',
  startedAt: new Date().toISOString(),
  endedAt: new Date().toISOString(),
}


export const initialTask = {
  id: uuidv4(),
  title: '',
  description: '',
  status: '',
  priority: '',
  date: '', 
  list: [], 
  createdBy: '',
  updatedBy: '',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
}


const projectSlice = createSlice({
  name: 'project',
  initialState: {

    project: null,
    projects: [{
      ...initialProject,
      tickets: [{
        ...initialTicket,
        tasks: [initialTask]
      }]
    }],

    tasks: [initialTask]
  },
  reducers: {
    setProject: (state, action) => {
      state.project = action.payload
      const index = state.projects.findIndex(project => project.id == action.payload.id)

      if(index > -1) {
        state.projects[index] = action.payload
      }
    },
    setTasks: (state, action) => {
      state.tasks = action.payload
    },
    setTask: (state, action) => {
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsProject.fulfilled, (state, action) => {
        state.projects = action.payload.projects
          state.project = action.payload.project
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.project = action.payload
      })

      .addCase(addProject.fulfilled, (state, action) => {
        state.projects.push(action.payload)
      })


      .addCase(createIdeaKanban.fulfilled, (state, action) => {
        state.project.tickets = action.payload
      })

      .addCase(updateProject.fulfilled, (state, action) => {
      })


      .addCase(deleteProject.fulfilled, (state, action) => {
        const { id } = action.payload;

        state.projects = state.projects.filter(project => project.id !== id);

        state.project = null;
      })



  },
});

export const {
  setProject,
  setTasks,
  setTask
} = projectSlice.actions;

export default projectSlice.reducer;













