import { createSlice } from "@reduxjs/toolkit"

import {

} from "../actions/stripe"

const stripeSlice = createSlice({
  name: "stripe",
  initialState: {
   
  },
  reducers: {
    setUser: (state, action) => {
    },
   
  },
  extraReducers: (builder) => {
      
  },
})

export const {
} = stripeSlice.actions

export default stripeSlice.reducer
