import axios from 'axios'

const axiosInstance = axios.create({
	// baseURL: 'https://ay-cloud.com/service/v1',
	baseURL: 'https://aythen.com/service/v1',
	//- baseURL: 'http://localhost:3001/service/v1',
	maxContentLength: Infinity,
	maxBodyLength: Infinity,
})

export default axiosInstance

// export const apiUrl = 'https://ay-cloud.com'
export const apiUrl = 'https://aythen.com'
//- export const apiUrl = 'http://212.47.252.124'

export const asyncComponent = (importComponent) => {
	return function AsyncComponent(props) {
		const [Component, setComponent] = useState(null)

		useEffect(() => {
			let isMounted = true
			importComponent().then((mod) => {
				if (isMounted) {
					setComponent(() => mod.default)
				}
			})

			return () => {
				isMounted = false
			}
		}, [importComponent])

		if (!Component) {
			return <p>Loading...</p>
		}

		return <Component {...props} />
	}
}

export const asyncFunctions = (importFunction, functionNames) => {
	return function AsyncFunctionWrapper() {
		const [loadedFunctions, setLoadedFunctions] = useState(null)

		useEffect(() => {
			let isMounted = true
			importFunction().then((mod) => {
				if (isMounted) {
					let functions

					if (!functionNames) {
						functions = { default: mod.default }
					}
					else if (typeof functionNames === 'string') {
						functions = { [functionNames]: mod[functionNames] }
					}
					else if (Array.isArray(functionNames)) {
						functions = functionNames.reduce((acc, fnName) => {
							acc[fnName] = mod[fnName]
							return acc
						}, {})
					}

					setLoadedFunctions(() => functions)
				}
			})

			return () => {
				isMounted = false
			}
		}, [importFunction, functionNames])

		if (!loadedFunctions) {
			return <p>Loading...</p>
		}

		return loadedFunctions
	}
}
