import apiBackend from '@src/apiBackend.js'
import { createAsyncThunk } from '@reduxjs/toolkit';

import { setNotification } from "../slices/iamSlice"




export const fetchsProject =
  createAsyncThunk('project/fetchsProject',
    async ({ workspaceId, projectId = null, filter }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.post(
          `/project/all`, {
          workspaceId,
          projectId,
          filter
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )

        return {
          projects: res.data.projects,
          project: res.data.project
        }
      } catch (error) {
        console.log('Error fetchs project:', error)
      }
    })


    export const createIdeaKanban = 
    createAsyncThunk('project/createIdeaKanban',
    async ({workspaceId, projectId, kanban, text}) => {
      try {
        const tokenGPT = localStorage.getItem('token-gpt')
        const token = localStorage.getItem('token')

         const response = await apiBackend.post(
          `/project/idea`,{
            token: tokenGPT,
            workspaceId,
            projectId,
            kanban,
            text
          },{
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        )

        return response.data
      } catch (error) {
        console.log('Error idea kanban:', error)
      }
    })    



export const fetchProject = 
createAsyncThunk('project/fetchProject',
async ({workspaceId, projectId}) => {
  try {
    const token = localStorage.getItem('token')

     const res = await apiBackend.post(
      `/project`,{
        workspaceId,
        projectId,
      },{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }
    )
    return res.data
  } catch (error) {
    console.log('Error fetch project:', error)
  }
})




export const addProject =
  createAsyncThunk('project/addProject',
    async ({ workspaceId, projectId, data }, {dispatch}) => {
      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.post(
          '/project/add',
          {
            workspaceId,
            projectId,
            data,
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )


        return res.data
      } catch (error) {
        if(error.response.status == 502){
          dispatch(
            setNotification({
              status: 300,
              text: `Limite excedido no se ha podido añadir un nuevo proyecto.`,
            }),
          )          
        }
      }
    })



export const updateProject =
  createAsyncThunk('project/updateProject',
    async ({ workspaceId, project }) => {
      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.post(
          `/project/update`,
          {
            workspaceId,
            project
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )

        return res.data
      } catch (error) {
        console.log('Error update project:', error)
      }
    })


export const deleteProject =
  createAsyncThunk('project/deleteProject',
    async ({ workspaceId, data }) => {
      try {
        const token = localStorage.getItem('token')

        const res = await apiBackend.delete(
          `/project`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            workspaceId,
            data
          }
        }
        )

        return res.data
      } catch (error) {
        console.log('Error delete project:', error)
      }
    })



export const addProjectTicket =
  createAsyncThunk('project/addProjectTicket',
    async ({ workspaceId, projectId, ticket, version = null }) => {
      try {
        const token = localStorage.getItem('token')
        const res = await apiBackend.post(
          '/project/ticket',
          {
            workspaceId,
            projectId,
            ticket,
            version
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        )

        return res.data
      } catch (error) {
        console.log('Error project ticket:', error)
      }
    })










export const fetchChatProject =
  createAsyncThunk('project/fetchChatProject',
    async ({ projectId, taskId }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.get(
          `/project/chat/${projectId}/${taskId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );


export const addChatProject =
  createAsyncThunk('project/addChatProject',
    async ({ projectId, taskId, chat }, { dispatch }) => {
      try {
        const token = localStorage.getItem('token')
        const resp = await apiBackend.post(
          '/project/chat',
          {
            projectId,
            taskId,
            chat
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  );
