import apiBackend from '@src/apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { setModal } from '../slices/iamSlice'

const VERSION = process.env.REACT_APP_VERSION;



export const fetchGraphStripe = createAsyncThunk(
	'fetchGraphStripe',
	async (
		{ id },
		{ dispatch }
	) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/data',
				{
					id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)


export const addUserStripePayment = createAsyncThunk(
	'addUserStripePayment',
	async (
		{ id, email, value, number, exp_month, exp_year, cvc },
		{ dispatch }
	) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/pay',
				{
					id,
					email,
					value,
					number,
					exp_month,
					exp_year,
					cvc,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)


export const addUserStripePaymentSheet = createAsyncThunk(
	'addUserStripePayment',
	async (
		{ id, email, value, number, exp_month, exp_year, cvc },
		{ dispatch }
	) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/pay/sheet',
				{
					id,
					email,
					value,
					number,
					exp_month,
					exp_year,
					cvc,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)




export const addUserStripe = createAsyncThunk(
	'addUserStripe',
	async ({email }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/user',
				{
					email,
					price: 100,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)


export const fetchUserStripe = createAsyncThunk(
	'fetchUserStripe',
	async ({ email }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/user/email',
				{
					email,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			if (error.response.status == 502) {
				dispatch(
					setNotification({
						status: 300,
						text: `Limite excedido no se ha podido añadir un nuevo proyecto.`,
					})
				)
			}
		}
	}
)


export const fetchUserStripePayments = createAsyncThunk(
	'fetchUserStripePayments',
	async ({id}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/user/payments',
				{
					email: 'info@aythen.com',
					filter: '',
					date0: '',
					date1: '',
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			throw error
		}
	}
)


export const fetchUserStripeCards = createAsyncThunk(
	'fetchUserCards',
	async (id, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/user/cards',
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)


export const addUserStripeMethodCard = createAsyncThunk(
	'addUserStripeMethodCard',
	async ({ number, exp_month, exp_year, cvc }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/user/card',
				{
					number,
					exp_month,
					exp_year,
					cvc,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)

export const defaultUserStripeCard = createAsyncThunk(
	'defaultUserStripeCard',
	async (id, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/user/card/default',
				{
					cardId: id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)

export const deleteUserMethodCard = createAsyncThunk(
	'deleteUserMethodCard',
	async (id, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/user/card/delete',
				{
					cardId: id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)



export const fetchCardStripe = createAsyncThunk(
	'fetchCardStripe',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/card/retreive',
				{
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)

export const addCardStripe = createAsyncThunk(
	'addCardIssuingStripe',
	async ({}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/card/add',
				{
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)


export const viewCardPinStripe = createAsyncThunk(
	'viewCardPinStripe',
	async ({id}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/card/pin/view',
				{
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)

export const modifyCardLimitStripe = createAsyncThunk(
	'modifyCardLimitStripe',
	async ({id}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/card/limit',
				{
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)

export const modifyCardPinStripe = createAsyncThunk(
	'modifyCardPinStripe',
	async ({id}, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/card/pin/modify',
				{
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)

export const availableCardStripe = createAsyncThunk(
	'availableCardStripe',
	async (id, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/card/available',
				{
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			throw error
		}
	}
)



export const fetchsSuscriptionStripe = createAsyncThunk(
	'fetchsSuscriptionStripe',
	async ({ user, date0, date1 }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/suscription/search',
				{
					user,
					date0,
					date1,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 502) {
				dispatch(
					setNotification({
						status: 300,
						text: `Limite excedido no se ha podido añadir un nuevo proyecto.`,
					})
				)
			}
			return { error }
		}
	}
)


export const fetchChargesAndPayments = createAsyncThunk(
	'fetchChargesAndPayments',
	async ({ user, date0, date1 }, { dispatch }) => {
		try {
			const token = localStorage.getItem('token')
			const response = await apiBackend.post(
				'/stripe/payment/search',
				{
					user,
					date0,
					date1,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return response.data
		} catch (error) {
			console.log('Error', error)
			if (error.response.status == 502) {
				dispatch(
					setNotification({
						status: 300,
						text: `Limite excedido no se ha podido añadir un nuevo proyecto.`,
					})
				)
			}
			return { error }
		}
	}
)



