import apiBackend from '../../../../apiBackend'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getUserCalendars = createAsyncThunk(
	'calendar/getUserCalendars',
	async ({ userId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/getUserCalendars',
				{ userId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const createCalendar = createAsyncThunk(
	'calendar/createCalendar',
	async ({ userId, calendarData, def }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/createCalendar',
				{ userId, calendarData, def },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const deleteCalendar = createAsyncThunk(
	'calendar/deleteCalendar',
	async ({ userId, calendarId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/deleteCalendar',
				{ userId, calendarId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const updateCalendar = createAsyncThunk(
	'calendar/updateCalendar',
	async ({ userId, calendarId, toUpdate }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/updateCalendar',
				{ userId, calendarId, toUpdate },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const getCalendarEvents = createAsyncThunk(
	'calendar/getCalendarEvents',
	async ({ userId, calendarId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/getCalendarEvents',
				{ userId, calendarId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const createEvent = createAsyncThunk(
	'calendar/createEvent',
	async ({ userId, calendarId, eventData }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/createEvent',
				{ userId, calendarId, eventData },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const deleteEvent = createAsyncThunk(
	'calendar/deleteEvent',
	async ({ userId, calendarId, eventId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/deleteEvent',
				{ userId, calendarId, eventId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const checkForCalendarDB = createAsyncThunk(
	'calendar/checkForCalendarDB',
	async ({ userId, userEmail }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/checkForCalendarDB',
				{ userId, userEmail },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const getEventDataByJoinId = createAsyncThunk(
	'calendar/getEventDataByJoinId',
	async ({ joinId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/getEventDataByJoinId',
				{ joinId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const getCalendarByShareId = createAsyncThunk(
	'calendar/getCalendarByShareId',
	async ({ shareId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/getCalendarByShareId',
				{ shareId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const getAllUserPendingInvitations = createAsyncThunk(
	'calendar/getAllUserPendingInvitations',
	async ({ userId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/getAllUserPendingInvitations',
				{ userId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const acceptInvitation = createAsyncThunk(
	'calendar/acceptInvitation',
	async ({ userId, eventId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/acceptInvitation',
				{ userId, eventId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const cancelInvitation = createAsyncThunk(
	'calendar/cancelInvitation',
	async ({ userId, eventId }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/cancelInvitation',
				{ userId, eventId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)

export const sendInvitationEmail = createAsyncThunk(
	'calendar/sendInvitationEmail',
	async ({ email, data }) => {
		try {
			const token = localStorage.getItem('token')
			const res = await apiBackend.post(
				'/calendar/sendInvitationEmail',
				{ email, data },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			return res.data
		} catch (error) {
			console.error('Error:', error)
		}
	}
)
