// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-background_Vmazm {
    height: 100%;
    left: 0px;
    overflow: hidden;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
    background: #000000;
    

    .app-background-image_PUqZs {
      height: 100%;
      transition: filter 250ms, transform 250ms;
      width: 100%;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/views/auth/background.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,QAAQ;IACR,WAAW;IACX,UAAU;IACV,mBAAmB;;;IAGnB;MACE,YAAY;MACZ,yCAAyC;MACzC,WAAW;IACb;EACF","sourcesContent":[".app-background {\n    height: 100%;\n    left: 0px;\n    overflow: hidden;\n    position: fixed;\n    top: 0px;\n    width: 100%;\n    z-index: 1;\n    background: #000000;\n    \n\n    .app-background-image {\n      height: 100%;\n      transition: filter 250ms, transform 250ms;\n      width: 100%;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app-background": `app-background_Vmazm`,
	"app-background-image": `app-background-image_PUqZs`
};
export default ___CSS_LOADER_EXPORT___;
