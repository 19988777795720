import React, { useState, useEffect } from 'react';
import gsap from 'https://cdn.skypack.dev/gsap@3.12.0'


import './background1.css'

const MouseEffectComponent = () => {
    const [loadLenis, setLoadLenis] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/gh/studio-freight/lenis@1.0.29/bundled/lenis.min.js';
        script.async = true;

        script.onload = () => {
            if (window.Lenis) {
                setLoadLenis(true); 
            } else {
                console.error('Lenis is not available');
            }
        };

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script); 
        };
    }, []);

    useEffect(() => {
        if (loadLenis) {
            const lenis = new window.Lenis({ lerp: 0.15 });
            gsap.ticker.add((time) => {
                lenis.raf(time * 1000);
            });
            gsap.ticker.lagSmoothing(0);

            const grid = document.querySelector(".grid");
            const gridRows = grid.querySelectorAll(".row");

            let winsize = { width: window.innerWidth, height: window.innerHeight };
            window.addEventListener("resize", () => {
                winsize = { width: window.innerWidth, height: window.innerHeight };
            });

            let mousepos = { x: winsize.width / 2, y: winsize.height / 2 };
            const config = {
                translateX: true,
                skewX: false,
                contrast: true,
                scale: false,
                brightness: true,
            };

            const numRows = gridRows.length;
            const middleRowIndex = Math.floor(numRows / 2);
            const middleRow = gridRows[middleRowIndex];
            const middleRowItems = middleRow.querySelectorAll(".row__item");
            const middleRowItemIndex = Math.floor(middleRowItems.length / 2);
            const middleRowItemInner = middleRowItems[middleRowItemIndex].querySelector(
                ".row__item-inner"
            );
            const middleRowItemInnerImage = middleRowItemInner.querySelector(
                ".row__item-img"
            );
            middleRowItemInnerImage.classList.add("row__item-img--large");

            const baseAmt = 0.1;
            const minAmt = 0.05;
            const maxAmt = 0.1;

            let renderedStyles = Array.from({ length: numRows }, (v, index) => {
                const distanceFromMiddle = Math.abs(index - middleRowIndex);
                const amt = Math.max(baseAmt - distanceFromMiddle * 0.03, minAmt);
                const scaleAmt = Math.min(baseAmt + distanceFromMiddle * 0.03, maxAmt);
                let style = { amt, scaleAmt };

                if (config.translateX) style.translateX = { previous: 0, current: 0 };
                if (config.skewX) style.skewX = { previous: 0, current: 0 };
                if (config.contrast) style.contrast = { previous: 100, current: 100 };
                if (config.scale) style.scale = { previous: 1, current: 1 };
                if (config.brightness) style.brightness = { previous: 100, current: 100 };

                return style;
            });

            let requestId;

            const getMousePos = (ev) => {
                let posx = 0;
                let posy = 0;
                if (!ev) ev = window.event;
                if (ev.pageX || ev.pageY) {
                    posx = ev.pageX;
                    posy = ev.pageY;
                } else if (ev.clientX || ev.clientY) {
                    posx =
                        ev.clientX +
                        document.body.scrollLeft +
                        document.documentElement.scrollLeft;
                    posy =
                        ev.clientY + document.body.scrollTop + document.documentElement.scrollTop;
                }
                return { x: posx, y: posy };
            };

            const updateMousePosition = (ev) => {
                const pos = getMousePos(ev);
                mousepos.x = pos.x;
                mousepos.y = pos.y;
            };

            const lerp = (a, b, n) => (1 - n) * a + n * b;

            const calculateMappedX = () => {
                return (((mousepos.x / winsize.width) * 2 - 1) * 40 * winsize.width) / 100;
            };

            const calculateMappedContrast = () => {
                const centerContrast = 100;
                const edgeContrast = 330;
                const t = Math.abs((mousepos.x / winsize.width) * 2 - 1);
                const factor = Math.pow(t, 2);
                return centerContrast - factor * (centerContrast - edgeContrast);
            };

            const render = () => {
                const mappedValues = {
                    translateX: calculateMappedX(),
                    contrast: calculateMappedContrast(),
                };

                gridRows.forEach((row, index) => {
                    const style = renderedStyles[index];

                    for (let prop in config) {
                        if (config[prop]) {
                            style[prop].current = mappedValues[prop];
                            const amt = prop === "scale" ? style.scaleAmt : style.amt;
                            style[prop].previous = lerp(style[prop].previous, style[prop].current, amt);
                        }
                    }

                    let gsapSettings = {};
                    if (config.translateX) gsapSettings.x = style.translateX.previous;
                    if (config.contrast)
                        gsapSettings.filter = `contrast(${style.contrast.previous}%)`;

                    gsap.set(row, gsapSettings);
                });

                requestId = requestAnimationFrame(render);
            };

            const startRendering = () => {
                if (!requestId) {
                    render();
                }
            };

            const stopRendering = () => {
                if (requestId) {
                    cancelAnimationFrame(requestId);
                    requestId = undefined;
                }
            };


            startRendering();

            window.addEventListener("mousemove", updateMousePosition);
            window.addEventListener("touchmove", (ev) => {
                const touch = ev.touches[0];
                updateMousePosition(touch);
            });

            return () => {
                gsap.ticker.remove(() => lenis.raf());
                window.removeEventListener("mousemove", updateMousePosition);
                window.removeEventListener("touchmove", updateMousePosition);
            };
        }
    }, [loadLenis]);






    const [imageSrc, setImageSrc] = useState('')
    const [background, setBackgorund] = useState([])
    const [backgroundAy, setBackgorundAy] = useState([])

    const initialBackground = [
        [
            {
                "url": "/assets/background/artem-demura-1.jpg",
                "alt": "Image 1"
            },
            {
                "url": "/assets/background/artem-demura-4.jpg",
                "alt": "Image 2"
            },
            {
                "url": "/assets/background/artem-demura-cherti-1.jpg",
                "alt": "Image 3"
            },
            {
                "url": "/assets/background/artem-demura-erebos3.jpg",
                "alt": "Image 4"
            },
            {
                "url": "/assets/background/artem-demura-iron-moon1.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/artem-demura-my-love.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/artem-demura-summer3.jpg",
                "alt": "Image 5"
            }
        ],
        [
            {
                "url": "/assets/background/artem-demura-tuman-ebat.jpg",
                "alt": "Image 1"
            },
            {
                "url": "/assets/background/eve-ventrue-mp-2-2.jpg",
                "alt": "Image 2"
            },
            {
                "url": "/assets/background/eytan-zana-the-arrival.jpg",
                "alt": "Image 3"
            },
            {
                "url": "/assets/background/joseph-feely-eagles-guardians-resized.jpg",
                "alt": "Image 4"
            },
            {
                "url": "/assets/background/joseph-feely-mongolian-wanderer.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/joseph-feely-z-king-olaf-returns.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/samet-dereli-final-render-2.jpg",
                "alt": "Image 5"
            }
        ], [
            {
                "url": "/assets/background/still-xvii-dwarves-environment-4.jpg",
                "alt": "Image 1"
            },
            {
                "url": "/assets/background/still-xvii-elfs-environment-14.jpg",
                "alt": "Image 2"
            },
            {
                "url": "/assets/background/still-xvii-ents-environment-7.jpg",
                "alt": "Image 3"
            },
            {
                "url": "/assets/background/still-xvii-hobbits-environment-4.jpg",
                "alt": "Image 4"
            },
            {
                "url": "/assets/background/still-xvii-mordor-environment-7.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/still-xvii-peoples-environment-1.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/still-xvii-trolls-environment-1.jpg",
                "alt": "Image 5"
            }
        ], [
            {
                "url": "/assets/background/tim-warnock-nf-set5environments-poppy-nest-e-02.jpg",
                "alt": "Image 1"
            },
            {
                "url": "/assets/background/tim-warnock-nf-set5environments-war-torndemacia-c-04.jpg",
                "alt": "Image 2"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-1-sluice-1dflatgh.jpg",
                "alt": "Image 3"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-1-sluice-3aflatgh.jpg",
                "alt": "Image 4"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-1-thranduils-realm-v03-ep.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-1-thrarealm-gh-2aflatgh.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-1-thrarealm-gh-2bflatgh.jpg",
                "alt": "Image 5"
            }
        ], [
            {
                "url": "/assets/background/weta-workshop-design-studio-1-thrarealm-gh-2eflatgh.jpg",
                "alt": "Image 1"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-2-dol-guldur-3-2flatgh.jpg",
                "alt": "Image 2"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-2-dol-guldur-5flatgh.jpg",
                "alt": "Image 3"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-2-sq01-dol-v1bflatgh.jpg",
                "alt": "Image 4"
            },
            {
                "url": "/assets/background/weta-workshop-design-studio-2-sq12-dol-v1aflatgh.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/artem-demura-1.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/artem-demura-4.jpg",
                "alt": "Image 5"
            }
        ], [
            {
                "url": "/assets/background/artem-demura-cherti-1.jpg",
                "alt": "Image 1"
            },
            {
                "url": "/assets/background/artem-demura-erebos3.jpg",
                "alt": "Image 2"
            },
            {
                "url": "/assets/background/artem-demura-iron-moon1.jpg",
                "alt": "Image 3"
            },
            {
                "url": "/assets/background/artem-demura-iron-moon1.jpg",
                "alt": "Image 4"
            },
            {
                "url": "/assets/background/artem-demura-my-love.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/artem-demura-summer3.jpg",
                "alt": "Image 5"
            },
            {
                "url": "/assets/background/artem-demura-tuman-ebat.jpg",
                "alt": "Image 5"
            }
        ]
    ]


    const generateBackgroundGrid = (images) => {
        const rows = images.length;
        const columns = images[0].length;
        let emptyGrid = [];
    
        const startX = 200; 
        const startY = -100; 
    
        for (let i = 0; i < rows; i++) {
            let row = [];
            for (let j = 0; j < columns; j++) {
                const x = startX + -(j * 310);  
                const y = startY + -(i * 110);  
                row.push({ x, y });
            }
            emptyGrid.push(row);
        }
    
        return emptyGrid;
    };



    useEffect(() => {
        const savedIndexAY = localStorage.getItem('background-desktop-ay');

        if (savedIndexAY !== null) {
            setImageSrc(savedIndexAY);


            const gridWithXY = generateBackgroundGrid(initialBackground);
            setBackgorundAy(gridWithXY)

            document.body.style.backgroundImage = `url(${savedIndexAY})`


        } else {
            setBackgorund(initialBackground)
        }
    }, []);




    return (
        <div className="noscroll">
            <section className="intro">
                <div className="grid">
                    {background.length > 0 && (
                        <>
                            {background.map((row, index) => (
                                <div key={index} className="row">
                                    {row.map((image, index) => (
                                        <div key={index} className="row__item">
                                            <div className="row__item-inner">
                                                <div
                                                    className="row__item-img"
                                                    style={{ backgroundImage: `url(${image.url})` }}
                                                    alt={image.alt}
                                                ></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </>
                    )}

                    {backgroundAy.length > 0 && (
                        <>
                            {backgroundAy.map((row, rowIndex) => (
                                <div key={rowIndex} className="row">
                                    {row.map((image, colIndex) => {
                                        const { x, y } = image;

                                        return (
                                            <div key={colIndex} className="row__item">
                                                <div className="row__item-inner">
                                                    <div
                                                        key={`${rowIndex}-${colIndex}`}
                                                        className="row__item-img"
                                                        style={{
                                                            backgroundSize: `inherit`,
                                                            backgroundRepeat: `no-repeat`,
                                                            backgroundImage: `url(${imageSrc})`,
                                                            backgroundPosition: `${x}px ${y}px`
                                                        }}
                                                    >
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )
                            )}
                        </>
                    )}
                </div>
            </section>
        </div>
    );
};

export default MouseEffectComponent;
